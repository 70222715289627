// Build heading style classes
@each $size, $headers in $header-styles {
  @include breakpoint($size) {
    @each $header, $header-defs in $headers {
      $font-size-temp: 1rem;
      .#{$header} {

        @if map-has-key($header-defs, font-size) {
          $font-size-temp: rem-calc(map-get($header-defs, font-size));
          font-size: $font-size-temp;

        } @else if map-has-key($header-defs, fs) {
          font-size: $font-size-temp;

          $font-size-temp: rem-calc(map-get($header-defs, fs));
        } @else if $size == $-zf-zero-breakpoint {
          font-size: $font-size-temp;
        }
        @if map-has-key($header-defs, line-height) {
          line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
        } @else if map-has-key($header-defs, lh) {
          line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
        } @else if $size == $-zf-zero-breakpoint {
          line-height: unitless-calc($header-lineheight, $font-size-temp);
        }

        @if map-has-key($header-defs, margin-top) {
          margin-top: rem-calc(map-get($header-defs, margin-top));
        } @else if map-has-key($header-defs, mt) {
          margin-top: rem-calc(map-get($header-defs, mt));
        } @else if $size == $-zf-zero-breakpoint {
          margin-top: 0;
        }
        @if map-has-key($header-defs, margin-bottom) {
          margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
        } @else if map-has-key($header-defs, mb) {
          margin-bottom: rem-calc(map-get($header-defs, mb));
        } @else if $size == $-zf-zero-breakpoint {
          margin-bottom: rem-calc($header-margin-bottom);
        }
      }
    }
  }
}


html {
  font-size: 16px;
}

h1,
h2,
h3 {
  font-weight: 300;
}

h4,
h6 {
  font-weight: normal;
}

h5 {
  font-weight: bold;
}

h1.super,
.h1.super {
  @include breakpoint(large up) {
    font-size: rem-calc(54);
  }
}

// Mixin base p() styles fo re-use in elements without variable sizing
@mixin p($size: "") {
  font-weight: 400;

  @if $size == "small" {
    font-size: rem-calc(13);

    @include breakpoint(large up) {
      font-size: rem-calc(15);
    }
  } @else if $size == "xsmall" {
        font-size: rem-calc(11);

    @include breakpoint(large up) {
      font-size: rem-calc(12);
    }
  }
}

a,
.a {
  @include p();
}

p,
.p {
  display: block;

  margin-bottom: 23px;

  font-size: rem-calc(16);

  @include breakpoint(large up) {
    margin-bottom: 26px;

    font-size: rem-calc(18);
  }
  @include p();
  &.bold {
    font-weight: 700;
  }
  &.intro,
  &.lead {
    font-size: rem-calc(19);

    @include breakpoint(large up) {
      font-size: rem-calc(22);
    }
  }
  &.small {
    font-size: rem-calc(13);

    @include breakpoint(large up) {
      font-size: rem-calc(15);
    }
  }
  &.xsmall {
    font-size: rem-calc(11);

    @include breakpoint(large up) {
      font-size: rem-calc(12);
    }
  }
  &.hint {
    margin-bottom: 0;

    color: $grey-400;
  }
}
