.fil-star-ticker {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);

  section.bg-grey-50 {
    margin-top: rem-calc($component-padding-base * 2);
  }

  .main-title {
    position: relative;
    color: $grey-500;
    margin-bottom: rem-calc(40);
    padding-bottom: rem-calc(30);
    font-weight: 400;


    &::after {
      display: block;
      position: absolute;
      left: 50%;
      margin-left: rem-calc(-25);
      bottom: 0;
      content: "";
      height: rem-calc(3);
      width: rem-calc(50);
      background-color: $blue-500;
    }
  }

  .star-ticker {
    text-align: center;
    padding: rem-calc(0 20);
    border-right: rem-calc(1) solid $grey-100;

    .above {
      margin-bottom: rem-calc(5);
    }

    .figure {
      display: block;
      font-size: rem-calc(80);
      color: $blue-500;
      margin-bottom: rem-calc(20);
    }

    .below {
      margin-bottom: 0;
    }

    &:last-of-type {
      border-right: none;
    }
  }
}

@include breakpoint(medium down) {
  .fil-star-ticker {
    .star-ticker {
      .figure {
        font-size: rem-calc(44);
      }
    }
  }
}

@include breakpoint(small down) {
  .fil-star-ticker {
    .star-ticker {
      border-right: none;
      border-bottom: rem-calc(1) solid $grey-100;

      .above {
        padding-top: rem-calc(20);
      }

      .figure {
        font-size: rem-calc(60);
      }

      .below {
        padding-bottom: rem-calc(20);
      }

      &:last-of-type {
        border-bottom: none;

        .below {
          padding-bottom: 0;
        }
      }
    }
  }
}
