// TODO: Optimise file
// TODO: Use foundation classes where possible (text alignment and font-weight)

.fil-article-grid {
  padding: rem-calc($component-padding-base 0 0);

  &.bg-grey-50 {
    padding-bottom: rem-calc($component-padding-base) !important;
  }

  .break-point-margin {
    @include breakpoint(medium down) {
      margin-top: rem-calc(64);
    }
  }

  .title-keyline::after {
    background-color: $orange-400;
  }

  .card {
    border: rem-calc(1) solid $grey-100;
    border-radius: rem-calc(4);
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc($component-padding-base);
    padding: rem-calc(20 24 32);

    @include breakpoint(medium) {
      height: 100%;
    }

    &__excerpt {
      margin-bottom: 0;
    }

    &__separator {
      // <hr> disappears in flexbox without this setting
      margin-left: 0;
      margin-right: 0;

      @include breakpoint(medium) {
        margin-top: auto;
      }
    }

    .category {
      color: $orange-700;
      font-size: rem-calc(18);
      margin-bottom: rem-calc(12);

      a {
        color: inherit;
        font-weight: inherit;
      }
    }

    .card-section {
      h4 {
        color: $grey-900;
        margin-bottom: rem-calc(18);
      }

      &.author {
        .cell {
          margin: 0;
        }

        p {
          &.small {
            margin-bottom: 0;
            min-height: auto;

            &.article-date {
              .label-pro {
                max-width: 100%;
                padding: rem-calc(3 10);
                overflow: hidden;
                border: rem-calc(1) solid transparent;
                border-radius: rem-calc(2);
                font-size: rem-calc(14);
                letter-spacing: rem-calc(.4);
                line-height: 1;
                text-overflow: ellipsis;
                vertical-align: middle;
                background: $blue-200;
                white-space: nowrap;
                color: $white;
                font-weight: 700;
                display: inline-block;
              }
            }
          }
        }

        @include author_avatar(66);
      }
    }
  }

  .articles {
    &.grid-margin-x {
      margin-left: rem-calc(-15);
      margin-right: rem-calc(-15);

      .cell {
        margin-left: rem-calc(15);
        margin-right: rem-calc(15);
      }
    }
  }

  .more-articles {
    padding: rem-calc(36 0 $component-padding-base);

    .fil-icon-arrow-r-light {
      &::after {
        margin-left: rem-calc(8);
        font-weight: bold;
      }
    }
  }

  .owl-dots {
    text-align: center !important;
  }
}

@include breakpoint(small only) {
  .fil-article-grid {
    padding-bottom: rem-calc($card-grid-padding-bottom-mobile);

    &.bg-grey-50 {
      padding-bottom: rem-calc($card-grid-padding-bottom-grey-mobile) !important;
    }

    .card {
      margin-bottom: rem-calc($card-padding-bottom-mobile);

      .card-section {
        h4,
        p {
          min-height: auto;
        }

        a {
          display: block;
        }

        img {
          margin-right: rem-calc(20);
        }
      }
    }

    .articles {
      &.grid-margin-x {
        margin-left: 0;
        margin-right: 0;

        .cell {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .owl-item.active {
        margin-right: 0 !important;
      }
    }
  }
}
