.intl {
  .article_section--top_separator {
    border-top: rem-calc(1) solid $grey-100;
  }

  .article_section--bottom_separator {
    border-bottom: rem-calc(1) solid $grey-100;
  }

  & > .cell.auto {
    @include breakpoint(small only) {
      margin-top: 0;
    }
  }
}

.editorial {
  &.fil-hero-component__wrapper {
    padding: 0;

    .fil-hero__bg-image {
      background-position: center 20%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: rem-calc(250);
      transition: all 0.5s ease;
      width: 100%;

      @include breakpoint(medium) {
        height: rem-calc(330);
      }
    }

    .fil-hero-component {
      margin-top: rem-calc(-96);

      h1 {
        margin: 0;
        padding: rem-calc(16 0 0);

        @include breakpoint(medium) {
          padding: rem-calc(32 0 0);
        }

        @include breakpoint(large) {
          padding: rem-calc(48 0 0);
        }
      }
    }
  }

  &.fil-article-page {
    border-top: rem-calc(1) solid $grey-100;

    // content-grid-container and content-inner-container
    // padding styles ensure alignment with grid
    .content-grid-container {
      padding: rem-calc(0 45);
    }

    .content-inner-container {
      padding: rem-calc(0 5);
      position: relative;
    }

    @include breakpoint(medium only) {
      .content-inner-container {
        padding: rem-calc(0 2.5);
      }
    }

    @include breakpoint(small only) {
      .content-grid-container {
        padding: rem-calc(0 30);
      }

      .content-inner-container {
        padding: rem-calc(0);
      }
    }

    .article-header {
      border: none;
      padding: 0;
      margin: rem-calc(40 0);

      &__title {
        margin-bottom: rem-calc(24);
        font-weight: bold;
      }

      &__author-name {
        display: inline-block;
      }

      @include breakpoint(small only) {
        margin: rem-calc(24 0 32);

        &__title {
          text-align: center;
        }
      }
    }

    .article-body {
      color: $grey-600;
      margin-top: rem-calc(40);

      @include breakpoint(small only) {
        margin-top: rem-calc(32);
      }
    }
  }

  &.fil-article-list,
  &.fil-article-grid {
    .avatar {
      height: rem-calc(66);
    }
  }
}

