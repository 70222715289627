.intl {
  .fil-author-page {
    padding: rem-calc($component-padding-base 0);

    h3 {
      font-size: rem-calc(18);
    }

    .author-page__author-image {
      border-radius: 50%;
      margin: rem-calc(12 0);
      height: rem-calc(68);
      width: rem-calc(68);
    }

    .author-page__meta,
    .author-page__bio-description {
      margin-bottom: rem-calc(24);
    }
  }
}
