

.fil-callback-form {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  h6 {
    margin-bottom: rem-calc(5);
  }

  .bio-container {
    margin-bottom: rem-calc(40);

    img {
      height: rem-calc(150);
      width: rem-calc(150);
      border-radius: rem-calc(100);
    }

    .bio-content {
      padding-top: rem-calc(20);

      p {
        margin-bottom: rem-calc(0);
      }
    }
  }

  /* stylelint-disable selector-max-specificity */
  // TODO: selector should not target ID
  #callback_form {
    select {
      background-size: rem-calc(10);
    }

    input
    &[type="text"] {
      width: 100%;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .page-level-error {
      display: none;
    }

    .parsley-errors-list {
      display: none;
    }

    .parsley-error,
    parsley-error:focus {
      outline: none;
      border: rem-calc(2) solid $error-red-500;
      background-color: $white;
    }

    div.checkbox.parsley-error {
      border: none;

      label {
        border: rem-calc(2) solid $error-red-500;

        &.label_content {
          border: 0;
          color: $error-red-500;
        }
      }
    }

    p.small {
      margin-left: rem-calc(45);
    }

    .message-box {
      .message-box__icon {
        width: rem-calc(55);
      }

      .message-box__body {
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(15);
      }

      p {
        margin-bottom: rem-calc(0);
      }
    }

    h4 {
      margin-top: rem-calc(20);
    }
  }

  #btncallback {
    margin-bottom: 0;
  }
  /* stylelint-enable */
}

@include breakpoint(small only) {
  .fil-callback-form {
    .bio-container {
      text-align: center;

      .bio-content {
        border-bottom: rem-calc(1) solid $grey-100;

        p {
          padding-bottom: rem-calc(20);
        }
      }
    }
  }
}
