@import './fil-card-colors';

@mixin set-selector-card-theme($selector) {
  @each $color, $value in $selector-color-defs {
    #{$selector}.#{$color} {
      .card-header {
        background: map-get($value, 'back');
      }

      .tool-name {
        color: map-get($value, 'color');
      }
    }
  }
}
