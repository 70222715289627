.fil-article-sections {
  padding: rem-calc(28 0 21);
  border-bottom: rem-calc(1) solid $grey-100;
  border-top: rem-calc(1) solid $grey-100;
  margin-bottom: rem-calc(40);

  &-title {
    margin-bottom: rem-calc(28);
  }

  &-list {
    margin-bottom: 0;

    li:last-child {
      margin-bottom: 0;
    }
  }

  @include breakpoint(small only) {
    padding: rem-calc(30 0 24);
    margin-bottom: rem-calc(32);
  }
}