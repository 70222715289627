.arrow-right-cta {
  font-size: rem-calc(18);
  display: inline-block;

  &::after {
    content: icon(arrow-r-light);
    font-family: 'fidelity-icon-font';
    font-weight: 700;
    position: relative;
    top: rem-calc(1);
  }

  &:focus {
    outline: rem-calc(2) solid $black;
    border-radius: rem-calc(1);
    text-decoration: none;
  }
}

a,
button {
  &.secondary {
    &.bg-white {
      background-color: $white;
      color: $grey-700;

      &:hover {
        color: $grey-900;
        background-color: $grey-200;
        text-decoration: none;
      }

      &:focus {
        background-color: $white;
        color: $grey-700;
      }
    }
  }

  &.text-white {
    &:hover {
      color: $blue-300;
      text-decoration: none;
    }
  }
}

// fil show/hide button with swapping text and roll style icon animation

// animation Var
$base-showhide-anim-speed: 0.2s;

// fil show/hide button BASE styles
.fil-showhide__button {
  display: inline-block;
  position: relative;
  padding-top: rem-calc(8);
  font-weight: bold;
  font-size: rem-calc(18);

  &.top-border {
    padding-top: rem-calc(16);
    border-top: rem-calc(2) solid $grey-100;
  }

  &:hover {
    text-decoration: none;
    color: $blue-800;

    .fil-icon-container {
      &::before,
      &::after {
        transition: all 0.35s ease;
      }

      &::before {
        transform: scale(1, 1) translateY(0);
      }

      &::after {
        transform: scale(1, 1) translateY(rem-calc(18));
      }
    }
  }

  .showhide-trigger__text {
    display: inline;
    float: left;
  }

  .showhide-trigger__text-open {
    display: inline;
  }

  .showhide-trigger__text-close {
    display: none;
  }

  &.is-active {
    .showhide-trigger__text-open {
      display: none;
    }

    .showhide-trigger__text-close {
      display: inline;
    }

    &::after {
      transition: opacity $base-showhide-anim-speed ease;
      opacity: 0;
    }

    .fil-icon-container {
      top: rem-calc(8);
      transition: all 0s ease;

      &::before {
        transform: scale(1, -1) translateY(rem-calc(-18));
      }

      &::after {
        transform: scale(1, -1) translateY(0);
      }
    }

    &:hover {
      .fil-icon-container {
        transition: all 0.35s ease;

        &::before {
          transform: scale(1, -1) translateY(0);
        }

        &::after {
          transform: scale(1, -1) translateY(rem-calc(18));
        }
      }
    }
  }

  .fil-icon-container {
    position: relative;
    float: left;
    margin-left: rem-calc(5);
    display: inline-block;
    width: rem-calc(18);
    height: rem-calc(18);
    overflow: hidden;

    &::before,
    &::after {
      font-family: 'fidelity-icon-font';
      content: icon(chevron-down-s);
      display: block;
      position: absolute;
      right: 0;
      width: rem-calc(18);
      height: rem-calc(18);
      transition: all 0s ease;
    }

    &::before {
      transform: scale(1, 1) translateY(rem-calc(-18));
    }

    &::after {
      transform: scale(1, 1) translateY(0);
    }
  }
}
