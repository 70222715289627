$modal-video-background: rgba(255, 255, 255, 0.7);
$modal-video-close-btn-background: rgba(9, 9, 26, 0.7);

$modal-video-mobile-width: 90%;
$modal-video-movie-wrap-padding: rem-calc(10);
$modal-video-close-top: rem-calc(-25);
$modal-video-close-right: rem-calc(-25);
$modal-video-close-width: rem-calc(32);
$modal-video-close-height: rem-calc(32);

.modal-video {
  background: $modal-video-background;

  .modal-video-body {
    @include breakpoint(medium down) {
      width: $modal-video-mobile-width;
    }

    .video-container {
      background: $white;
      padding: rem-calc(10);
    }
  }

  .modal-video-movie-wrap {
    border: $modal-video-movie-wrap-padding solid $white;
    box-shadow: 0 0 rem-calc(20) $grey-300;
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: $modal-video-close-top;
    right: $modal-video-close-right;
    display: inline-block;
    width: $modal-video-close-width;
    height: $modal-video-close-height;
    overflow: hidden;
    border: none;
    border-radius: rem-calc(16);
    background: $modal-video-close-btn-background;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: rem-calc(2);
      width: 50%;
      top: 65%;
      left: rem-calc(8);
      background: $white;
      border-radius: rem-calc(5);
      margin-top: rem-calc(-6);
    }
  }
}
