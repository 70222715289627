.fund-details_container {
  .accordion-content {
    padding: 0;
    border: none;
  }

  :last-child > .accordion-content:last-child {
    border-bottom: none;
  }
}

.broker-details_container {
  .accordion-content {
    padding: 0;
    border: none;
  }

  :last-child > .accordion-content:last-child {
    border-bottom: none;
  }
}
