.fil-email-form {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  /* stylelint-disable selector-max-specificity */
  // TODO: replace ID selector
  #email_form {
    input &[type='text'] {
      width: 100%;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    &textarea {
      width: 100%;
    }

    .checkbox-container {
      margin-top: rem-calc(40);

      .checkbox {
        margin-bottom: rem-calc(40);

        &:last-child {
          margin-bottom: rem-calc(8);
        }
      }
    }

    label.label_content {
      margin-left: rem-calc(40);
      display: block;
      position: relative;
      margin-top: rem-calc(-30);
      line-height: rem-calc(25);
    }

    .page-level-error,
    .parsley-errors-list {
      display: none;
    }

    .parsley-error,
    parsley-error:focus {
      outline: none;
      border: rem-calc(2) solid $error-red-500;
      background-color: $white;
    }

    select {
      background-size: rem-calc(16);
    }

    div.checkbox.parsley-error {
      border: none;

      label {
        border: rem-calc(2) solid $error-red-500;

        &.label_content {
          border: 0;
          color: $error-red-500;
        }
      }
    }

    .message-box {
      .message-box__icon {
        width: rem-calc(55);
      }

      .message-box__body {
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(15);
      }

      p {
        margin-bottom: rem-calc(0);
      }
    }

    .disclaimer-container {
      margin-top: rem-calc(40);
    }
  }

  #btncallback {
    margin-bottom: 0;
  }
  /* stylelint-enable */
}
