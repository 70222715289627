.fil-media-grid {
  padding: rem-calc($component-padding-base 0);

  .media-grid-item {
    margin: rem-calc(40 0 0);
    position: relative;
  }

  .cell {
    &:first-of-type {
      padding: rem-calc(0 28 0 0);
    }

    &:nth-of-type(2) {
      padding: rem-calc(0 0 0 28);
    }

    a:last-of-type {
      margin-top: rem-calc(20);
    }

    section {
      &:first-of-type {
        padding-top: 0;
        margin-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .fil-media-grid__video {
    height: 0;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: rem-calc(25);
    position: relative;

    iframe {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 9;
    }
  }

  .fil-media-grid__qumu {
    position: relative;
    z-index: 9;

    iframe {
      width: 100% !important;
      height: rem-calc(306) !important;
    }
  }

  .fil-cta {
    padding: 0;

    .align-center {
      -ms-flex-pack: left;
      justify-content: left;
    }
  }

  .grey,
  .white,
  .wealth {
    padding: rem-calc(20 0 20 80);
    position: relative;

    .cell:last-of-type {
      padding-right: 0;
    }

    img {
      position: relative;
      z-index: 9;
    }

    &.left {
      .cell:last-of-type {
        padding-right: rem-calc(15);
      }
    }
  }

  .left {
    padding: rem-calc(56 80 56 0);

    &::before {
      content: '';
      z-index: 8;
      position: absolute;
      top: 0;
      left: 0;
      width: 8%;
      height: 100%;
    }

    &::after {
      display: none;
    }

    &.white {
      &::before {
        background-color: $grey-50;
      }
    }

    &.grey,
    &.wealth {
      &::before {
        background-color: $white;
      }
    }
  }

  .right {
    padding: rem-calc(56 0 56 80);

    &::after {
      content: '';
      z-index: 8;
      position: absolute;
      top: 0;
      right: 0;
      width: 8%;
      height: 100%;
    }

    &::before {
      display: none;
    }

    &.white {
      &::after {
        background-color: $grey-50;
      }
    }

    &.grey,
    &.wealth {
      &::after {
        background-color: $white;
      }
    }
  }

  h3 {
    position: relative;
    font-weight: bold;
    font-size: rem-calc(37);
    margin: rem-calc(-9 0 24);
  }

  p {
    margin-bottom: rem-calc(30);

    a {
      display: inline;
    }
  }

  li {
    a {
      display: inline;
    }
  }

  a {
    display: block;
  }

  .button {
    margin: 0;
    margin-bottom: rem-calc(30);
    width: inherit;
  }

  .grey {
    background-color: $grey-50;
  }

  .white {
    background-color: $white;
  }

  .wealth {
    background-color: $white-dim;
  }

  video {
    position: relative;
    z-index: 9;
  }

  &.bg-grey-50 {
    .grey,
    .white,
    .wealth {
      &::before,
      &::after {
        background-color: $grey-50;
      }
    }
  }
}

@include breakpoint(small only) {
  .fil-media-grid {
    .media-grid-item {
      margin: rem-calc(25 0 0);
    }

    .cell {
      &:first-of-type {
        padding-left: 0;
        padding-right: 0;
      }

      &:nth-of-type(2) {
        padding-left: 0;
      }

      section {
        h3 {
          margin-top: rem-calc(20);
          margin-bottom: rem-calc(20);
          font-size: rem-calc(33);
        }

        p {
          margin-bottom: rem-calc(20);
        }

        .arrow-right-cta {
          display: block;
          margin-bottom: rem-calc(20);
        }

        &:first-of-type {
          padding-top: inherit;
        }

        &:last-of-type {
          padding-bottom: inherit;
        }
      }
    }

    .text-left {
      &:first-of-type {
        padding-left: 0;
      }
    }

    .grey,
    .white,
    .wealth {
      padding: rem-calc(0 0 20 0);

      .cell {
        padding: 0;

        h3,
        p {
          padding: rem-calc(0 20);
        }

        &:first-of-type {
          order: 2;
        }
      }

      &::before,
      &::after {
        display: none;
      }

    }

    .grey {
      .cell {
        ul {
          padding: rem-calc(0 24);
        }
      }
    }
  }
}
