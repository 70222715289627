// Stupid override for global component site page template
$footer-padding-mobile: $component-padding-base * 2;
$footer-internal-padding-top: 24;
$footer-padding: $component-padding-base * 2 - $footer-internal-padding-top;

/* stylelint-disable selector-max-specificity */
// TODO: replace ID selector
#body-container .fil-global-footer .fil-accordion {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
/* stylelint-enable */

.fil-global-footer {
  .fmr-logo {
    margin-left: rem-calc(24);
  }

  .fil-global-footer__fmr-copyright {
    margin-left: rem-calc(8);
    border-left: rem-calc(2) solid $grey-400;
    padding-left: rem-calc(12);
  }

  &__disclaimer {
    @include breakpoint(medium down) {
      margin-top: rem-calc(48);
    }

    p {
      margin-bottom: 0;
      font-size: rem-calc(18);
    }
  }

  .fil-global-footer__link-lists {
    padding-top: rem-calc($footer-padding) !important;
    padding-bottom: rem-calc($footer-padding) !important;
    margin-top: rem-calc($component-padding-base) !important;
    margin-bottom: 0 !important;

    h5 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .fil-accordion {
    .accordion-item {
      border: none;

      .accordion-title {
        color: $grey-900;
        font-weight: bold;
        padding-top: rem-calc(24);
        padding-right: 0;
        padding-bottom: rem-calc(32);
        border-bottom: rem-calc(1) solid $grey-100;

        &::before {
          display: none;
        }
      }

      .accordion-content {
        padding-top: rem-calc(40);

        a {
          font-size: rem-calc(18);
        }
      }
    }
  }

  .fil-global-footer__bottom-menu-logo {
    margin-left: rem-calc(36);
    margin-right: rem-calc(24);

    // TODO: replace ID selector
    #symbol-container { /* stylelint-disable-line selector-max-specificity */
      stroke: $grey-300;
    }
  }

  .fil-global-footer__copyright {
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);

    p {
      color: $grey-100;
      padding-bottom: 0;
      margin-bottom: rem-calc(8);
    }

    .legal-privacy-container {
      > a,
      > span {
        color: $grey-50;
        display: inline-block;

        &:first-child {
          padding-right: rem-calc(12);
          border-right: rem-calc(2) solid $grey-400;
        }

        &:last-child {
          margin-left: rem-calc(12);
        }
      }
    }

    .small:last-of-type {
      margin-bottom: 0;
    }
  }

  .justify-self-end {
    justify-self: flex-end;
  }

  .fil-global-footer__social {
    justify-self: flex-end;
    margin-left: rem-calc(32);
    margin-right: rem-calc(40);
  }

  .return-to-top-button__container {
    position: relative;
    width: rem-calc(96);
    height: rem-calc(96);

    &--float {
      position: fixed;
      right: rem-calc(18);
      bottom: rem-calc(18);
      width: rem-calc(55);
      height: rem-calc(55);
      opacity: .8;
      z-index: 99;

      .return-to-top-button {
        border-left: 0;
      }
    }
  }

  .return-to-top-button {
    color: $white;
    font-weight: bold;
    border-left: rem-calc(2) solid $grey-400;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      margin: 0;
      font-size: rem-calc(28);
      transform: scale(1, 0.8);
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .fil-global-footer__social-button {
    background-color: $grey-400;
    border-radius: 50%;
    width: rem-calc(48);
    height: rem-calc(48);
    position: relative;
    margin-bottom: 0;
    margin-left: rem-calc(28);
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      font-weight: normal;
      font-size: rem-calc(24);
      color: $white;
    }

    &.fil-icon-logo-googleplus {
      &::after {
        content: icon(logo-googleplus) !important;
      }
    }

    &.fil-icon-logo-linkedin {
      &::after {
        content: icon(logo-linkedin) !important;
      }
    }

    &.fil-icon-logo-facebook {
      &::after {
        content: icon(logo-facebook) !important;
      }
    }

    &.fil-icon-logo-instagram {
      &::after,
      &::before {
        display: none;
      }

      img {
        width: rem-calc(50);
      }
    }

    &.fil-icon-logo-twitter {
      &::after {
        content: icon(logo-twitter) !important;
      }
    }

    &.fil-icon-logo-youtube {
      &::after {
        content: icon(logo-youtube) !important;
      }
    }

    &.fil-icon-logo-xing {
      &::after {
        content: icon(logo-xing) !important;
      }
    }
  }
}

@include breakpoint(large) {
  .return-to-top-button {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .fil-global-footer__social-button {
    &:hover {
      background-color: $grey-300;
    }
  }

  .fil-global-footer__copyright {
    a:hover {
      color: $grey-200;
    }
  }

  .fil-global-footer {
    .fil-accordion {
      .accordion-item {
        .accordion-content {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(medium down) {
  .fil-global-footer {
    &.backup-mode {
      .fil-accordion {
        .accordion-item {
          &.is-active {
            .accordion-content {
              padding: rem-calc(40 0 13);
              transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
              opacity: 1;
              max-height: rem-calc(900);
            }
          }

          .accordion-content {
            padding: 0;
            padding-top: 0;
            transition: opacity 0.25s ease-out, max-height 0.3s ease-out, padding 0.3s linear;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            display: block;
          }
        }
      }
    }

    .fil-global-footer__link-lists {
      padding-left: rem-calc(5);
      padding-right: rem-calc(5);
      padding-top: rem-calc($footer-padding-mobile) !important;
      padding-bottom: rem-calc($footer-padding-mobile) !important;

      .cell:first-of-type {
        .fil-accordion {
          .accordion-item {
            border-top: rem-calc(1) solid $grey-100;
          }
        }
      }
    }

    .fil-accordion {
      .accordion-item {
        border-bottom: rem-calc(1) solid $grey-100;

        .accordion-title {
          color: $grey-900;
          font-weight: bold;
          padding-top: rem-calc(16);
          padding-right: 0;
          padding-bottom: rem-calc(16);
          border-top: none;
          border-bottom: none;

          &::before {
            display: inline-block;
          }
        }

        .accordion-content {
          padding-top: rem-calc(40);
        }
      }
    }

    .fil-global-footer__bottom-menu-logo {
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: rem-calc(16);
    }

    .fil-global-footer__social {
      margin-left: auto;
      margin-right: auto;
      padding-top: rem-calc(28);
      padding-bottom: rem-calc(32);
      justify-content: center;

      .fil-global-footer__social-button {
        margin-left: 0;
        margin-right: rem-calc(14);
        padding: 0;
        width: rem-calc(40);

        img {
          width: rem-calc(24);
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .return-to-top-button__container {
      width: 100%;
      height: rem-calc(48);

      .return-to-top-button {
        width: 100%;
        border-left: none;
        border-top: rem-calc(2) solid $grey-500;

        &::after {
          font-size: rem-calc(24);
        }
      }
    }
  }

  .fil-global-footer__bottom-menu {
    padding-top: rem-calc(32);
  }

  .fil-global-footer__bottom-menu-logo,
  .fil-global-footer__copyright {
    text-align: center;
  }
}

@include breakpoint(small only) {
  .fil-global-footer {
    .fil-global-footer__link-lists {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
