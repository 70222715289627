.editorial {
  .media-grid-item {
    .left {
      .cell {
        &:first-of-type {
          order: 1;
          padding-left: 0;
          padding-right: 0;

          @include breakpoint(medium) {
            padding-left: rem-calc(28);
          }
        }

        &:last-of-type {
          order: 0;
          padding-left: 0;
          padding-right: 0;

          @include breakpoint(medium) {
            padding-right: rem-calc(28);
          }
        }
      }
    }

    .preview-articles {
      margin-bottom: rem-calc(16);

      p {
        margin: 0;
      }

      .fil-icon {
        display: none;
      }
    }
  }
}
