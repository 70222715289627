$staticPrefix: '/static/' !default;
$default-thumbnail: '#{$staticPrefix}fil_site_assets/images/no-video.png' !default;

@import './fil-model-video-fix';

.fil-video-gallery {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  &.reveal {
    padding: 0;
    border: none;

    h4 {
      padding: rem-calc(15 60 15 20);
      margin: 0;
    }
  }

  &.grey-True {
    .card {
      background-color: $white;
    }
  }

  .card {
    margin-bottom: rem-calc(20);
    background-color: $grey-50;

    .thumbnail {
      position: relative;
      background-color: $black;

      .thumbnail-inner-wrapper {
        .kv-play-icon {
          display: none !important;
        }

        .thumbnail-empty {
          .kv-empty {
            background: url($default-thumbnail) no-repeat center 43%;
            background-size: 180%;
            padding: 0;
            cursor: not-allowed;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100% !important;
          }
        }

        a.kv-packshot::before {
          display: none;
        }

        .kv-packshot-image {
          width: 120% !important;
        }

        .kv-kulu {
          overflow-x: hidden;
        }

        .kv-thumbnail-holder {
          max-width: 100%;
        }

        .play-button {
          position: absolute;
          right: rem-calc(1);
          bottom: rem-calc(1);
          cursor: pointer;

          &.normal {
            display: block;
          }

          &.hover {
            display: none;
          }
        }

        &:hover {
          img:first-of-type {
            opacity: 0.8;
          }

          .normal {
            display: none;
          }

          .hover {
            display: block;
          }
        }

        &.youtube {
          height: rem-calc(221);
          cursor: pointer;

          .video-thumbnail {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }

          .play-button {
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    .thumbnail-image {
      height: rem-calc(186);
    }

    .card-details {
      text-align: left;
      padding-top: rem-calc(25);
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
      position: relative;

      h4 {
        font-weight: bold;

        @include support-cjk-lang {
          font-size: rem-calc(22);
        }
      }

      a {
        outline: none;
        text-decoration: none;
        color: $grey-900;

        &:hover {
          color: $blue-500;
        }
      }

      .meta {
        position: absolute;
        bottom: rem-calc(25);
        left: rem-calc(20);
        right: rem-calc(20);

        p.small {
          margin-bottom: 0;

          a {
            color: $blue-500;

            &:hover {
              color: $grey-900;
            }
          }
        }
      }
    }
  }

  .paginator {
    &__nav,
    &__per-page {
      width: 100%;
    }

    &__per-page {
      > label,
      > select {
        margin-bottom: 0;
      }
    }

    &__nav.hide + .paginator__per-page {
      margin-top: rem-calc(32);
    }
  }

  &.bg-grey-50 {
    .card {
      background-color: $grey-25;
    }

    .paginator__nav-button--digit:not(.paginator__nav-button--current) {
      background-color: $grey-25;
    }
  }
}

@include breakpoint(small only) {
  .fil-video-gallery {
    &.reveal {
      height: auto;
      min-height: auto;
    }

    .card {
      margin: 0 0 rem-calc(20);

      .fil-video-widget {
        .kv-packshot-holder {
          width: 100% !important;
        }
      }

      .card-details {
        h4 {
          font-weight: bold;
          margin-bottom: rem-calc(20);

          @include support-cjk-lang {
            font-size: rem-calc(19);
          }
        }

        .meta {
          position: static;
          margin-bottom: rem-calc(25);
        }
      }
    }
  }
}
