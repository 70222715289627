/*
  These modifications to the Foundation Grid are to ensure that there is always 
  a decent amount of padding around each row whenever the viewport is large enough
  to warrant it.
*/


/*
  This custom wrapper will set the outer padding of the "page" according to the
  current viewport width.
*/
$gutters: $grid-column-gutter;
.wrapper {
  max-width: $global-width;
  margin: 0 auto;

  @include grid-column-gutter($gutters: $gutters);
}

/*
  1. Remove all horizontal padding by default
  2. ...then, when the viewport is wide enough, add the appropriate padding.
*/
.row {
  padding-right: 0; /* [1] */
  padding-left: 0;

 @include breakpoint(medium up) {
     @include grid-column-gutter($gutters: $gutters); /* [2] */
 }
}


.row--table {
  display: table;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  .radio {
    display: table-cell;
  }
}