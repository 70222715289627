.broker-details_container {
  padding-top: rem-calc(32);
  padding-bottom: rem-calc(64);
}

.broker-details_img {
  border-radius: 50%;
  width: 120%;
  height: auto;
}

.broker-details {
  .top-row {
    border-bottom: rem-calc(1) solid $grey-200;
    margin-bottom: rem-calc(16);

    h2 {
      display: inline-block;
      font-weight: bold;
      margin-bottom: rem-calc(16);
      margin-right: rem-calc(16);
    }
  }

  .broker-details_label {
    display: inline-block;
    width: rem-calc(158);
  }

  .broker-details_data {
    font-weight: bold;
  }

  p {
    margin-bottom: rem-calc(8);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@include breakpoint(medium down) {
  .broker-details_img {
    position: relative;
    max-width: rem-calc(160);
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@include breakpoint(small only) {
  .broker-details_container {
    padding-bottom: rem-calc(48);
  }

  .broker-details {
    .top-row {
      border-bottom: none;

      h2 {
        display: block;
        margin-top: rem-calc(16);
        margin-right: 0;
        text-align: center;
      }

      a {
        display: block;
        position: relative;
        margin: auto;
        margin-bottom: rem-calc(16);
        text-align: center;
      }
    }

    .broker-details_accordion {
      p:first-of-type {
        padding-top: rem-calc(16);
        border-top: rem-calc(1) solid $grey-200;
      }

      p:last-of-type {
        padding-bottom: rem-calc(16);
      }
    }

    .broker-details_label {
      display: block;
      width: 100%;
      text-align: center;
    }

    .broker-details_data {
      display: block;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }

    p {
      margin-bottom: rem-calc(8);
    }
  }
}
