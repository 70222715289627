// Remove global top margin on DataTables
.dataTables_wrapper table.dataTable {
  margin-top: 0 !important;
}
// Fix width of length menu in datatables.
div.dataTables_wrapper div.dataTables_length.dataTables_length select {
  width: 95px;
}

// General table styling
table {
  border-collapse: collapse;
}

table tr {
  transition: background-color .15s ease-out;

  background-color: $white;
  &:hover {
    background-color: $blue-25;
  }
}

th {
  color: $white;
  background-color: $grey-500;
}

thead,
tbody {
  border: 0;
}

td {
  border-bottom: 1px solid $grey-100;
  &:first-of-type {
    border-left: 1px solid $grey-100;
  }
  &:last-of-type {
    border-right: 1px solid $grey-100;
  }
}

.dt-foundation > .row {
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(20);
}

// Stack tables styling.
@include breakpoint(medium down) {
  table.stack {
    border: none;
    background-color: transparent;
    tbody {
      border: none;
    }
    td {
      border-top: none;
      border-right: 1px solid $grey-50;
      border-bottom: none;
      border-left: 1px solid $grey-50;
      &:last-child {
        border-bottom: 1px solid $grey-50;
      }
      &:first-child {
        border-top: 1px solid $grey-50;
      }
      &:nth-child(even) {
        background-color: $grey-25;
      }
      &:nth-child(odd) {
        background-color: $white;
      }
    }
    tr {
      background-color: transparent;
      margin-bottom: rem-calc(20);

      border: none;
      &:hover {
        background-color: inherit;
      }
    }
  }
}

// Table Group
tr.group.group-start {
  color: $blue-500;
  background-color: $grey-25;

  font-weight: 700;
  td {
    position: relative;

    padding-left: 40px;
  }
  &:hover {
    cursor: pointer;

    background-color: $blue-50;
    td {
      text-decoration: underline;
    }
  }

  // Chevron
  td:before {
    position: absolute;
    left: 12px;

    content: icon('chevron-down-m');

    font-family: 'fidelity-icon-font';
  }

  &.open td:before {
    content: icon('chevron-up-m');
  }
}

// For fixed column hovering
tr.hover {
  background-color: $blue-25;
}

// Table Underscroll shadow
.DTFC_LeftWrapper {
  height: auto !important;
  &:after {
    position: absolute;
    top: 0;
    right: -6px;
    bottom: 0;

    width: 6px;

    content: '';

    background: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
  }
}


// #MISC PINS
[data-table-toggle] {
  z-index: 10;

  display: inline-block;

  width: 10px;
  height: 10px;

  border: 1px solid$white;
  background: rebeccapurple;
}

// #LIST TABLES
table.list {
  border: 1px solid $grey-50;

  tr {
    height:52px;

    @include breakpoint(large up) {
      height: 60px;

    }
    &:nth-child(2n) {
      background-color: $grey-25
    }

    &:hover {
      background-color: inherit;
    }
  }

  th {
    @include p();
    @include breakpoint(large up) {
      @include p("small");
    }
  }
  td {
    @include p();
  }

  
  th,
  td {
    display: table-cell;

    height: 52px;
    margin-bottom: 0;
    padding-top: 17px;
    padding-bottom: 17px;

    color: $grey-800;
    border: 0;
    background-color: inherit;

    font-weight: 400;
    line-height: 1;
    // line-height:52px;

    @include breakpoint(large up) {
      height: 60px;
      padding-top: 21px;
      padding-bottom: 21px;
    }
  }

  &.border {
    th,td {
      border-bottom: 1px solid $grey-50;
      background: $white;
    }
  }
}

// #TILE TABLES
table.tile {
  tr {
    border: 0;
    background-color: $grey-50;
  }
  td, th {
    display: block;
    // height: 26px;

    margin-bottom: 0;

    text-align: left;

    color: $grey-800;
    border: 0;
    background-color: inherit;

    font-weight: 400;
    line-height: 1;

    @include p();
  }
  
  th {
    padding-bottom: 0;

    @include p("small");
  }
  td {
    padding-top: 4px;
  }

  tr.important td,
  tr.important th {
    font-weight: 700;
  }

  &.border {
    tr {
      border: 1px solid $grey-50;
      background-color: $white;
    }
  }
}


// #SORT
.sorting {
  // padding-right: 20px;
}

// table.dataTable thead .sorting_desc,
$up-sort-offset: -65%;
$down-sort-offset: -35%;
table.dataTable thead {
  .sorting_asc.sorting_asc,
  .sorting_desc.sorting_desc {
    position: relative;

    background-image: none;
    // padding-right: 20px;

    &:after {
      position: absolute;
      top: 50%;
      right: 4px;

      transform: translateY(-50%);

      font-size: 20px;
    }
  }
  .sorting_desc.sorting_desc {
    &:after {
      content: icon(down-sort);
      transform: translateY($down-sort-offset);

      font-family: 'fidelity-icon-font';
    }
  }
  .sorting_asc.sorting_asc {
    &:after {
      content: icon(up-sort);
      transform: translateY($up-sort-offset);    

      font-family: 'fidelity-icon-font';
    }
  }
}

table.dataTable thead .sorting.sorting {
  position: relative;

  background-image: none;
  &:hover:before,
  &:hover:after {
    opacity: 1;
  }
  &:after,
  &:before {
    position: absolute;
    top: 50%;
    right: 4px;

    transition: opacity .15s ease-out;

    opacity: 0;
    color: $grey-300;

    font-family: 'fidelity-icon-font';
    font-size: 20px;
    // remove if part of a scroll body
    .dataTables_scrollBody & {
      content: none;
    }
  }

  &:before {
    content: icon(up-sort);
    transform: translateY($up-sort-offset);
  }
  &:after {
    content: icon(down-sort);
    transform: translateY($down-sort-offset);
  }
}