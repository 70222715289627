.font-white {
  color: $white;
}

.text-link {
  span {
    font-size: rem-calc(14.4);
  }
}

.text-lower-case {
  text-transform: lowercase;
}
