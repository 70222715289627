.fil-hero-homepage {

  .fil-hero-homepage__bg-image {
    background-size: cover;
  }
}

@include breakpoint(small only) {
  .fil-hero-homepage {

    .fil-hero-homepage__bg-image {
      position: relative;
      left: 0;
      object-fit: cover;
      width: 100%;
      padding-bottom: 66.6666666%;
    }
  }
}