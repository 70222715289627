.fil-article-share-links {
  margin-bottom: rem-calc(40);

  @include breakpoint(large) {
    display: none;
  }

  &__linkedin-container,
  &__email-container {
    margin: rem-calc(0 12);
    text-decoration: none;

    &::after {
      display: none;
    }
  }

  &__email,
  &__linkedin {
    border-radius: 50%;
    border: rem-calc(1) solid $grey-100;
    width: rem-calc(42);
    height: rem-calc(42);
    text-decoration: none;
    background: $white;
    display: flex;
    color: $grey-500;
    font-size: rem-calc(21);

    &:hover {
      border-color: $grey-300;
    }
  }

  &--desktop {
    display: inline-block;
    position: absolute;
    left: rem-calc(-90);

    @include breakpoint(medium down) {
      display: none;
    }

    .fil-article-share-links__email-container {
      margin-bottom: rem-calc(16);
    }
  }
}