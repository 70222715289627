.fil-date-and-time-horizon {
  color: $grey-900;
  text-align: center;

  &__date-wrapper {
    margin-bottom: 0;
    text-align: center;
  }

  &__date,
  &__time-horizon {
    font-weight: bold;
  }

  @include breakpoint(large) {
    text-align: left;

    &__date-wrapper {
      text-align: left;
      display: inline;
      padding-right: rem-calc(14);
      border-right: rem-calc(1) solid $grey-100;
    }

    &__time-horizon-wrapper {
      padding-left: rem-calc(14);
      display: inline;
    }
  }

  @include breakpoint(small only) {
    &__time-horizon-wrapper {
      margin-bottom: 0;
    }
  }
}
