$radioWidth: 12px;

// #RADIO BUTTON
.radio {
  margin-bottom: 8px;
  &.radio--med,
  &.radio--large {
    display: inline-block;
    float: left;
  }

  &.radio--med {
    display: block;
    @include breakpoint(medium up) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

// #RADIO INPUT

// chaining the same class selectors together boosts specificty by 1 for each
// selector. This is useful when overriding other styles without bumping specificity
// too much higher with heavier nesting or !important rules.
// See: https://csswizardry.com/2014/07/hacks-for-dealing-with-specificity/
.radio__input.radio__input {
  position: absolute;
  z-index: -1;
  // TODO: Uncomment visibility line to hide native radio button

  visibility: hidden;
  // width: 0;
  // height: 0;

  margin: 0;
  padding: 0;

  line-height: 0;
  &:checked + .radio__label:before,
  &:checked + .radio__container > .radio__label:before {
    position: absolute;
    // top: calc(50% - #{$radioWidth});
    // left: calc(50% - #{$radioWidth});
    top: 27%;
    left: 27%;

    width: $radioWidth;
    height: $radioWidth;

    content: '';

    border-radius: 100%;
    background: $blue-500;
  }
  // Medium Radio input checkbox hack
  // #MED RADIO CHECKED
  .radio--med & {
    display: block;

    width: 0;
    height: 0;
    &:focus + .radio__label--button {
      background-color: $blue-700;
    }
    &:checked + .radio__label--button {
      color: $white;
      background-color: $blue-500;
      &:before {
        display: inline-block;

        margin-right: 6px;

        content: icon(tick-bold);

        font-family: 'fidelity-icon-font';
        font-size: 12px;
      }
    }
  }
  .radio--large & {
    // Focus styles
    &:focus + .radio__container {
      background-color: $teal-25;
    }
    &:checked + .radio__container {
      color: $teal-700-contrast;
      border-color: $teal-700;
      background-color: $teal-700;
    }
    &:checked + .radio__container > .radio__label:before {
      background: $teal-500;
    }
    &:checked + .radio__container > .radio__label {
      border-color: $white;
    }
  }
}

// #RADIO LABEL
.radio__label.radio__label {
  vertical-align: middle;
}

.radio__label--button.radio__label--button {
  position: relative;

  display: inline-block;

  width: 28px;
  height: 28px;
  margin-right: 12px;
  margin-left: 0;

  transition: color .15s ease-out, background-color .15s ease-out;
  vertical-align: middle;

  border: 1px solid $grey-300;
  border-radius: 100%;
  background-color:$white;
  // #MED RADIO LABEL
  .radio--med & {
    display: inline-block;

    width: auto;
    height: 48px;
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px;

    color: $blue-700;
    border-color: $blue-500;
    border-radius: 0;
    background: $blue-50;

    line-height: 48px;
    &:hover {
      color: $white;
      background-color: $blue-700;
    }
  }
  .radio--large & {
    position: absolute;
    bottom: 20px;
    left: 50%;

    transform: translate(-50%, 0);

    border-color: $teal-500;
  }
}

.radio__container.radio__container,
.checkbox__container.checkbox__container {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 104px;
  margin-right: 0;
  margin-left: 0;
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 48px;
  padding-left: 24px;

  transition: background-color .15s ease;
  text-align: center;

  border: 1px solid $teal-500;
  border-bottom: 4px solid $teal-500;
  background-color: $grey-25;

  justify-content: center;
  &:hover {
    background-color: $teal-25;
  }
  .p {
    margin-bottom: 12px;

    line-height: 1.2;
  }
}