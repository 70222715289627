.important-article-block {
  padding-top: rem-calc(60);

  .clickable_item_not_anchor {
    color: $grey-800;

    &:hover {
      color: $grey-800;
    }
  }

  .subcategory {
    font-size: rem-calc(16);
    font-weight: bold;
    margin-bottom: rem-calc(20);
    line-height: rem-calc(22);

    @include breakpoint (medium down ) {
      padding-top: rem-calc(0);
    }
  }

  @include breakpoint ( medium down ) {
    padding-top: rem-calc(15);
  }

  .listed-article-column {
    @include breakpoint( medium up ) {
      padding-left: rem-calc(15);
    }
  }


  .primary_article {
    padding: rem-calc(0 0 90);

    @include breakpoint( medium down ) {
      padding: rem-calc(20 0);
    }

    &__teaser_image {
      background-position: 50% center;
      background-size: cover;
      height: 100%;
      min-height: rem-calc(172);

      @include breakpoint( medium down ) {
        height: rem-calc(210);
      }
    }

    &__teaser_content {
      top: 0;

      @include breakpoint( medium down ) {
        padding-top: rem-calc(20);
      }

      @include breakpoint( medium up ) {
        padding: rem-calc(0 30);
      }
    }

    &__title {
      margin: rem-calc(15 0);
      font-weight: bold;
      line-height: rem-calc(31);
      font-size: rem-calc(26);
    }

    &__description {
      margin-bottom: rem-calc(0);
      line-height: rem-calc(26);
    }
  }

  .featured_important_article {
    padding-right: rem-calc(30);

    @include breakpoint( medium down ) {
      padding-right: rem-calc(20);
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
    }

    &__container {
      &.right_padded_section {
        padding-right: rem-calc(15);
      }
    }

    &__other_details {
      padding-top: rem-calc(16);
    }

    &__content_wrapper {
      padding: rem-calc(15);

      @include breakpoint( medium down ) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__title {
      margin: 0;
      font-weight: bold;
      line-height: rem-calc(26);
      min-height: rem-calc(60);
      font-size: rem-calc(22);

      @include breakpoint( medium down ) {
        min-height: auto;
        font-size: rem-calc(20);
        line-height: rem-calc(24);
        padding-bottom: rem-calc(20);
      }
    }

    &__description {
      margin-bottom: rem-calc(0);

      @include breakpoint( medium down ) {
        min-height: auto;
      }
    }

    .teaser_image {
      min-height: rem-calc(180);
      background-size: cover;
      background-position: 50% center;

      @include breakpoint(medium down) {
        min-height: rem-calc(210);
      }
    }

    .published_date {
      color: $grey-400;
      font-size: rem-calc(16);
      margin-bottom: 0;
    }

    &__content_text {
      padding: rem-calc(24);
      background-color: $grey-50;
      border: rem-calc(1) solid $grey-100;

      @include breakpoint ( medium down ) {
        padding: rem-calc(20);
      }
    }

    &__content_description {
      // min-height: rem-calc(88);

      @include breakpoint( medium down ) {
        min-height: auto;
      }
    }
  }

  .listed_article_column {
    padding-left: rem-calc(15);
    margin-bottom: 0;

    &__title {
      margin: rem-calc(15 0);
      font-weight: bold;
      font-size: rem-calc(18);
      line-height: rem-calc(26);
      padding-right: rem-calc(10);

      @include breakpoint( medium down ) {
        padding-right: 0;
      }
    }

    &__subcategory {
      font-size: rem-calc(15);
      line-height: rem-calc(22);
      margin-bottom: rem-calc(20);
      margin-right: rem-calc(8);
    }

    &__container {
      padding: 0;
      border-bottom: rem-calc(1) solid $grey-100;
      margin-bottom: rem-calc(30);

      @include breakpoint (medium up) {
        max-height: rem-calc(150);
      }

      @include breakpoint (medium down) {
        height: auto;
      }
    }

    &__teaser_image {
      background-size: cover;
      background-position: 50%;
      margin-bottom: rem-calc(30);

      @include breakpoint ( large ) {
        min-height: rem-calc(111);
        min-width: rem-calc(144);
      }

      @include breakpoint ( medium down ) {
        height: rem-calc(240);
      }

      @include breakpoint ( medium large ) {
        max-height: rem-calc(130);
      }
    }
  }
}