$fail-contrast:         'This colour combination fails AA (4.5:1) contrast ratings at 18px fontsize';
$primary-red:           #b72f2a;
$primary-blue:          #006193;

$white:                 #ffffff;

// #GREY
$grey-25:               #f7f7f8;
$grey-50:               #eeeff0;
$grey-100:              #dcdee1;
$grey-200:              #b9bdc4;
$grey-300:              #979da6;
$grey-light:            #979da6;          // quaternary (light range)
$grey-400:              #747c89;
$grey-500:              #515b6b;
$grey:                  #515b6b;          // secondary (mid range)
$grey-600:              #414956;
$grey-700:              #313740;
$grey-dark:             #313740;          // tertiary (dark range)
$grey-800:              #20242b;
$grey-900:              #101215;

$grey-25-contrast:      $grey-800;
$grey-50-contrast:      $grey-800;    
$grey-100-contrast:     $grey-800;
$grey-200-contrast:     $grey-800;
$grey-300-contrast:     $grey-800;
$grey-light-contrast:   $grey-800;
$grey-400-contrast:     $fail-contrast;
$grey-500-contrast:     $white;
$grey-contrast:         $white;
$grey-600-contrast:     $white;
$grey-700-contrast:     $white;
$grey-700-contrast:     $white;
$grey-800-contrast:     $white;
$grey-900-contrast:     $white;


// #BLUE
$blue-25:               #ecf9fd;
$blue-50:               #c5ebf9;
$blue-100:              #83d4f2;
$blue-200:              #42bdea;
$blue-300:              #00a6e3;
$blue-light:            #00a6e3;     // quaternary (light range)
$blue-400:              #018bc8;
$blue-500:              #016fad;
$blue:                  #016fad;     // secondary (mid range)
$blue-600:              #016195;
$blue-700:              #00527c;
$blue-dark:             #00527c;     // tertiary (dark range)
$blue-800:              #004263;
$blue-900:              #00314a;

$blue-25-contrast:      $grey-800;
$blue-50-contrast:      $grey-800;
$blue-100-contrast:     $grey-800;
$blue-200-contrast:     $grey-800;
$blue-300-contrast:     $grey-800;
$blue-light-contrast:   $grey-800;
$blue-400-contrast:     $fail-contrast;
$blue-500-contrast:     $white;
$blue-contrast:         $white;
$blue-600-contrast:     $white;
$blue-700-contrast:     $white;
$blue-dark-contrast:     $white;
$blue-800-contrast:     $white;
$blue-900-contrast:     $white;

// #TEAL
$teal-25:               #dbf9f9;
$teal-50:               #b6f2f2;
$teal-100:              #85e9e9;
$teal-200:              #54e0e0;
$teal-300:              #0bd2d2;
$teal-light:            #0bd2d2;     // quaternary (light range)
$teal-400:              #11c5c5;
$teal-500:              #17b8b8;
$teal:                  #17b8b8;     // secondary (mid range)
$teal-600:              #0d9c9c;
$teal-700:              #028080;
$teal-dark:             #028080;     // tertiary (dark range)
$teal-800:              #026666;
$teal-900:              #014d4d;

$teal-25-contrast:      $grey-800;
$teal-50-contrast:      $grey-800;
$teal-100-contrast:     $grey-800;
$teal-200-contrast:     $grey-800;
$teal-300-contrast:     $grey-800;
$teal-light-contrast:   $grey-800;
$teal-400-contrast:     $grey-800;
$teal-500-contrast:     $grey-800;
$teal-contrast:         $grey-800;
$teal-600-contrast:     $grey-800;
$teal-700-contrast:     $white;
$teal-700-contrast:     $white;
$teal-800-contrast:     $white;
$teal-900-contrast:     $white;


// #GREEN
$green-25:              #f1f9e0;
$green-50:              #e3f3c1;
$green-100:             #d1ec98;
$green-200:             #bee46e;
$green-300:             #a2d830;
$green-light:           #a2d830;     // quaternary (light range)
$green-400:             #82c823;
$green-500:             #61b815;
$green:                 #61b815;     // secondary (mid range)
$green-600:             #4aa00f;
$green-700:             #338808;
$green-dark:            #338808;     // tertiary (dark range)
$green-800:             #296d06;
$green-900:             #1f5205;

$green-25-contrast:     $grey-800;
$green-50-contrast:     $grey-800;
$green-100-contrast:    $grey-800;
$green-200-contrast:    $grey-800;
$green-300-contrast:    $grey-800;
$green-light-contrast:  $grey-800;
$green-400-contrast:    $grey-800;
$green-500-contrast:    $grey-800;
$green-contrast:        $grey-800;
$green-600-contrast:    $grey-800;
$green-700-contrast:    $white;
$green-700-contrast:    $white;
$green-800-contrast:    $white;
$green-900-contrast:    $white;

// #YELLOW
$yellow-25:             #fffae3;
$yellow-50:             #fff4c7;
$yellow-100:            #ffeda2;
$yellow-200:            #ffe57c;
$yellow-300:            #ffda44;
$yellow-light:          #ffda44;     // quaternary (light range)
$yellow-400:            #ffcd22;
$yellow-500:            #ffc000;
$yellow:                #ffc000;     // secondary (mid range)
$yellow-600:            #edae00;
$yellow-700:            #d99f00;
$yellow-dark:           #d99f00;     // tertiary (dark range)
$yellow-800:            #c48c00;
$yellow-900:            #ae7400;

$yellow-25-contrast:    $grey-800;
$yellow-50-contrast:    $grey-800;
$yellow-100-contrast:   $grey-800;
$yellow-200-contrast:   $grey-800;
$yellow-300-contrast:   $grey-800;
$yellow-light-contrast: $grey-800;
$yellow-400-contrast:   $grey-800;
$yellow-500-contrast:   $grey-800;
$yellow-contrast:       $grey-800;
$yellow-600-contrast:   $grey-800;
$yellow-700-contrast:   $grey-800;
$yellow-700-contrast:   $grey-800;
$yellow-800-contrast:   $grey-800;
$yellow-900-contrast:   $fail-contrast;

// #ORANGE
$orange-25:             #fff2d9;
$orange-50:             #ffe5b3;
$orange-100:            #ffd480;
$orange-200:            #ffc24d;
$orange-300:            #ffa800;
$orange-light:          #ffa800;     // quaternary (light range)
$orange-400:            #ff9b19;
$orange-500:            #ff8d31;
$orange:                #ff8d31;     // secondary (mid range)
$orange-600:            #ea6a19;
$orange-700:            #d14905;
$orange-dark:           #d14905;     // tertiary (dark range)
$orange-800:            #aa3800;
$orange-900:            #7f2a00;

$orange-25-contrast:    $grey-800;
$orange-50-contrast:    $grey-800;
$orange-100-contrast:   $grey-800;
$orange-200-contrast:   $grey-800;
$orange-300-contrast:   $grey-800;
$orange-light-contrast: $grey-800;
$orange-400-contrast:   $grey-800;
$orange-500-contrast:   $grey-800;
$orange-contrast:       $grey-800;
$orange-600-contrast:   $grey-800;
$orange-700-contrast:   $white;
$orange-700-contrast:   $white;
$orange-800-contrast:   $white;
$orange-900-contrast:   $white;

// #PURPLE
$purple-25:             #f7e4f9;
$purple-50:             #eec9f2;
$purple-100:            #e3a5ea;
$purple-200:            #d881e2;
$purple-300:            #c74bd5;
$purple-light:          #c74bd5;     // quaternary (light range)
$purple-400:            #ae30bc;
$purple-500:            #9514a3;
$purple:                #9514a3;     // secondary (mid range)
$purple-600:            #841b8f;
$purple-700:            #72217b;
$purple-dark:           #72217b;     // tertiary (dark range)
$purple-800:            #5b1a62;
$purple-900:            #44144a;

$purple-25-contrast:    $grey-800;
$purple-50-contrast:    $grey-800;
$purple-100-contrast:   $grey-800;
$purple-200-contrast:   $grey-800;
$purple-300-contrast:   $fail-contrast;
$purple-light-contrast: $fail-contrast;
$purple-400-contrast:   $white;
$purple-500-contrast:   $white;
$purple-contrast:       $white;
$purple-600-contrast:   $white;
$purple-700-contrast:   $white;
$purple-700-contrast:   $white;
$purple-800-contrast:   $white;
$purple-900-contrast:   $white;

$error-red-500:         #e51e17;
$error-red:             #e51e17;
$error-red-25:          #fdefee;





// @function color($key) {
//   @if map-has-key($fid-colors, $key ) {
//     @return map-get($fid-colors, $key);
//   }

//   @warn "Unknown `#{$key}` in $fid-color-palette";
//   @return null;
// }

$color-defs: (
  'primary-red': ('main': $primary-red, 'contrast': $white),
  'primary-blue': ('main': $primary-blue, 'contrast': $white),

  'grey-25': ('main': $grey-25, 'contrast': $grey-25-contrast),
  'grey-50': ('main': $grey-50, 'contrast': $grey-50-contrast),
  'grey-100': ('main': $grey-100, 'contrast': $grey-100-contrast),
  'grey-200': ('main': $grey-200, 'contrast': $grey-200-contrast),
  'grey-300': ('main': $grey-300, 'contrast': $grey-300-contrast),
  'grey-400': ('main': $grey-400, 'contrast': $grey-400-contrast),
  'grey-500': ('main': $grey-500, 'contrast': $grey-500-contrast),
  'grey': ('main': $grey, 'contrast': $grey-contrast),
  'grey-600': ('main': $grey-600, 'contrast': $grey-600-contrast),
  'grey-700': ('main': $grey-700, 'contrast': $grey-700-contrast),
  'grey-800': ('main': $grey-800, 'contrast': $grey-800-contrast),
  'grey-900': ('main': $grey-900, 'contrast': $grey-900-contrast),
  
  'blue-25': ('main': $blue-25, 'contrast': $blue-25-contrast),
  'blue-50': ('main': $blue-50, 'contrast': $blue-50-contrast),
  'blue-100': ('main': $blue-100, 'contrast': $blue-100-contrast),
  'blue-200': ('main': $blue-200, 'contrast': $blue-200-contrast),
  'blue-300': ('main': $blue-300, 'contrast': $blue-300-contrast),
  'blue-400': ('main': $blue-400, 'contrast': $blue-400-contrast),
  'blue-500': ('main': $blue-500, 'contrast': $blue-500-contrast),
  'blue': ('main': $blue, 'contrast': $blue-contrast),
  'blue-600': ('main': $blue-600, 'contrast': $blue-600-contrast),
  'blue-700': ('main': $blue-700, 'contrast': $blue-700-contrast),
  'blue-800': ('main': $blue-800, 'contrast': $blue-800-contrast),
  'blue-900': ('main': $blue-900, 'contrast': $blue-900-contrast),
  
  'teal-25': ('main': $teal-25, 'contrast': $teal-25-contrast),
  'teal-50': ('main': $teal-50, 'contrast': $teal-50-contrast),
  'teal-100': ('main': $teal-100, 'contrast': $teal-100-contrast),
  'teal-200': ('main': $teal-200, 'contrast': $teal-200-contrast),
  'teal-300': ('main': $teal-300, 'contrast': $teal-300-contrast),
  'teal-400': ('main': $teal-400, 'contrast': $teal-400-contrast),
  'teal-500': ('main': $teal-500, 'contrast': $teal-500-contrast),
  'teal': ('main': $teal, 'contrast': $teal-contrast),
  'teal-600': ('main': $teal-600, 'contrast': $teal-600-contrast),
  'teal-700': ('main': $teal-700, 'contrast': $teal-700-contrast),
  'teal-800': ('main': $teal-800, 'contrast': $teal-800-contrast),
  'teal-900': ('main': $teal-900, 'contrast': $teal-900-contrast),

  'green-25': ('main': $green-25, 'contrast': $green-25-contrast),
  'green-50': ('main': $green-50, 'contrast': $green-50-contrast),
  'green-100': ('main': $green-100, 'contrast': $green-100-contrast),
  'green-200': ('main': $green-200, 'contrast': $green-200-contrast),
  'green-300': ('main': $green-300, 'contrast': $green-300-contrast),
  'green-400': ('main': $green-400, 'contrast': $green-400-contrast),
  'green-500': ('main': $green-500, 'contrast': $green-500-contrast),
  'green': ('main': $green, 'contrast': $green-contrast),
  'green-600': ('main': $green-600, 'contrast': $green-600-contrast),
  'green-700': ('main': $green-700, 'contrast': $green-700-contrast),
  'green-800': ('main': $green-800, 'contrast': $green-800-contrast),
  'green-900': ('main': $green-900, 'contrast': $green-900-contrast),

  'yellow-25': ('main': $yellow-25, 'contrast': $yellow-25-contrast),
  'yellow-50': ('main': $yellow-50, 'contrast': $yellow-50-contrast),
  'yellow-100': ('main': $yellow-100, 'contrast': $yellow-100-contrast),
  'yellow-200': ('main': $yellow-200, 'contrast': $yellow-200-contrast),
  'yellow-300': ('main': $yellow-300, 'contrast': $yellow-300-contrast),
  'yellow-400': ('main': $yellow-400, 'contrast': $yellow-400-contrast),
  'yellow-500': ('main': $yellow-500, 'contrast': $yellow-500-contrast),
  'yellow': ('main': $yellow, 'contrast': $yellow-contrast),
  'yellow-600': ('main': $yellow-600, 'contrast': $yellow-600-contrast),
  'yellow-700': ('main': $yellow-700, 'contrast': $yellow-700-contrast),
  'yellow-800': ('main': $yellow-800, 'contrast': $yellow-800-contrast),
  'yellow-900': ('main': $yellow-900, 'contrast': $yellow-900-contrast),

  'orange-25': ('main': $orange-25, 'contrast': $orange-25-contrast),
  'orange-50': ('main': $orange-50, 'contrast': $orange-50-contrast),
  'orange-100': ('main': $orange-100, 'contrast': $orange-100-contrast),
  'orange-200': ('main': $orange-200, 'contrast': $orange-200-contrast),
  'orange-300': ('main': $orange-300, 'contrast': $orange-300-contrast),
  'orange-400': ('main': $orange-400, 'contrast': $orange-400-contrast),
  'orange-500': ('main': $orange-500, 'contrast': $orange-500-contrast),
  'orange': ('main': $orange, 'contrast': $orange-contrast),
  'orange-600': ('main': $orange-600, 'contrast': $orange-600-contrast),
  'orange-700': ('main': $orange-700, 'contrast': $orange-700-contrast),
  'orange-800': ('main': $orange-800, 'contrast': $orange-800-contrast),
  'orange-900': ('main': $orange-900, 'contrast': $orange-900-contrast),

  'purple-25': ('main': $purple-25, 'contrast': $purple-25-contrast),
  'purple-50': ('main': $purple-50, 'contrast': $purple-50-contrast),
  'purple-100': ('main': $purple-100, 'contrast': $purple-100-contrast),
  'purple-200': ('main': $purple-200, 'contrast': $purple-200-contrast),
  'purple-300': ('main': $purple-300, 'contrast': $purple-300-contrast),
  'purple-400': ('main': $purple-400, 'contrast': $purple-400-contrast),
  'purple-500': ('main': $purple-500, 'contrast': $purple-500-contrast),
  'purple': ('main': $purple, 'contrast': $purple-contrast),
  'purple-600': ('main': $purple-600, 'contrast': $purple-600-contrast),
  'purple-700': ('main': $purple-700, 'contrast': $purple-700-contrast),
  'purple-800': ('main': $purple-800, 'contrast': $purple-800-contrast),
  'purple-900': ('main': $purple-900, 'contrast': $purple-900-contrast),

  'error-red-500': ('main': $error-red-500, 'contrast': $white),
  'error-red': ('main': $error-red, 'contrast': $white),
  'error-red-25': ('main': $error-red-25, 'contrast': $grey-800),
  'white': ('main': $white, 'contrast': $grey-800)
);