.fil-article-hero-view {
  padding: rem-calc($component-padding-base 0);

  .fil-article-hero-view__bg-image {
    background-position: center 20%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: rem-calc(250);
    transition: all 0.5s ease;
    width: 100%;

    @include breakpoint(medium) {
      height: rem-calc(330);
    }
  }

  .fil-article-hero-view__title {
    margin-top: rem-calc(-48);

    h1 {
      color: $white;
      font-weight: bold;
      margin: rem-calc(0 0 40);
    }
  }

  .fil-article-hero-view__title,
  .fil-article-hero-view__summary {
    padding: rem-calc(40);
  }
}
