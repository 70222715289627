.fil-article-image {
  margin-top: rem-calc(40);
  margin-bottom: rem-calc(40);

  &__img-container {
    padding: rem-calc(0);
    display: block;
    margin: 0 auto;
    text-align: center;

    @include breakpoint(large) {
      max-width: 90%;
    }

    @include breakpoint(xlarge) {
      max-width: 60%;
    }
  }

  &__source-container {
    padding-top: rem-calc(15);
    margin-bottom: 0;
  }

  &__source {
    color: $grey-400;
    display: inline;
  }

  &__author {
    color: $grey-600;
    display: inline;
  }

  @include breakpoint(medium only) {
    margin-top: rem-calc(0);
  }

  @include breakpoint(small only) {
    margin: rem-calc(80 0 24);

    &__source-container {
      padding-top: rem-calc(8);
    }
  }
}

.fil-article-header-margin {
  @include breakpoint(medium down) {
    margin-top: rem-calc(80);
  }
}