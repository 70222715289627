.fil-feature {
  padding: rem-calc($component-padding-base 0);
  transition: all 0.5s ease;

  &--outlook {
    padding: 0;
    margin-bottom: rem-calc(40);

    @include breakpoint(medium down) {
      margin-bottom: rem-calc(32);
    }
  }

  .fil-feature__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: rem-calc(48);
    padding-right: 0;
    padding-bottom: rem-calc(48);
    padding-left: 0;
    min-height: rem-calc(420);
    z-index: 3;
    transition: all 0.5s ease;

    &.extended {
      min-height: rem-calc(450);
    }

    .button-wrapper {
      justify-self: end;
    }

    h3 {
      font-weight: bold;
    }

    p {
      margin-bottom: rem-calc(48);
      flex-grow: 1;
    }

    .fil-feature__text-link {
      display: inline-block;
      margin-bottom: rem-calc(24);

      &:hover {
        color: $white;
        opacity: 0.7;
      }

      &:focus {
        color: $white;
      }
    }

    .button {
      margin-bottom: 0;
    }

    &::after {
      display: block;
      content: ' ';
      background-color: inherit;
      position: absolute;
      z-index: -1;
      width: rem-calc(10000);
      right: 0;
      top: 0;
      height: 100%;
      transform: skew(-11.6deg);
      transform-origin: bottom right;
    }
  }

  .fil-feature__content--outlook {
    color: $white;
    text-align: left;
    background-color: $blue-800;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: rem-calc(32);
    z-index: 3;
    transition: all 0.5s ease;

    &.extended {
      min-height: rem-calc(450);
    }

    .button-wrapper {
      justify-self: end;
    }

    h4 {
      font-weight: bold;
    }

    p {
      font-weight: normal;
      margin-bottom: rem-calc(32);
      flex-grow: 1;
    }

    .fil-feature__text-link {
      display: inline-block;

      &:hover {
        color: $white;
        opacity: 0.7;
      }

      .arrow-right-cta {
        font-size: rem-calc(16);
      }
    }

    .button {
      margin-bottom: 0;
      font-size: rem-calc(16);
    }

    &::after {
      display: block;
      content: ' ';
      background-color: inherit;
      position: absolute;
      z-index: -1;
      width: 100%;
      right: 0;
      top: 0;
      height: 100%;
      transform: skew(-11.6deg);
      transform-origin: bottom right;
    }
  }

  .fil-feature__bgImage {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: $grey-400;
  }
}

.fil-feature-list {
  &:nth-child(odd) {
    padding-right: rem-calc(0);
  }

  &:nth-child(even) {
    padding-left: rem-calc(0);
  }

  .fil-feature__content {
    min-height: rem-calc(200) !important;
    padding: rem-calc(32 0 32 32);

    p {
      min-height: rem-calc(85);
      margin-bottom: rem-calc(40);
    }

    h3 {
      margin-bottom: rem-calc(24);
      min-height: rem-calc(75);
    }

    .button-wrapper:last-child a {
      margin-bottom: 0;
    }

    .grid-x:nth-last-child(2) {
      margin-bottom: rem-calc(24);
    }

    &::after {
      width: 100%;
    }
  }

  @include breakpoint(medium) {
    .large-offset-1 {
      margin-left: rem-calc(17);
    }

    &:nth-child(odd) {
      padding-right: rem-calc(15);
    }

    &:nth-child(even) {
      padding-left: rem-calc(15);
    }
  }
}

@include breakpoint(medium down) {
  .fil-feature {
    .fil-feature__content {
      min-height: 0;
      height: auto;
      padding: rem-calc(40 24 24 24);

      &.extended {
        min-height: 0;
        height: auto;
      }

      h3 {
        margin-bottom: rem-calc(24);
      }

      p {
        margin-bottom: rem-calc(24);
      }
    }

    .fil-feature__content--outlook {
      min-height: 0;
      height: auto;
      padding: rem-calc(24);

      &.extended {
        min-height: 0;
        height: auto;
      }

      h4 {
        margin-bottom: rem-calc(16);
        font-weight: bold;
      }

      p {
        margin-bottom: rem-calc(8);
        font-size: rem-calc(18);
      }
    }
  }
}

@include breakpoint(small only) {
  .fil-feature {
    text-align: center;

    .fil-feature__content--outlook {
      &::before,
      &::after {
        display: none;
      }

      p {
        margin-bottom: rem-calc(16);
        font-size: rem-calc(16);
      }

      h4 {
        font-weight: bold;
        font-size: rem-calc(23);
        margin-bottom: rem-calc(16);
      }

      .intro {
        font-size: rem-calc(16);
        font-weight: 300;
        margin-bottom: rem-calc(16);
      }

      .fil-feature__text-link {
        font-weight: 300;
        font-size: rem-calc(16);

        .arrow-right-cta {
          font-size: rem-calc(16);
        }
      }
    }

    .fil-feature__content {
      &::before,
      &::after {
        display: none;
      }

      p {
        margin-bottom: rem-calc(32);
      }

      h3 {
        font-size: rem-calc(24);
        margin-bottom: rem-calc(18);
      }

      .intro {
        font-size: rem-calc(16);
        font-weight: 300;
        margin-bottom: rem-calc(24);
      }

      .fil-feature__text-link {
        font-weight: 300;
      }
    }
  }

  .fil-feature-list {
    .fil-feature__content {
      margin-bottom: rem-calc(20);
      padding: rem-calc(40 24 44.8 24);

      p {
        min-height: rem-calc(85);
        margin-bottom: rem-calc(16);
      }

      h3 {
        margin-bottom: rem-calc(16);
      }

      .button-wrapper a:last-child {
        margin-bottom: 0;
      }
    }

    .fil-feature__content--outlook {
      margin-bottom: rem-calc(20);
      padding: rem-calc(40 24 44.8 24);

      p {
        min-height: rem-calc(85);
        margin-bottom: rem-calc(16);
      }

      h3 {
        margin-bottom: rem-calc(16);
      }

      .button-wrapper a:last-child {
        margin-bottom: 0;
      }
    }

    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .fil-feature__bgImage {
    padding-bottom: 66.6666666%;
  }
}
