$teaser-header-section-mobile: rem-calc(200);
$teaser-header-section-tablet: rem-calc(335);
$teaser-header-section-desktop: rem-calc(180);

.editorial {
  .break-point-margin {
    @include breakpoint(medium down) {
      margin-top: rem-calc(64);
    }
  }

  &.fil-article-grid {
    justify-content: center;
    padding-bottom: rem-calc(28);

    .articles {
      .fil-article-grid__tag {
        font-weight: bold;
        color: $blue;
        padding: rem-calc(16 0 8 0);
        margin-bottom: rem-calc(8);
        text-align: left;

        h4 {
          color: $grey-800;
        }
      }

      .cell {
        margin: rem-calc(0 4 16 4);
      }

      .card {
        height: 100%;
        margin-bottom: 0;

        .avatar {
          border-radius: 0;
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card {
      padding: 0;
      background: $grey-25;

      header,
      section,
      footer {
        position: relative;
      }

      header,
      footer {
        padding: rem-calc(0 24);
      }
    }

    header {
      min-height: rem-calc(16);

      h4 {
        margin-bottom: rem-calc(8);
      }

      .cell {
        height: 100%;
      }

      .article__bg-image {
        left: 0;
        width: 100%;
        height: rem-calc(50);
        overflow: hidden;

        @include breakpoint(medium) {
          height: $teaser-header-section-tablet;
        }

        @include breakpoint(large) {
          height: $teaser-header-section-desktop;
        }
      }

      .category {
        font-weight: bold;
        padding: rem-calc(4);
        position: relative;

        @include breakpoint(large) {
          padding: rem-calc(4 24);
        }

        .fil-icon {
          font-size: rem-calc(16);
          font-weight: normal;
          margin-right: rem-calc(4);
        }
      }

      .reading-time {
        margin-left: rem-calc(18);
      }

      a,
      .reading-time {
        @include breakpoint(medium only) {
          display: block;
          margin: 0;
        }
      }
    }

    section {
      padding: rem-calc(0 24);

      p {
        margin: 0;
      }
    }

    footer {
      margin-bottom: rem-calc(20);

      .article-header__author-title {
        display: block;
      }
    }

    @include breakpoint(medium up) {
      padding-top: 0;
    }
  }
}

aside {
  .fil-article-grid {
    article.card {
      padding: rem-calc(24);
      background: $grey-25;

      header,
      section,
      footer {
        padding: 0;
      }

      footer {
        margin: 0;
      }

      h4 {
        margin-bottom: rem-calc(16);
      }

      .category {
        display: inline-block;
        margin: 0;
        padding: 0;
      }

      .article__teaser-image {
        margin: rem-calc(14 0);
      }

      .card-section {
        padding: 0;
      }
    }
  }
}
