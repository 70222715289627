.fil-investment-team-view-card {
  &__container {
    margin-bottom: rem-calc(24);

    // create space for links
    padding-bottom: rem-calc(32);

    &--0-links {
      padding-bottom: 0;
    }

    @include breakpoint(xlarge) {
      margin-bottom: rem-calc(32);
      padding-bottom: 0;
      // create space for images
      &--top-left {
        padding-top: rem-calc(48);
      }

      &--top-right {
        padding-top: rem-calc(48);
        padding-right: rem-calc(160);
      }
    }
  }

  &__card {
    @include card-container($white, $grey-600, 0, solid rem-calc(1) $grey-100, 0, 0);

    position: relative;  // positioning context for the images
    border-radius: rem-calc(5);
    overflow: visible;
    padding: rem-calc(24 32 32);

    @include breakpoint(small only) {
      padding: rem-calc(20);
    }

    p {
      margin-bottom: rem-calc(16);
    }

    h3 {
      font-weight: bold;
      margin-bottom: rem-calc(24);
      color: $grey-900;
      font-size: rem-calc(28);

      a {
        font-weight: inherit;
      }

      &:first-of-type {
        margin-bottom: 0;
      }
    }

    .fil-house-view-table {
      margin: 0 auto;
      margin-bottom: rem-calc(32);

      @include breakpoint(medium down) {
        overflow-x: auto;
      }

      table {
        margin-bottom: 0;
        min-width: rem-calc(440);
      }

      tr {
        padding: rem-calc(24);
        text-align: left;
      }

      td {
        text-align: left;
        min-width: 33%;
        padding-left: 0;

        span {
          margin: rem-calc(0 0 0 10);
        }
      }

      th {
        white-space: nowrap;
        min-width: 33%;
        padding-left: 0;
      }

      td:first-of-type,
      th:first-of-type {
        padding-left: rem-calc(32);
      }

      img {
        max-width: unset;
        margin: rem-calc(0 10 0 0)
      }

      &__view-column {
        padding-right: rem-calc(2);

        @include breakpoint(large) {
          padding-left: rem-calc(8);
        }
      }
    }

    &--has-pdf-download {
      h3 {
        // Allow space for PDF download link.
        // Link itself is ~120px, then add left margin.
        margin-right: rem-calc(120 + 24);

        @include breakpoint(xlarge) {
          margin-right: rem-calc(120 + 40);
        }

        @include breakpoint(small only) {
          margin-right: 0;
        }
      }
    }
  }

  &__dateline,
  &__read-more-link-container {
    margin-bottom: rem-calc(16);
  }

  &__title-container {
    flex-grow: 1;
    margin-bottom: rem-calc(16);
  }

  &__title,
  &__pdf-download-link-container {
    margin-bottom: 0;
  }

  &__intro {
    margin-bottom: rem-calc(24);
  }

  &__title,
  &__intro {
    font-weight: bold;
    color: $grey-900;
  }

  &__title-link {
    font-weight: inherit;
  }

  &--has-pdf-download {
    &__title,
    &__intro {
      // Allow space for PDF download link.
      // Link itself is ~120px, then add left margin.
      margin-right: rem-calc(120 + 24);

      @include breakpoint(xlarge) {
        margin-right: rem-calc(120 + 40);
      }

      @include breakpoint(small only) {
        margin-right: 0;
      }
    }
  }

  &__dateline {
    color: $grey-500;
    font-size: rem-calc(15);
  }

  &__summary {
    margin-bottom: rem-calc(24);

    p {
      margin-bottom: 0;
    }
  }

  &__read-more-link-arrow {
    vertical-align: middle;
  }

  &__arrow {
    position: relative;
    top: rem-calc(2);
  }

  &__link-list {
    @include card-container($blue-25, $grey-800, 0, solid rem-calc(1) $blue-50, 0, 0);

    border-radius: rem-calc(5);
    margin: rem-calc(0 0 -60 4);
    padding: rem-calc(24 32);

    @include breakpoint(small only) {
      margin-left: 0;
    }

    p {
      font-weight: bold;
    }
  }

  &__link-list-header {
    font-weight: bold;
    margin-bottom: rem-calc(16);
  }

  &__link-card > a {
    color: $blue-500;

    &:hover {
      color: $grey-900;
    }
  }

  &__link-card::before {
    // Removing bullets from list
    display: none;
  }

  &__link-card-link {
    color: $blue-500;

    &:hover {
      color: $grey-900;
    }
  }

  &__link-card-arrow {
    font-weight: normal;
    color: $blue-500;
    vertical-align: middle;
  }

  &__image {
    position: absolute;
    z-index: -1;
    top: rem-calc(-48);
    left: rem-calc(-165);
    height: 100%;

    &--top-right {
      left: auto;
      right: rem-calc(-160);
    }
  }

  &__pdf-link {
    position: absolute;
    top: rem-calc(24);
    right: rem-calc(24);

    @include breakpoint(xlarge) {
      top: rem-calc(32);
      right: rem-calc(40);
    }

    @include breakpoint(small only) {
      display: none;
    }
  }

  &__read-more-button {
    padding: rem-calc(16 40);

    @include breakpoint(small only) {
      margin-bottom: rem-calc(32);
    }

    &--custom {
      // Reduce side padding so it's possible to fit
      // a few more characters
      padding: rem-calc(16);
    }
  }

  &__read-more-button-link {
    color: $white;
  }
}

.fil-outlook-table__tooltip {
  padding: rem-calc(8 24 24);

  @include breakpoint(small only) {
    padding: rem-calc(24);
  }

  &--description {
    clear: left;
    padding-top: rem-calc(8);
  }

  &--line {
    clear: left;
    border-bottom: rem-calc(1) solid $grey-400;
    padding-top: rem-calc(8);
  }

  &--mobile,
  &--desktop {
    img {
      margin-left: 0;
    }

    h3:first-of-type {
      margin-bottom: rem-calc(32);
    }

    h6 {
      border-top: none;
      padding: 0;
    }
  }
}
