.fil-outlook-hero-homepage {
  margin: 0 0 rem-calc($outlook-margin-medium);
  position: relative;
  z-index: -3;
  max-width: none;
  padding-left: 0;
  padding-right: 0;

  &__container {
    margin-left: auto;
    margin-right: 0;
  }

  &__heading {
    position: relative;
    padding: calc(#{rem-calc(32)} + 2.375%) 0 calc(#{rem-calc(32)} + 2.375%) rem-calc(45);

    &::after {
      display: block;
      content: ' ';
      background-color: $blue-600;
      position: absolute;
      z-index: 0;
      width: 100%;
      right: 0;
      top: 0;
      height: 100%;
      transform: skew(-15deg);
      transform-origin: bottom right;
    }
  }

  &__heading-text {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    font-weight: bold;
    padding-bottom: rem-calc(24);
  }

  &__baton {
    width: rem-calc(80);
    height: rem-calc(4);
    background-color: $blue-200;
    display: inline-block;
    z-index: 1;
    position: relative;
  }

  &__bg-image {
    background-repeat: no-repeat;
    background-position: 75%;
    background-size: cover;
    z-index: -2;
    min-height: rem-calc(164);
  }

  @include breakpoint(xlarge) {
    &__heading {
      // ensures heading text is aligned with body text at larger screen widths
      padding-left: calc((100% - #{map-get($breakpoints, xlarge)}) / 2 + #{rem-calc(45)});
    }
  }

  @include breakpoint(small only) {
    // uses the full class to gain more specificity and override the grid-x style which sets flex-wrap
    .fil-outlook-hero-homepage__container {
      flex-wrap: wrap-reverse;
    }

    &__heading {
      text-align: center;
      padding: rem-calc(32) 4.17%;

      &::after {
        display: none;
      }
    }

    &__heading-text {
      font-size: rem-calc(33);
      padding-bottom: rem-calc(12);
    }

    &__bg-image {
      height: 0;
      padding-bottom: 50%;
    }
  }

  @include breakpoint(medium down) {
    margin-bottom: rem-calc($outlook-margin-small);
  }
}
