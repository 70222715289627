
// #CHECKBOX
.checkbox {
  margin-bottom: 8px;
  &.checkbox--med,
  &.checkbox--large {
    display: inline-block;
    float: left;
  }
}

.checkbox__label.checkbox__label--button {
  position: relative;

  width: 28px;
  height: 28px;
  margin-right: 12px;
  margin-left: 0;

  border: 1px solid $grey-300;
  background-color:$white;

  &.is-invalid-input:focus,
  &.is-invalid-input:not(:focus),
  &.is-invalid-input {
    border: 2px solid $error-red-500;
    background-color:$white;
  }

  .checkbox--large & {
    position: absolute;
    bottom: 20px;
    left: 50%;

    margin: 0 auto;

    transform: translate(-50%, 0);

    border-color: $teal-500;

    &.is-invalid-input:focus,
    &.is-invalid-input:not(:focus),
    &.is-invalid-input {
      border-color: $error-red-500;
    }
  }
}

// Actual visible checkbox
.checkbox__input.checkbox__input {
  position: absolute;

  visibility: hidden;

  width: 0;
  height: 0;
  &:checked + .checkbox__label--button,
  &:checked + .checkbox__container > .checkbox__label--button {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;

      content: icon(tick-bold);
      transform: translate(-50%, -50%);

      color: $blue-500;

      font-family: 'fidelity-icon-font';
      font-size: 12px;
    }
  }
  .checkbox--large & {
    // Focus styles
    + .checkbox__container .checkbox__label--button:before {
      color: $teal-500;
    }
    &:focus + .checkbox__container {
      background-color: $teal-25;
    }
    &:checked + .checkbox__container {
      color: $teal-700-contrast;
      border-color: $teal-700;
      background-color: $teal-700;
    }
    &:checked + .checkbox__container > .checkbox__label {
      border-color:$white;
    }
  }
}
