$icons-def: (
    chevron-d-bold: "\e997",
    chevron-d-light: "\e998",
    chevron-d-regular: "\e999",
    chevron-l-bold: "\e800",
    chevron-l-light: "\e801",
    chevron-l-regular: "\e802",
    chevron-r-bold: "\e803",
    chevron-r-light: "\e804",
    chevron-r-regular: "\e805",
    chevron-u-bold: "\e806",
    chevron-u-light: "\e807",
    chevron-u-regular: "\e808",
    cross-bold: "\e809",
    cross-light: "\e810",
    cross-regular: "\e811",
    plus-bold: "\e812",
    plus-light: "\e813",
    plus-regular: "\e94f",
    pdf-small: "\e814",
    external-link: "\e815",
    tick-bold: "\e827",
    tick-light: "\e829",
    tick-regular: "\e828",
    triangle-u: "\e852",
    triangle-d: "\e9853",
    triangle-r: "\e854",
    triangle-l: "\e855",
    cog-14: "\e832",
    globe-14: "\e833",
    lock-14: "\e834",
    calendar-14: "\e835",
    coinstack-14: "\e836",
    medal-14: "\e837",
    calendar-plus-14: "\e838",
    person-filled-14: "\e839",
    search-14: "\e840",
    mappin-filled-14: "\e841",
    info-circle-filled-14: "\e842",
    info-circle-14: "\e843",
    warning-triangle-filled-14: "\e844",
    warning-triangle-14: "\e845",
    clock-14: "\e846",
    computer-14: "\e847",
    exit-left-14: "\e848",
    person-circle-filled-14: "\e849",
    download-14: "\e850",
    squares-overlap-14: "\e851",
    circle-arrow-back-14: "\e923",
    phone-14: "\e93e",
    arrow-d-light: "\e94d",
    arrow-l-light: "\e94e",
    arrow-r-light: "\e95a",
    arrow-u-light: "\e95b",
    warning-square: "\e95c",
    card-cvc: "\e903",
    clipboard-tick: "\e900",
    verified: "\e900",
    newspaper: "\e925",
    factsheet-full: "\e925",
    letter-open: "\e905",
    post: "\e905",
    spyglass: "\e906",
    search: "\e906",
    person: "\e909",
    account: "\e909",
    check-mail: "\e90a",
    help-circle: "\e90b",
    help: "\e90b",
    person-lock: "\e90c",
    lock-account: "\e90c",
    letter: "\e90d",
    messages: "\e90d",
    smartphone: "\e90e",
    mobile: "\e90e",
    eye: "\e90f",
    watchlist: "\e90f",
    circle-tick: "\e912",
    confirm: "\e912",
    info-circle: "\e914",
    info: "\e914",
    star: "\e908",
    star-line: "\e908",
    star-filled: "\e907",
    star-fill: "\e907",
    ellipsis: "\e915",
    menu: "\e915",
    warning-triangle-filled: "\e916",
    warning: "\e916",
    warning-circle-filled: "\e913",
    error: "\e913",
    chart-plus: "\e917",
    compare: "\e917",
    circle-minus: "\e918",
    contract: "\e918",
    circle-plus: "\e919",
    expand: "\e919",
    download: "\e91a",
    export: "\e91a",
    spyglass-chart: "\e91b",
    factsheet: "\e91b",
    printer: "\e91c",
    print: "\e91c",
    refresh: "\e91d",
    cog: "\e91e",
    settings: "\e91e",
    person-plus: "\e91f",
    account-add: "\e91f",
    coins-sterling: "\e920",
    buy: "\e920",
    arrow-down: "\e901",
    arrow-up: "\e902",
    add: "\e910",
    close: "\e911",
    chart: "\e921",
    market: "\e922",
    hand-coins: "\e924",
    sell: "\e924",
    globe: "\e926",
    booklet-chart: "\e927",
    report: "\e927",
    computer: "\e928",
    tick: "\e929",
    hand: "\e930",
    movie: "\e931",
    down-sort: "\e932",
    up-sort: "\e933",
    chevron-down-s: "\e934",
    chevron-up-s: "\e935",
    chevron-down-m: "\e936",
    chevron-up-m: "\e937",
    menu-burger: "\e938",
    no-entry: "\e939",
    square: "\e940",
    line: "\e941",
    droplet: "\e942",
    droplet-line: "\e942",
    droplet-filled: "\e943",
    droplet-fill: "\e943",
    droplet-alt: "\e944",
    droplet-alt-line: "\e944",
    droplet-alt-filled: "\e945",
    droplet-alt-fill: "\e945",
    circle-right: "\e946",
    circle-wave: "\e947",
    circle-zig: "\e948",
    piggybank: "\e949",
    saving: "\e949",
    home: "\e950",
    quantitative: "\e951",
    hand-point: "\e952",
    exit-right: "\e953",
    exit: "\e953",
    calendar: "\e954",
    clock: "\e955",
    person-help: "\e956",
    other-account: "\e956",
    lock-tick: "\e957",
    leaf: "\e958",
    computer-tick: "\e959",
    smartphone-chatbubble: "\e960",
    sms-alert: "\e960",
    coinstack-cog: "\e961",
    edit-income-settings: "\e961",
    chevron-left-m: "\e962",
    chevron-right-m: "\e963",
    pencil: "\e964",
    tag: "\e965",
    medal: "\e966",
    awards: "\e966",
    bookmark-star: "\e967",
    favourites: "\e967",
    smartphone-flip: "\e968",
    mobile-flip: "\e968",
    back: "\e969",
    forward: "\e970",
    pause: "\e971",
    stop: "\e972",
    play: "\e973",
    map-pin: "\e974",
    doc-spyglass: "\e975",
    view-docs: "\e975",
    doc-pdf: "\e976",
    pdf: "\e976",
    hand-docs: "\e977",
    compare-file: "\e977",
    doc-stacked: "\e978",
    group: "\e978",
    pencil-lock: "\e979",
    lock-edit: "\e979",
    paperclip: "\e980",
    attach: "\e980",
    coinstack: "\e981",
    currency: "\e981",
    controlpanel: "\e982",
    expand-filters: "\e982",
    browser-plus: "\e983",
    new-window: "\e983",
    chevron-right-s: "\e95f",
    chevron-left-s: "\e985",
    circle-filled: "\e986",
    circle-fill: "\e986",
    circle-dashed: "\e987",
    circle-empty: "\e987",
    bookmark-star-filled: "\e92a",
    favourites-filled: "\e92a",
    doc-csv: "\e989",
    csv: "\e989",
    logo-facebook: "\e990",
    logo-twitter: "\e991",
    logo-xing: "\e992",
    logo-googleplus: "\e993",
    logo-linkedin: "\e994",
    logo-youtube: "\e995",
    messages-filled: "\e996",
    openbook: "\e818",
    doc-chevron-r: "\e819",
    doc-arrow-d: "\e820",
    share: "\e821",
    share-filled: "\e822",
    person-double: "\e823",
    chatbubble: "\e824",
    chatbubble-letter: "\e825",
    chatbubble-letter-filled: "\e826",
    calendar-circle-plus: "\e830",
    mappin-filled: "\e831",
    coins-plus: "\e904",
    coins-minus: "\e92b",
    arrows-left-right: "\e92c",
    abc-blocks: "\e92d",
    beach-umbrella: "\e92e",
    piggybank-alt: "\e92f",
    three-people: "\e93a",
    circle-arrow-back: "\e93b",
    circle-minus-filled: "\e93c",
    circle-plus-filled: "\e93d",
    receipt: "\e93f",
    receipt-dollar: "\e94a",
    person-pencil: "\e94b",
    Icon_Transfer_24x24_test: "\e94c",
);