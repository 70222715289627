@import 'fil-house-view-table';
@import 'fil-multi-asset-table';


.fil-outlook-table {
  margin-bottom: rem-calc(40);

  @include breakpoint(medium down) {
    overflow-x: auto;
  }

  table {
    margin-bottom: 0;
  }

  th {
    text-align: left;
    white-space: nowrap;
  }

  img {
    max-width: unset;
  }

  &__tooltip {
    white-space: normal;
    z-index: 1;
    display: none;
    padding: rem-calc(24);

    &-row {
      display: flex;
      margin-bottom: rem-calc(8);
    }

    h6 {
      font-size: rem-calc(16);
      font-weight: bold;
      margin: rem-calc(16 0);
      padding-top: rem-calc(16);
    }

    dt {
      margin-bottom: 0;
      padding-right: rem-calc(16);
    }

    &--desktop {
      position: absolute;
      width: rem-calc(256);
      left: rem-calc(-128);
      top: rem-calc(32);
      background: $grey-800;
      color: $white;
      font-size: rem-calc(16);
      border-radius: rem-calc(4);

      h6 {
        border-top: rem-calc(1) solid $grey;
      }
    }

    &--mobile {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      color: $grey-600;
      background-color: $white;
      z-index: 92;  // z-index of menu + 1

      h3 {
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(32);
        text-align: center;
        color: $grey;

        &::after {
          content: ' ';
          display: block;
          width: rem-calc(40);
          height: rem-calc(4);
          background-color: $blue;
          position: relative;
          left: calc(50% - #{rem-calc(20)});
          margin-top: rem-calc(24);
        }
      }
    }
  }

  &__tooltip-triangle {
    position: absolute;
    top: rem-calc(-16);
    left: rem-calc(128);
    // CSS triangle: https://dominikpiatek.com/how-do-css-triangles-work/
    width: 0;
    height: 0;
    border: rem-calc(8) solid transparent;
    border-bottom: rem-calc(8) solid $grey-800;
  }

  &__tooltip-content {
    max-width: rem-calc(304);
    margin: auto;
  }

  &__close-icon {
    color: $blue;
    position: absolute;
    top: rem-calc(24);
    right: rem-calc(24);
    font-size: rem-calc(32);
    font-weight: bold;
  }

  &__close-button {
    width: 100%;
    background-color: $green-400;
    color: $grey-800;
    padding: rem-calc(16);
    margin-top: rem-calc(32);
  }

  &__change-icon {
    display: inline-block;
    width: rem-calc(8);
    margin-right: rem-calc(8);
  }

  &__info-icon {
    color: $grey-100;
    font-weight: normal;
    position: relative;
    top: rem-calc(2);

    &:hover {
      @include breakpoint(large) {
        .fil-outlook-table__tooltip--desktop {
          display: block;
        }
      }
    }

    &--clicked {
      @include breakpoint(medium down) {
        .fil-outlook-table__tooltip--mobile {
          display: block;
        }
      }
    }
  }

  &__view-icon {
    width: rem-calc(54);
  }

  &__upgraded-arrow {
    width: 0;
    height: 0;
    border-width: rem-calc(0 4 6 4);
    border-style: solid;
    position: relative;
    top: rem-calc(8);

    &--dark {
      border-color: $white transparent;
    }

    &--light {
      border-color: $grey-700 transparent;
    }

    &--in-table {
      top: rem-calc(-2);
    }
  }

  &__downgraded-arrow {
    width: 0;
    height: 0;
    border-width: rem-calc(6 4 0 4);
    border-style: solid;
    position: relative;
    top: rem-calc(8);

    &--dark {
      border-color: $white transparent;
    }

    &--light {
      border-color: $grey-700 transparent;
    }

    &--in-table {
      top: rem-calc(-2);
    }
  }
}