.fil-article-attribution {

  &-author {
    padding-right: rem-calc(24);
    color: $grey-600;
    flex-wrap: nowrap;
    align-items: center;

    &__wrapper {
      align-items: center;
      flex-wrap: nowrap;
    }

    &__author-image-wrapper {
      flex-shrink: 0;
    }

    @include author_avatar(60);

    &__author-image {
      height: rem-calc(60);
      width: rem-calc(60);
      border-radius: 50%;
      margin-right: rem-calc(16);

      @include breakpoint(medium) {
        margin-right: rem-calc(20);
      }
    }

    &__text-wrapper {
      padding: rem-calc(7) 0;
      word-break: break-word;
    }

    &__name {
      font-weight: bold;
      color: $grey-500;
    }

    &__title {
      color: $grey-600;
    }

    &__name,
    &__title {
      margin-bottom: 0;
      // provides natural spacing between lines
      line-height: 1.73;
    }

    &__dropdown-toggle {
      display: inline-block;
    }
  }

  &-horizontal {
    // only displayed on desktop - up to 3 authors side-by-side
    margin-bottom: rem-calc(32);
    flex-wrap: nowrap;
    align-items: center;

    .fil-article-attribution-author {
      border-left: rem-calc(1) solid $grey-100;
      padding-left: rem-calc(24);
      height: rem-calc(64);

      &--first {
        padding-left: 0;
        border-left: 0;
      }
    }

    &__author-wrapper {
      &--2 {
        max-width: 50%;
      }

      &--3 {
        max-width: 33%;
      }
    }
  }

  &-collated {
    // displayed on mobile for all numbers of authors and on desktop for > 3

    margin-bottom: rem-calc(32);

    &__grid {
      flex-wrap: nowrap;
      align-items: center;
    }

    &__circle {
      height: rem-calc(60);
      width: rem-calc(60);
      border-radius: 50%;
      background-color: $grey-100;
      border: rem-calc(1) solid $white;
      display: inline-block;
      margin-right: rem-calc(-52);
    }

    &__dropdown {
      padding-top: rem-calc(24);
      display: none;
      position: absolute;
      background: $white;
      z-index: 100;

      &--open {
        display: inline-block;
      }
    }

    &__dropdown-close {
      position: relative;
      top: rem-calc(-16);
      right: rem-calc(-16);
      float: right;
      color: $grey-500;
    }

    &__dropdown-inner {
      border-radius: rem-calc(5);
      box-shadow: rem-calc(0 2 6 0) rgba(0, 0, 0, 0.25);
      padding: rem-calc(24 24 0);
      align-items: baseline;

      .fil-article-attribution-author {
        margin-bottom: rem-calc(24);
      }
    }

    @include breakpoint(small only) {
      margin-bottom: rem-calc(24);

      &__grid {
        justify-content: center;
      }

      &__dropdown {
        position: fixed;
        text-align: center;
        top: 50%;
        width: 100%;
        left: 50%;
        margin-left: -50%;
        transform: translateY(-50%);
        background: none;
        padding-top: 0;
      }

      &__dropdown-inner {
        display: inline-block;
        text-align: left;
        background: $white;
        max-height: 80vh;
        overflow-y: scroll;
      }
    }
  }

  &-lower {
    // displayed at the bottom of both mobile and desktop pages

    margin-bottom: rem-calc(64);

    &__author-container {
      background: $grey-25;
      border: rem-calc(0.5) solid $grey-50;
      display: flex;
    }

    .fil-article-attribution-author {
      padding: rem-calc(30);
    }

    @include breakpoint(medium) {
      &__author-container {
        &:nth-child(even) {
          border-left: 0;
        }

        &:nth-child(n+3) {
          border-top: 0;
        }
      }
    }

    @include breakpoint(small only) {
      margin-bottom: rem-calc(40);

      &__author-container:nth-child(n+2) {
        border-top: 0;
      }

      .fil-article-attribution-author {
        padding: rem-calc(20);
      }
    }
  }
}