.select50-overlay {
  .fil-tabs {
    padding: 0;

    .tabs-portrait {
      border-bottom: 0;
      flex-wrap: wrap;
      justify-content: center;
      visibility: hidden;

      .tabs-title {
        background-color: transparent;
        border: 0;
        height: auto;
        margin: rem-calc(8 16);
        max-height: rem-calc(100);
        max-width: rem-calc(100);
        min-height: rem-calc(100);
        min-width: rem-calc(100);
        visibility: visible;

        @include breakpoint(medium) {
          margin: rem-calc(8 32);
        }

        &:first-child {
          border-left: 0;
        }

        a {
          margin-left: 0;
          padding-left: 0;
          padding-right: 0;
        }

        &.is-active {
          border: 0;

          &::before {
            display: none;
          }

          a {
            background: transparent;
          }

          img {
            border: rem-calc(3) solid $blue-400;
            border-radius: 50%;
          }
        }
      }

      &:hover {
        .tabs-title a {
          background: transparent;
        }
      }
    }

    .tabs-clone .tabs-panel {
      position: relative;
    }

    .tabs-content {
      position: relative;
      transform-origin: top;
      white-space: normal;

      .tabs-panel {
        display: block;
        opacity: 0;
        position: absolute;
        transform-origin: top;
        transition: opacity 0.1s ease;
        width: 100%;
        z-index: 10;

        &.is-active {
          opacity: 1;
          transition: opacity 0.1s ease 0.4s;
          z-index: 20;
        }

        section:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .owl-dots {
    display: none;
  }

  .tabs-portrait > * {
    transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
  }

  /* Makes the fades smooth with a slight delay to prevent jumps as the mouse moves between items */

  .tabs-portrait:hover > * {
    opacity: 0.4;
    transform: scale(0.9);
  }

  /* Fade out all items when the parent is hovered */

  .tabs-portrait > *:hover,
  .tabs-portrait > .is-active {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0ms, 0ms;
  }

  .broker-info-tabs-container {
    .tabs-content {
      background: transparent;
      border: none;
    }
  }
}
