@import './fil-card-utils';

.fil-cards {
  .card {
    &.icon {
      padding-top: 0;

      &.dual-cta {
        padding-top: 0;
        padding-bottom: rem-calc(140);
      }

      &.cta-only {
        padding: rem-calc(0 0 70);
      }

      .card-header {
        border-radius: rem-calc(4 4 0 0);

        img {
          width: rem-calc(60);
          height: rem-calc(60);
          margin: rem-calc(30) auto rem-calc(24);
        }
      }

      .header-icon {
        font-size: rem-calc(60);
      }

      h4 {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }

    &.image {
      padding-top: 0;

      &.cta-only {
        padding: rem-calc(0 0 70);
      }

      .image-header {
        min-height: rem-calc(232);
        border-radius: rem-calc(4 4) 0 0;
        padding-top: rem-calc(140);
        background-size: cover;
        background-blend-mode: multiply;
        position: relative;

        h4 {
          color: $white;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          text-align: center;
          margin-bottom: 0;
          padding: 0 rem-calc(20 26);
        }
      }

      .card-section {
        padding-top: rem-calc(22);
      }
    }

    &.mini-promo,
    &.promo {
      cursor: pointer;
      padding: 0;
      text-align: left;
      min-height: rem-calc(143);
      overflow: hidden;

      @include breakpoint(medium down) {
        margin-bottom: rem-calc(23);
      }

      &:hover .cta-section a {
        color: $grey-900;
      }

      .image-container {
        background-color: $grey-50;
        padding-top: 8%;
        bottom: 0;
      }

      .side-text {
        padding-right: rem-calc(20);
        font-size: rem-calc(18);
      }

      .image {
        min-height: rem-calc(143);
        width: rem-calc(112);
        padding: rem-calc(15) 0;
        background-repeat: no-repeat;
        background-position: 50%;

        img {
          width: 100%;
        }
      }

      .text {
        position: relative;
        padding: rem-calc(15 20);

        h5 {
          margin-bottom: rem-calc(5);
        }

        p {
          font-size: rem-calc(13);
          line-height: rem-calc(19);
          min-height: auto;
          text-align: left;
        }

        a {
          left: rem-calc(20);
          bottom: rem-calc(15);

          span {
            top: rem-calc(-1);
          }
        }
      }

      .cta-section {
        left: rem-calc(20);
        width: auto;
        bottom: rem-calc(16);
        line-height: 1;

        a {
          margin-bottom: 0;
          font-size: rem-calc(16);
        }

        @include breakpoint(small only) {
          position: relative;
          left: 0;
          margin-bottom: 0;
          top: rem-calc(4);
        }
      }
    }

    &.promo {
      text-align: center;

      .image {
        background-position-y: rem-calc(15);
        background-position-x: center;
      }

      ul {
        margin-top: rem-calc(16);
      }

      li {
        margin-bottom: rem-calc(5);
      }

      p {
        font-size: rem-calc(18);
        min-height: 0;
        text-align: left;
      }

      .text {
        padding: rem-calc(15 0);

        .card-section {
          padding-left: rem-calc(20);
          padding-right: rem-calc(20);

          .button {
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);
            font-size: rem-calc(13);
          }
        }
      }
    }

    &.selector {
      padding-top: 0;
      padding-bottom: rem-calc(90);

      &.dual-cta {
        padding-top: 0;
        padding-bottom: rem-calc(140);
      }

      .card-header {
        border-radius: rem-calc(4 4) 0 0;
        background: $blue-500;

        img {
          width: rem-calc(72);
          height: rem-calc(72);
          margin: rem-calc(30) auto;
        }
      }

      .tool-name {
        min-height: auto;
        display: block;
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(10);
        color: $blue-500;
        font-size: rem-calc(18);
        font-weight: bold;
      }

      h4 {
        margin-top: 0;
        margin-bottom: rem-calc(24);
      }
    }

    &.imagetext {
      padding-top: 0;
      padding-bottom: 0;
      background-color: $grey-50;

      .image-text {
        min-height: rem-calc(232);
        border-radius: rem-calc(4 4) 0 0;
        padding-top: rem-calc(140);
        background-size: cover;
        background-blend-mode: multiply;
        position: relative;

      }

      .card-section {
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(40);
        position: relative;
      }
    }
  }

  .cell {
    &:nth-of-type(1) {
      .selector {
        .card-header {
          background: $teal-500;
        }

        .tool-name {
          color: $teal-700;
        }
      }
    }

    &:nth-of-type(2) {
      .selector {
        .card-header {
          background: $blue-500;
        }

        .tool-name {
          color: $blue-700;
        }
      }
    }

    &:nth-of-type(3) {
      .selector {
        .card-header {
          background: $blue-300;
        }

        .tool-name {
          color: $blue-500;
        }
      }
    }

    @include set-selector-card-theme('.selector');
  }
}
