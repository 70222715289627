$hero-content-padding: 24;
$hero-content-item-padding: 32;

.fil-hero-edition__wrapper {
  margin-bottom: rem-calc(8);
  padding-bottom: 0;
}

.fil-hero-edition {
  position: relative;

  &__fil-answers {
    position: absolute;
    top: rem-calc(32);
    padding: rem-calc(12 16);
    background-color: $blue-700;
    color: white;
    font-weight: bold;
  }

  &__banner-and-title-section.grid-container {
    padding-left: 0;
    padding-right: 0;
  }

  &__bg-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-height: rem-calc(320);
  }

  .by-author {
    font-weight: bold;
  }

  &__title-and-author-section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    z-index: 10;
    padding-top: rem-calc($hero-content-padding);
    padding-bottom: 0;

    @include breakpoint(medium up) {
      padding-top: rem-calc(24);
      padding-bottom: rem-calc(8);
      padding-left: rem-calc(24);
    }

    .countdown-wrapper {
      padding: rem-calc(0 0 40 0);
      text-align: center;

      .countdown {
        display: block;
        width: 100%;
        font-size: rem-calc(44);
        font-weight: bold;

        div {
          padding: 0 rem-calc(8);
          display: inline-block;
          float: left;

          &:first-child {
            padding-left: 0;
          }

          .date-label {
            display: block;
            color: inherit;
            padding: 0;
            margin-top: rem-calc(-9);
            font-size: rem-calc(15);
            font-weight: normal;
            text-align: center;
          }
        }

        @include breakpoint(medium only) {
          font-size: rem-calc(34);
        }

        @include breakpoint(small only) {
          width: auto;
          display: inline-block;
          font-size: rem-calc(34);

          div {
            padding: rem-calc(0 5);
          }
        }
      }
    }


    @at-root :lang(ja) & {
      h1 {
        font-size: rem-calc(37);
        line-height: rem-calc(45);
      }

      p.intro {
        font-size: rem-calc(18);
        line-height: rem-calc(32);
      }

      @include breakpoint(small only) {
        h1 {
          font-size: rem-calc(33);
          line-height: rem-calc(40);
        }

        p.intro {
          font-size: rem-calc(16);
          line-height: rem-calc(23);
        }
      }
    }

    .fil-feature-edition__text-link {
      display: inline-block;
      margin-bottom: rem-calc(24);

      &:hover {
        color: $white;
        opacity: 0.7;
      }
    }

    .button {
      margin-bottom: 0;
      align-self: flex-start;
    }

    &.reduced {
      &::after {
        right: -50%;
      }
    }
  }

  &__author-line,
  &__publish-date-line {
    margin-bottom: rem-calc(8);
  }

  &__author-line {
    margin-right: rem-calc(24);

    @include breakpoint(small only) {
      width: 100%;
    }
  }

  &__author-publish-date-separator {
    border-left-width: rem-calc(1);
    border-left-style: solid;
    border-left-color: $grey;
  }

  .fil-feature-edition__bgImage {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: $grey-400;
  }

  &__content-title {
    font-weight: bold;
    margin-bottom: rem-calc(16);
    margin-top: rem-calc(-11);
    font-size: rem-calc(32);
  }

  &__content-title-link {
    color: $black;

    &:hover {
      color: $blue;
    }
  }

  &__banner-section {
    @include breakpoint(medium up) {
      margin-bottom: rem-calc(24);
    }
  }

  &__edition-summary-section {
    &--large-only {
      padding-right: rem-calc(36);

      @include breakpoint(medium down) {
        display: none;
      }
    }

    &--small-and-medium-only {
      display: none;

      @include breakpoint(medium down) {
        display: block;
      }
    }
  }

  &__author-publish-date-section {
    margin-bottom: rem-calc(8);
  }

  &__edition-summary {
    margin-bottom: 0;
  }
}

@include breakpoint(small only) {
  .fil-hero-edition {
    .fil-hero-edition__content {
      min-height: 0;
      padding: rem-calc(32 0 8);

      &::after {
        left: -50%;
        right: -50%;
        transform: none;
      }

      .button {
        align-self: center;
        text-align: center;
      }
    }

    .fil-feature-edition__content {
      padding-top: rem-calc(32);

      &::after {
        left: rem-calc(-9000);
      }
    }
  }
}
