$base-showhide-anim-speed: 0.2s;

.fil-showhide {
  margin-bottom: 0;
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .fil-text {
    .grid-container {
      padding: 0;

      .medium-10 {
        width: 100%;
      }
    }
  }
}

.js-showhide {
  .showhide__visible {
    margin-top: rem-calc(24);

    img {
      margin-bottom: rem-calc(36);
    }

    p {
      margin-bottom: 0;
    }
  }

  .accordion {
    width: 100%;
  }

  &.bg-grey-50 {
    .accordion-item {
      &::after {
        background: linear-gradient(
          to bottom,
          rgba(238, 239, 240, 0) 0%,
          rgba(238, 239, 240, 1) 100%
        );
      }
    }
  }

  .accordion-item {
    width: 100%;
    text-align: center;
    position: relative;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: rem-calc(-48);
      width: 100%;
      height: rem-calc(48);
      opacity: 1;
      transition: opacity $base-showhide-anim-speed ease $base-showhide-anim-speed;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    .accordion-content {
      text-align: left;

      .fil-image {
        margin-top: rem-calc(36);
      }

      > section {
        padding-top: 0;
        padding-bottom: 0;

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .fil-showhide__button {
      margin: rem-calc(64) auto 0 auto;
      padding: rem-calc(16 24 0 0);
      border-top: rem-calc(2) solid $grey-100;
      display: inline-block;
      position: relative;
      font-weight: bold;
      font-size: rem-calc(18);

      &:hover {
        color: $blue-800;
        text-decoration: none;

        .fil-icon-container {
          .fil-icon {
            transition: all 0.35s ease;

            &:first-of-type {
              transform: scale(1, 1) translateY(0);
            }

            &:last-of-type {
              transform: scale(1, 1) translateY(rem-calc(18));
            }
          }
        }
      }

      .showhide-trigger__text-open {
        display: inline;
      }

      .showhide-trigger__text-close {
        display: none;
      }

      .fil-icon-container {
        position: absolute;
        top: rem-calc(16);
        right: 0;
        display: block;
        width: rem-calc(18);
        height: rem-calc(18);
        overflow: hidden;

        .fil-icon {
          display: block;
          position: absolute;
          right: 0;
          width: rem-calc(18);
          height: rem-calc(18);
          transition: all 0s ease;

          &:first-of-type {
            transform: scale(1, 1) translateY(rem-calc(18));
          }

          &:last-of-type {
            transform: scale(1, 1) translateY(0);
          }
        }
      }
    }

    &.is-active {
      &::after {
        transition: opacity $base-showhide-anim-speed ease;
        opacity: 0;
      }

      .fil-showhide__button {
        .showhide-trigger__text-open {
          display: none;
        }

        .showhide-trigger__text-close {
          display: inline;
        }

        .fil-icon-container {
          top: rem-calc(24);

          .fil-icon {
            transition: all 0s ease;

            &:first-of-type {
              transform: scale(1, -1) translateY(0);
            }

            &:last-of-type {
              transform: scale(1, -1) translateY(rem-calc(-18));
            }
          }
        }

        &:hover {
          .fil-icon-container {
            .fil-icon {
              transition: all 0.35s ease;

              &:first-of-type {
                transform: scale(1, -1) translateY(rem-calc(18));
              }

              &:last-of-type {
                transform: scale(1, -1) translateY(0);
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(small only) {
  .js-showhide .accordion-item .fil-showhide__button {
    font-size: rem-calc(16);
    padding: rem-calc(16 20.8 0 0);

    .fil-icon-container {
      right: rem-calc(-4);
    }
  }
}
