.fil-enhanced-list {
  padding: rem-calc($component-padding-base 0);

  p {
    margin-bottom: rem-calc(63);
  }

  .yext-wrapper {
    margin-left: 0;
    margin-bottom: 0;
    background-color: color(grey-25);
    border: rem-calc(1) solid color(grey-50);
    border-radius: rem-calc(4);
    padding: rem-calc(38 40 38 57);

    .search-form-enhanced-list {
      .yxt-SearchBar-container {
        padding-top: rem-calc(4);
        background-color: color(grey-25);
      }

      .yxt-SearchBar-button {
        background-color: color(grey-25);
        padding-top: 0;
      }
    }
  }

  ul {
    margin-left: 0;
    margin-bottom: 0;
    padding: rem-calc(38 40 38 57);

    li {
      padding-left: rem-calc(28);

      &::before {
        width: auto;
        height: auto;
        top: rem-calc(-3);
        color: color(blue-500);
        font-family: 'fidelity-icon-font';
        font-size: rem-calc(12);
        content: icon(tick-regular);
        background: $white !important;
        padding: rem-calc(6 9);
        border: rem-calc(1) solid color(grey-50);
        border-radius: 50%;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.link-list {
      li {
        &::before {
          content: icon(chevron-r-regular);
        }

        a {
          display: block;
          width: 100%;
        }

        .tag {
          display: inline-block;
          margin-top: rem-calc(5);
          padding: rem-calc(5 10);
          border: rem-calc(1) solid;
          border-left: rem-calc(10) solid;
          background: $white;
          font-size: rem-calc(15);

          &.blue-500 {
            border-color: $blue-500;
          }

          &.grey-500 {
            border-color: $grey-500;
          }

          &.teal-500 {
            border-color: $teal-500;
          }

          &.green-500 {
            border-color: $green-500;
          }

          &.yellow-500 {
            border-color: $yellow-500;
          }

          &.orange-500 {
            border-color: $orange-500;
          }

          &.purple-500 {
            border-color: $purple-500;
          }
        }
      }
    }

    &.task-list {
      padding: rem-calc(23 40 23 57);

      li {
        padding-top: rem-calc(15);
        padding-left: rem-calc(80);
        margin-bottom: rem-calc(15);

        &::before {
          display: none;
        }

        img {
          margin-right: rem-calc(15);
          width: rem-calc(25);
          height: rem-calc(25);
        }

        a {
          position: relative;
          top: rem-calc(2);
        }
      }
    }

    &.dynamic-list {
      li {
        &::before {
          display: none;
        }

        img {
          margin-right: rem-calc(15);
          width: rem-calc(25);
          height: rem-calc(25);
        }
      }
    }
  }
}

@include breakpoint(medium only) {
  .fil-enhanced-list {
    ul {
      &.task-list {
        .cell:nth-of-type(3) {
          width: 100%;

          li {
            width: 50%;
            float: left;
          }
        }
      }
    }
  }
}

@include breakpoint(small only) {
  .fil-enhanced-list {
    ul {
      padding: rem-calc(30 23 30 43);

      &.task-list {
        li {
          padding-left: 10%;
        }
      }
    }

    p {
      margin-bottom: rem-calc(40);
    }
  }
}
