.fil-related-stories {
  padding-top: rem-calc(64);
  border-top: rem-calc(1) solid $grey-100;
  margin-bottom: rem-calc(40);

  &__outer-container {
    padding: 0 rem-calc(30);
  }

  &__title {
    margin-bottom: rem-calc(48);
  }

  @include breakpoint(small only) {
    padding-top: rem-calc(40);
    margin-bottom: rem-calc(40);

    &__title {
      margin-bottom: rem-calc(32);
    }

    &__grid {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.fil-related-story {
  margin-bottom: rem-calc(40);

  &__image-container {
    text-align: center;
    overflow: hidden;
    position: relative;
    padding-bottom: 60%;
  }

  &__image {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  &__text-container {
    padding: rem-calc(20 24 24);
    flex-grow: 1;
  }

  &__footer-container {
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__tag {
    margin-bottom: rem-calc(16);
    color: $blue-600;
  }

  &__article-title {
    margin-bottom: rem-calc(16);
  }

  &__author-details {
    margin-bottom: rem-calc(4);
  }

  &__article-date {
    margin-bottom: rem-calc(0);
  }

  &__avatar {
    height: rem-calc(160);
    width: rem-calc(160);
    object-fit: cover;
  }

  @include breakpoint(small only) {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: rem-calc(21);
    }

    &__text-container {
      padding: rem-calc(22 16 27);
    }

    &__article-title {
      margin-bottom: rem-calc(23);
    }
  }
}
