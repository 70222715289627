// Constants
$E: 2.718281828459045;
$PI: 3.141592653589793;
$LN2: 0.6931471805599453;
$SQRT2: 1.4142135623730951;

$fid-color-palette: (
  primary-red: #b72f2a,
  primary-blue: #006193,
  grey-25: #f7f7f8,
  grey-50: #eeeff0,
  grey-100: #dcdee1,
  grey-200: #b9bdc4,
  grey-300: #979da6,
  // quaternary (light range)
    grey-400: #747c89,
  grey-500: #515b6b,
  // secondary (mid range)
    grey-600: #414956,
  grey-700: #313740,
  // tertiary (dark range)
    grey-800: #20242b,
  grey-900: #101215,
  blue-25: #ecf9fd,
  blue-50: #c5ebf9,
  blue-100: #83d4f2,
  blue-200: #42bdea,
  blue-300: #00a6e3,
  // quaternary (light range)
    blue-400: #018bc8,
  blue-500: #016fad,
  // secondary (mid range)
    blue-600: #016195,
  blue-700: #00527c,
  // tertiary (dark range)
    blue-800: #004263,
  blue-900: #00314a,
  teal-25: #dbf9f9,
  teal-50: #b6f2f2,
  teal-100: #85e9e9,
  teal-200: #54e0e0,
  teal-300: #0bd2d2,
  // quaternary (light range)
    teal-400: #11c5c5,
  teal-500: #17b8b8,
  // secondary (mid range)
    teal-600: #0d9c9c,
  teal-700: #028080,
  // tertiary (dark range)
    teal-800: #026666,
  teal-900: #014d4d,
  green-25: #f1f9e0,
  green-50: #e3f3c1,
  green-100: #d1ec98,
  green-200: #bee46e,
  green-300: #a2d830,
  // quaternary (light range)
    green-400: #82c823,
  green-500: #61b815,
  // secondary (mid range)
    green-600: #4aa00f,
  green-700: #338808,
  // tertiary (dark range)
    green-800: #296d06,
  green-900: #1f5205,
  yellow-25: #fffae3,
  yellow-50: #fff4c7,
  yellow-100: #ffeda2,
  yellow-200: #ffe57c,
  yellow-300: #ffda44,
  // quaternary (light range)
    yellow-400: #ffcd22,
  yellow-500: #ffc000,
  // secondary (mid range)
    yellow-600: #edae00,
  yellow-700: #d99f00,
  // tertiary (dark range)
    yellow-800: #c48c00,
  yellow-900: #ae7400,
  orange-25: #fff2d9,
  orange-50: #ffe5b3,
  orange-100: #ffd480,
  orange-200: #ffc24d,
  orange-300: #ffa800,
  // quaternary (light range)
    orange-400: #ff9b19,
  orange-500: #ff8d31,
  // secondary (mid range)
    orange-600: #ea6a19,
  orange-700: #d14905,
  // tertiary (dark range)
    orange-800: #aa3800,
  orange-900: #7f2a00,
  purple-25: #f7e4f9,
  purple-50: #eec9f2,
  purple-100: #e3a5ea,
  purple-200: #d881e2,
  purple-300: #c74bd5,
  // quaternary (light range)
    purple-400: #ae30bc,
  purple-500: #9514a3,
  // secondary (mid range)
    purple-600: #841b8f,
  purple-700: #72217b,
  // tertiary (dark range)
    purple-800: #5b1a62,
  purple-900: #44144a,
  red-500: #ad0101,
  error-red-500: #e51e17,
  error-red-25: #fdefee
);

// color aliases for easier usage of more commonly used colors.
$fid-colors: map-merge(
  $fid-color-palette,
  (
    grey: map-get($fid-color-palette, grey-500),
    grey-dark: map-get($fid-color-palette, grey-700),
    grey-light: map-get($fid-color-palette, grey-300),
    blue: map-get($fid-color-palette, blue-500),
    blue-dark: map-get($fid-color-palette, blue-700),
    blue-light: map-get($fid-color-palette, blue-300),
    teal: map-get($fid-color-palette, teal-500),
    teal-dark: map-get($fid-color-palette, teal-700),
    teal-light: map-get($fid-color-palette, teal-300),
    green: map-get($fid-color-palette, green-500),
    green-dark: map-get($fid-color-palette, green-700),
    green-light: map-get($fid-color-palette, green-300),
    yellow: map-get($fid-color-palette, yellow-500),
    yellow-dark: map-get($fid-color-palette, yellow-700),
    yellow-light: map-get($fid-color-palette, yellow-300),
    orange: map-get($fid-color-palette, orange-500),
    orange-dark: map-get($fid-color-palette, orange-700),
    orange-light: map-get($fid-color-palette, orange-300),
    purple: map-get($fid-color-palette, purple-500),
    purple-dark: map-get($fid-color-palette, purple-700),
    purple-light: map-get($fid-color-palette, purple-300)
  )
);

@function color($key) {
  @if map-has-key($fid-colors, $key) {
    @return map-get($fid-colors, $key);
  }

  @warn "Unknown `#{$key}` in $fid-color-palette";
  @return null;
}
