body {
  :lang(ja) {
    font-family: $noto-jp;
  }

  :lang(zh-hans) {
    font-family: $noto-sc;
  }

  :lang(zh-hant) {
    font-family: $noto-tc;
  }

  :lang(ko) {
    font-family: $noto-kr;
  }
}

:lang(ja) .foundation-mq,
:lang(zh-hans) .foundation-mq,
:lang(zh-hant) .foundation-mq,
:lang(ko) .foundation-mq {
  font: rem-calc(15) 'small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em' !important;
}
