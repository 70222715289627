.editorial {
  &.fil-media-grid {
    padding: rem-calc($component-padding-base 0);

    .media-grid-item {
      margin: rem-calc(40 0);
      border-bottom: rem-calc(1) solid $dark-gray;
      padding-bottom: rem-calc(48);

      &:last-of-type {
        border-bottom: none;
      }

      &__image {
        background-size: cover;
        width: 4em;
        height: 4em;
        float: left;
        margin-right: 1em;
      }

      &__edition-image {
        background-size: cover;
        height: rem-calc(352);

        @include breakpoint(small only) {
          height: rem-calc(183);
        }
      }

      &__image-cell {
        position: relative;
        width: 70%;
      }

      &__text-cell {
        width: 30%;
        padding: 0;
      }

      &__image-cell-title {
        margin-bottom: rem-calc(8);
      }

      &__image-cell-text-container {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: rem-calc(0 20);
        background-color: $white;
        width: 90%;
      }

      &__text-cell-title,
      &__text-cell-published-date {
        display: none;
      }

      &__image-cell-published-date {
        margin-bottom: 0;
      }

      &__clear {
        clear: both;
      }

      &__preview-article-row {
        margin-bottom: 2em;
      }

      &__show-all-answers-button {
        width: 100%;
      }

      &__article-link {
        color: $black;
        font-size: 1.1em;
        text-decoration: none;

        &:hover {
          color: $anchor-color;
        }
      }

      &__edition-title-link:hover {
        color: $anchor-color;
      }
    }

    .cell {
      section {
        &:first-of-type {
          padding-top: 0;
          margin-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }

      &.edition-list {
        padding: 0;
      }
    }

    .inner-image-cell {
      position: relative;
    }

    .grey {
      padding: rem-calc(65 0 65 80);
      position: relative;
      background-color: $grey-50;

      .cell:last-of-type {
        padding-right: 0;
      }

      &::after {
        content: '';
        z-index: 8;
        position: absolute;
        top: 0;
        right: 0;
        width: 8%;
        height: 100%;
        background-color: $white;
      }

      img {
        position: relative;
        z-index: 9;
      }

      &.left {
        .cell:last-of-type {
          padding-right: rem-calc(15);
        }

        &::after {
          display: none;
        }
      }
    }

    h3 {
      font-size: rem-calc(37);
      margin: rem-calc(-9 0 24);
      position: relative;
    }

    p {
      margin-bottom: 0;

      a {
        display: inline;
      }
    }

    a {
      display: block;
    }

    .button {
      margin: 0;
    }
  }
}

@include breakpoint(medium down) {
  .editorial {
    &.fil-media-grid {
      .media-grid-item {
        margin: rem-calc(25 0);
        padding-bottom: rem-calc(25);

        &__image {
          background-size: cover;
          width: 5em;
          height: 5em;
          float: left;
        }

        &__image-cell {
          width: 100%;
        }

        &__text-cell {
          width: 100%;
        }

        &__image-cell-title,
        &__image-cell-published-date {
          display: none;
        }

        &__text-cell-title,
        &__text-cell-published-date {
          display: block;
        }

        &__image-cell-text-container {
          display: none;
        }

        &__article-link {
          font-size: rem-calc(19);
        }
      }

      .cell {
        &:first-of-type {
          padding: 0;
        }

        &:nth-of-type(2) {
          padding-left: 0;
        }

        h3 {
          margin: rem-calc(20 0);
          font-size: rem-calc(33);
        }

        p {
          margin-bottom: rem-calc(20);
        }

        section {
          &:first-of-type {
            padding-top: inherit;
          }

          &:last-of-type {
            padding-bottom: inherit;
          }
        }
      }

      .text-left {
        &:first-of-type {
          padding-left: 0;
        }
      }

      .grey {
        padding: rem-calc(0 0 20);

        .cell {
          padding: 0;

          p {
            padding: rem-calc(0 20);
          }

          h3 {
            padding: rem-calc(0 4);

            a {
              padding: 0;
            }
          }
        }

        &::after {
          display: none;
        }
      }

      .preview-articles {
        margin-bottom: rem-calc(30);

        p {
          margin-bottom: 0;
        }

        .reading-time {
          .fil-icon {
            display: none;
          }
        }
      }
    }
  }
}
