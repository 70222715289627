.fund-details_container {
  margin: 0;
  padding-bottom: rem-calc(32);
  padding-top: rem-calc(64);

  & + & {
    padding-top: rem-calc(10);
  }

  &.bg-grey-50 {
    margin-bottom: 0;
  }

  .grid-container {
    border: rem-calc(2) solid $grey-100;
    border-radius: rem-calc(3);
    padding-bottom: rem-calc(32);
    padding-left: 0;
    padding-right: 0;
    padding-top: rem-calc(32);

    &.fund-details_additional-last {
      border: 0;
      padding-bottom: 0;

      button {
        margin-left: rem-calc(48);
      }
    }
  }

  .border-left {
    border-left: rem-calc(1) solid $grey-100;
  }

  .fil-showhide__button {
    color: $blue-500;
    cursor: pointer;
  }
}

.fund-details_primary-fund {
  .fund-details_info-meta {
    padding-bottom: rem-calc(32);
  }
}

.fund-details_additional-fund-intro {
  border-bottom: rem-calc(1) solid $grey-100;
  border-top: rem-calc(1) solid $grey-100;
  padding-bottom: rem-calc(32);
  padding-left: rem-calc(32);
  padding-top: rem-calc(32);

  p {
    margin-bottom: 0;
  }
}

.align-center {
  justify-content: center;
}

@include breakpoint(small only) {
  .small-align-center {
    justify-content: center;
  }
}

.fund-details_main {
  flex-basis: 100%;
  position: relative;

  @include breakpoint(medium) {
    padding-left: rem-calc(32);
  }

  h2 {
    margin-bottom: rem-calc(32);
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }

    a {
      font-weight: bold;
    }
  }

  .fund-details_provider {
    margin-bottom: rem-calc(48);
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  .fund-details_info-meta-wrapper {
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  .fund-details_info-meta {
    border-bottom: rem-calc(1) solid $grey-100;
    border-top: rem-calc(1) solid $grey-100;
    display: inline-block;
    margin-bottom: rem-calc(48);
    padding-top: rem-calc(16);
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
    }

    ul {
      padding-right: rem-calc(16);

      li {
        margin-bottom: 0;
      }
    }

    .label {
      display: block;
      margin-bottom: 0;

      @include breakpoint(medium) {
        display: inline-block;
        width: rem-calc(180);
      }
    }

    .data {
      display: block;
      font-weight: bold;
      margin-bottom: rem-calc(8);

      @include breakpoint(medium) {
        display: inline-block;
        margin-left: rem-calc(48);
      }
    }
  }
}

.fund-details_main .btn-group {
  margin-top: auto;
  position: relative;
}

.btn-show-more {
  opacity: 1;
  transition: all 0.5s ease;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease 0.5;
  }
}

.fund-details_meta {
  .numerical-label {
    margin-bottom: 0;
  }

  .numerical-display {
    font-size: rem-calc(48);
    font-weight: normal;
  }

  .fund-details_compare,
  .fund-details_notice {
    // Temporarily hidden
    display: none;
    margin-left: rem-calc(8);
    margin-right: rem-calc(8);

    @include breakpoint(medium) {
      // Temporarily hidden
      display: none;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .fil-icon {
    &:hover {
      &::after {
        transform: translateX(rem-calc(3.04));
      }
    }

    &::after {
      display: inline-block;
      font-weight: bold;
      margin-left: rem-calc(8);
      position: relative;
      text-decoration: none;
      top: rem-calc(2);
    }
  }

  .button {
    display: block;

    @include breakpoint(medium) {
      display: inline-block;
    }
  }
}

.rating-label {
  margin-bottom: rem-calc(8);
}

.m-star-rating {
  font-family: 'fidelity-icon-font';
  font-size: rem-calc(24);

  &::before {
    color: $yellow-400;
  }

  &::after {
    color: $grey-100;
  }

  &[data-rating='1'] {
    &::before {
      content: icon(star-fill);
    }

    &::after {
      content: '\e907 \e907 \e907 \e907';
    }
  }

  &[data-rating='2'] {
    &::before {
      content: '\e907 \e907';
    }

    &::after {
      content: '\e907 \e907 \e907';
    }
  }

  &[data-rating='3'] {
    &::before {
      content: '\e907 \e907 \e907';
    }

    &::after {
      content: '\e907 \e907';
    }
  }

  &[data-rating='4'] {
    &::before {
      content: '\e907 \e907 \e907 \e907';
    }

    &::after {
      content: icon(star-fill);
    }
  }

  &[data-rating='5'] {
    &::before {
      content: '\e907 \e907 \e907 \e907 \e907';
    }

    &::after {
      content: ' ';
    }
  }
}

.fund-details_info {
  margin-bottom: rem-calc(48);
  margin-left: 0;
  padding-left: rem-calc(40);
  position: relative;

  .fund-details_bullet {
    margin-bottom: rem-calc(24);
    padding-left: rem-calc(8);

    &::before,
    &::after {
      display: block;
      height: rem-calc(32);
      left: rem-calc(-40);
      position: absolute;
      top: rem-calc(3.2);
      width: rem-calc(32);
    }

    &::before {
      background-color: $white;
      border-radius: 50%;
      border: rem-calc(1) solid $grey-100;
    }

    &::after {
      color: $blue-500;
      content: icon(tick);
      font-family: 'fidelity-icon-font';
      font-size: rem-calc(20);
      font-weight: bold;
      text-align: center;
    }
  }
}

.fund-details_additional-fund {
  border-bottom: rem-calc(1) solid $grey-100;
  padding-bottom: rem-calc(32);
  padding-top: rem-calc(32);

  h3 {
    margin-bottom: rem-calc(12);
    margin-top: rem-calc(4);
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }

    a {
      font-weight: normal;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .fund-details_main {
    padding-bottom: 0;
  }

  .fund-details_info-meta {
    border-bottom: 0;
    border-top: 0;
    margin-bottom: 0;

    ul {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-right: 0;
      width: 100%;

      li {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;

        @include breakpoint(medium) {
          width: 50%;
        }

        .data,
        .label {
          display: block;
          flex-shrink: 0;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;

          @include breakpoint(medium) {
            text-align: left;
            width: 50%;
          }

          @include breakpoint(large) {
            display: inline-block;
          }
        }

        .data {
          margin-bottom: rem-calc(16);

          @include breakpoint(medium) {
            margin-bottom: rem-calc(8);
          }
        }
      }
    }
  }

  .subfund__notice-link,
  .subfund__compare-link {
    // Temporarily hidden
    visibility: hidden;
  }
}

.fund-details_additional-last {
  .btn-broup {
    margin-top: rem-calc(16);
    text-align: center;

    @include breakpoint(medium) {
      padding-left: rem-calc(32);
      text-align: left;
    }
  }
}
