.fil-accordion {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);
  margin-bottom: 0 !important;

  @include breakpoint(medium) {
    margin-bottom: rem-calc(40);
  }

  .accordion-item {
    border-bottom: rem-calc(1) solid $grey-100;
    position: relative;

    &:focus {
      background-color: $white;
    }

    &:first-of-type {
      border-top: rem-calc(1) solid $grey-100;
    }

    .accordion-title {
      display: block;
      padding: rem-calc(35 64 35 0);
      font-size: rem-calc(22);
      border: none;
      text-decoration: none;
      color: $grey-800;

      &::before {
        font-family: 'fidelity-icon-font';
        content: icon(chevron-d-light);
        color: $blue-500;
        position: absolute;
        width: rem-calc(28);
        height: rem-calc(28);
        line-height: rem-calc(28);
        font-size: rem-calc(20);
        text-align: center;
        margin-top: rem-calc(-2);
        padding-top: rem-calc(2);
        right: 0;
        transform-origin: center;
      }

      h5 {
        margin-bottom: 0;
      }

      .fil-icon {
        font-size: rem-calc(32);
        line-height: rem-calc(28);
        vertical-align: sub;
      }

      &:hover {
        color: $blue-500;
      }
    }

    .accordion-content {
      border: none;
      padding: rem-calc(13) 0;

      h5 {
        margin-bottom: 0;
        position: relative;
        max-height: rem-calc(38.4);
        overflow: hidden;

        &::after {
          content: '';
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 50%;
          height: rem-calc(19.2);
          background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
        }
      }

      .grid-container {
        padding-left: 0;
        padding-right: 0;
      }

      section.bg-grey-50 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    @supports (-webkit-line-clamp: 2) {
      h5 {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /* autoprefixer: off */
        -webkit-box-orient: vertical;

        /* autoprefixer: on */

        &::after {
          display: none;
        }
      }
    }

    &.is-active {
      .accordion-title {
        color: $blue;
        background: transparent;

        &::before {
          content: icon(chevron-u-light);
        }

        h5 {
          display: block;
        }
      }
    }
  }

  :last-child:not(.is-active) > .accordion-title {
    border-bottom: none;
  }

  .reference-button {
    pointer-events: none;
    position: absolute;
    right: rem-calc(78);
    top: rem-calc(19);
    border: rem-calc(1) solid $grey-100;
    color: $white;
    padding: rem-calc(10);
    text-decoration: none;
    background: $blue-500;
  }

  .fil-video {
    .kv-viewer {
      .kv-thumbnail {
        width: 100% !important;
        height: inherit !important;
      }

      div {
        width: 100% !important;
        height: inherit !important;
      }

      .kv-packshot-button {
        width: 100% !important;
        height: inherit !important;
      }

      .kv-packshot-button + .kv-play-icon img,
      .kv-packshot-button .kv-play-icon img {
        max-width: rem-calc(72);
      }
    }

    .medium-10 {
      width: 100%;
    }
  }
}

@include breakpoint(small only) {
  .fil-accordion {
    .accordion-item {
      .accordion-title {
        padding: rem-calc(25 64 25 0);

        &::before {
          margin-top: rem-calc(-5);
        }
      }
    }
  }
}
