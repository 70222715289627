.fil-card-grid-feature {
  padding-top: rem-calc($component-padding-base);
  margin-bottom: 0;

  &.bg-grey-50 {
    padding-bottom: rem-calc($component-padding-base) !important;
  }

  &.grey-True {
    padding-top: rem-calc(30);

    & + .grey-True {
      padding-top: 0;
    }
  }

  .cell {
    width: calc(50% - #{rem-calc(30)});

    &.small-12 {
      width: 100%;
    }
  }

  &.children-3,
  &.children-5,
  &.children-6 {
    .cell {
      &:nth-child(3),
      &:nth-child(6) {
        width: calc(100% - #{rem-calc(30)});
        padding-left: rem-calc(360);
        padding-right: rem-calc(360);

        @include breakpoint(medium only) {
          padding-left: rem-calc(150);
          padding-right: rem-calc(150);
        }

        .card {
          padding-left: 30%;
          padding-right: 30%;
          padding-top: rem-calc(90);

          p {
            margin-bottom: rem-calc(130) !important;
          }
        }
      }
    }
  }

  .card {
    padding: rem-calc(125 60 30);
    text-align: center;
    margin-bottom: rem-calc($component-padding-base);
    position: relative;

    h3 {
      margin-bottom: rem-calc(24);
      min-height: rem-calc(98);
      font-weight: bold;
    }

    p {
      margin-top: 0 !important;
      margin-bottom: rem-calc(130) !important;
    }

    a {
      position: absolute;
      bottom: rem-calc(30);
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
    }

    a[href^="tel"] {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      width: auto;
    }

    &.bg-image {
      background-color: $grey-50;
      background-size: cover;
      background-blend-mode: multiply;
      color: $white;

      a {
        color: $white;
      }

      .title-keyline {
        color: $white;

        &::after {
          background-color: $blue-200;
        }
      }
    }

    &.color-bg {
      &:nth-of-type(1) {
        background-color: $grey-50;

        .title-keyline {
          color: $grey-900;

          &::after {
            background-color: $blue-500;
          }
        }

        @at-root .bg-grey-50#{&} {
          background-color: $white;
        }
      }

      &:nth-of-type(2) {
        background-color: $blue-500;
        color: $white;

        .title-keyline {
          color: $white;

          &::after {
            background-color: $blue-200;
          }
        }

        a {
          color: $white;
        }
      }

      &:nth-of-type(3) {
        background-color: $blue-800;
        color: $white;

        a {
          color: $white;
        }

        .title-keyline {
          color: $white;

          &::after {
            background-color: $blue-200;
          }
        }
      }
    }
  }

  @include breakpoint(small only) {
    .grid-x {
      padding: 0 rem-calc(20);

      .cell {
        width: 95% !important;
        padding-left: rem-calc(20) !important;
        padding-right: rem-calc(20) !important;
      }
    }

    &.children-3,
    &.children-5,
    &.children-7,
    &.children-9 {
      .cell {
        width: 100%;

        &:nth-of-type(3),
        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(9) {
          width: calc(100% - #{rem-calc(30)});
          padding-left: 0;
          padding-right: 0;

          .card {
            padding-left: rem-calc(60);
            padding-right: rem-calc(60);
          }
        }
      }
    }

    padding-bottom: rem-calc($card-grid-padding-bottom-mobile);

    &.bg-grey-50 {
      padding-bottom: rem-calc($card-grid-padding-bottom-grey-mobile) !important;
    }

    .card {
      padding-top: rem-calc(56);
      margin-bottom: rem-calc($card-padding-bottom-mobile);
    }
  }
}
