//Enforcing the base font size for rems, !important to overwrite tools
html {
  font-size: rem-calc(16) !important;
}

h2 {
  font-weight: bold;
  font-size: rem-calc(37);
  margin-bottom: rem-calc(56);
  color: $grey;
}

h6,
p {
  margin-bottom: rem-calc(40);
}

ul,
ol {
  margin-bottom: rem-calc(40);
  margin-left: rem-calc(28);

  li {
    &::before {
      left: rem-calc(-28);
    }
  }

  ul,
  ol {
    margin-left: rem-calc(62);
  }

  ul {
    li {
      &::before {
        background-color: $white;
        border: rem-calc(1) solid $blue-500;
      }
    }
  }
}

ol {
  li {
    &::before {
      color: $grey-800;
    }
  }
}

a {
  &:hover {
    color: $grey-900;
    text-decoration: underline !important;
  }
}

.arrow-right-cta {
  &::after {
    content: ' \e95a';
    font-weight: bold;
    position: relative;
    font-family: fidelity-icon-font;
  }
}

@include breakpoint(small only) {
  h2 {
    margin-bottom: rem-calc(40);
  }

  h6,
  p {
    margin-bottom: rem-calc(40);
  }

  ul,
  ol {
    margin-left: rem-calc(24);

    li {
      &::before {
        left: rem-calc(-24);
      }
    }

    ul,
    ol {
      margin-left: rem-calc(25);
      margin-top: rem-calc(12);
    }
  }
}
