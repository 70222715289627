.fil-deep-dive-links {
  display: block;
  margin-bottom: rem-calc($outlook-margin-small);

  &__header {
    padding: 0 0 rem-calc($outlook-margin-small);
    margin: 0;
  }

  &__wrapper {
    border-radius: rem-calc(5);
    border: rem-calc(1) solid $blue-50;
  }

  &__link {
    border-bottom: rem-calc(1) solid $blue-50;
    display: table;
    width: 100%;
    text-decoration: none;
    background: $blue-25;

    &:last-child {
      border-bottom: 0
    }

    &:hover {
      .fil-deep-dive-links__link-title,
      .fil-deep-dive-links__arrow {
        color: $blue-500;
      }
    }
  }

  &__link-text-container {
    padding: rem-calc(24);
    display: table-cell;
  }

  &__link-title {
    margin: 0;
    color: $grey-900;
    font-weight: bold;
  }

  &__link-intro {
    position: relative;
    color: $grey-600;
    margin-top: rem-calc(2);

    p {
      margin: 0;
    }
  }

  &__arrow {
    color: $grey-600;
    display: table-cell;
    vertical-align: middle;
    padding-right: rem-calc(24);
    text-align: right;
  }

  @include breakpoint(medium down) {
    margin-bottom: rem-calc(56);

    &__link-intro {
      display: none;
    }

    &__arrow {
      padding-right: rem-calc(16);
    }
  }
}
