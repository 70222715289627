.left-hand-nav-wrapper {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  position: relative;

  .left-hand-nav-mobile {

    height: rem-calc(46);

    .tab-for-left-hand-nav-mobile {
      color: $white;
      background-color: $blue-800;
      padding: rem-calc(8 8);
      width: 100%;

      .tab-icon {
        color: $white;
        right: 0;
        display: inline-block;
        float: right;
      }

      h6 {
        margin-bottom: 0;
        display: inline-block;
      }
    }
  }

  button.toggle-left-nav {
    font-size: rem-calc(20);
    font-weight: bold;
    border-radius: 100%;
    height: rem-calc(45);
    width: rem-calc(45);
    color: $grey-800;
    outline: none;
    position: fixed;
    right: rem-calc(-20);
    background: $grey-25;
    border: rem-calc(1) solid $grey-100;
    left: rem-calc(20);
    z-index: -1;
    top: rem-calc(180);
    transition: left 0.2s ease-out;
    opacity: 0;

    .fil-icon {
      height: rem-calc(28);
      width: rem-calc(28);
    }

    &.button-active-class {
      opacity: 1;
      z-index: 90;

      &.active {
        left: 18%;
        z-index: 101;
        transition: left 0.2s ease-out;
        top: rem-calc(180);
        color: $blue-600;
      }
    }
  }

  .left-hand-nav {
    width: rem-calc(1);
    float: left;
    opacity: 0;
    background-color: $white;

    &.show-left-nav {
      display: block !important;
    }

    &.default-open {
      width: 20%;
      padding: rem-calc(20 0);
      position: fixed;
      z-index: 100;
      opacity: 1;
      top: 0;
      padding-top: rem-calc(40);
    }

    .masked-overlay-hide-sn {
      background: $white;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 1;

      &.hide-masked-overlay {
        display: none;
      }
    }

    .logo-container {
      padding: rem-calc(20 40);
      position: relative;
      top: rem-calc(4);
      background-color: $white;
    }

    &.left-nav-active {
      display: block;
      width: 20%;
      padding: rem-calc(20 0);
      position: fixed;
      z-index: 100;
      opacity: 1;
      top: 0;
      padding-top: rem-calc(40);
    }

    .subnav-list {
      overflow: hidden;
      padding-bottom: rem-calc(200);
    }

    .left-nav-menu {
      border-right: rem-calc(1) solid $grey-200;
      height: 100vh;
      position: relative;
      scrollbar-width: thin;
    }

    .list-item-wrapper {
      &.nav-item.active-menu-component {
        .list-item-heading {
          border-left: rem-calc(5) solid $blue-500;
          background-color: $grey-100;
          color: $black;
          font-weight: bold;
        }
      }

      ul {
        display: none;
      }

      &.active-menu-component {
        ul {
          display: block;
        }
      }

      ul.active-submenu {
        display: block;
      }
    }

    .list-item-heading {
      margin-bottom: rem-calc(0);
      padding: rem-calc(15 10);
      padding-left: rem-calc(20);
      font-size: rem-calc(15);
      border-left: rem-calc(5) solid $white;
    }

    a.left-nav-submenu-title {
      font-size: rem-calc(15);
      color: $grey-500;

      &:hover {
        color: $blue-500;
        text-decoration: none;
      }
    }

    a.accordion-title {
      color: $grey-500;

      &:hover {
        color: $blue-500;
        text-decoration: none;
      }
    }

    p {
      margin-bottom: rem-calc(20);
    }

    ul {
      margin-left: rem-calc(20);
      padding-left: 0;
      margin-top: rem-calc(20);
      margin-bottom: 0;

      li.left-hand-nav-list-item {
        padding-left: rem-calc(20);
        border-left: rem-calc(2) solid $grey-100;
        margin-bottom: 0;
        padding-bottom: rem-calc(10);

        &::before {
          display: none;
        }

        &.active-link {
          border-left: rem-calc(2) solid $grey-400;
        }
      }
    }
  }

  .left-hand-nav-pg-components {
    width: 100%;
    float: none;
    left: 0;

    &.left-nav-active {
      width: 80%;
      overflow: hidden;
      position: relative;
      left: 20%;
    }
  }

  ::-webkit-scrollbar {
    width: rem-calc(6);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* Keeping this to help track back */
    box-shadow: inset rem-calc( 0 0 0 0 ) $grey-500;
    border-radius: rem-calc(10);
    cursor: pointer;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-500;
    border-radius: rem-calc(6);
  }
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.active_siblink_element {
  color: $blue-500;
}

.fil-global-footer {
  width: 100%;
  left: 0;
  transition: left 0.3s ease-in;
  position: relative;

  &.left-nav-active {
    width: 80%;
    overflow: hidden;
    left: 20%;
  }
}

body {
  .remove-filter {
    filter: none !important;
    -webkit-filter: none !important;
  }

  .global-section {
    position: relative;
    z-index: 103;
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

.disable-scroll {
  margin: 0;
  overflow: hidden;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: rem-calc(1);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: rem-calc(1);
}