/* stylelint-disable selector-max-specificity */
// TODO: replace use of ID selectors throughout this document

.page-wrapper {
  clear: both;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

#fsearch {
  float: right;
  display: block;
  padding-right: rem-calc(32);
  max-height: rem-calc(113);

  @include breakpoint(medium only) {
    width: 100%;
  }

  .typeahead,
  .tt-query,
  .tt-hint {
    max-width: 100%;
    padding: rem-calc(17) 0;
    border: rgba(255, 255, 255, 0);
    outline: none;
  }

  .typeahead {
    background-color: $white;
    border-bottom: rem-calc(2) solid $white;
  }

  .typeahead:focus {
    border-bottom: rem-calc(2) solid $grey-dim-200;
  }

  .tt-query {
    -webkit-box-shadow: inset rem-calc(0 1 1) rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset rem-calc(0 1 1) rgba(0, 0, 0, 0.075);
    box-shadow: inset rem-calc(0 1 1) rgba(0, 0, 0, 0.075);
  }

  .tt-hint {
    color: $grey-medium-400;
  }

  .tt-menu {
    width: 100%;
    min-width: rem-calc(205);
    top: rem-calc(70) !important;
    padding: rem-calc(8) 0;
    background-color: $white;
    border: rem-calc(1) solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: rem-calc(0 5 10) rgba(0, 0, 0, 0.2);
    -moz-box-shadow: rem-calc(0 5 10) rgba(0, 0, 0, 0.2);
    box-shadow: rem-calc(0 5 10) rgba(0, 0, 0, 0.2);
  }

  .tt-suggestion {
    padding: rem-calc(3 20);
    font-size: rem-calc(18);
    line-height: rem-calc(24);

    &:hover {
      cursor: pointer;
      color: $white;
      background-color: $blue-250;
    }

    &.tt-cursor {
      color: $white;
      background-color: $blue-250;
    }

    p {
      margin: 0;
    }
  }

  .twitter-typeahead,
  input#q {
    display: inline-block;
    float: left;
  }

  .button {
    margin-left: rem-calc(8);
    float: left;
    color: $grey-dim-200;
    border-color: $grey-dim-200;
    font-size: rem-calc(18);

    &:hover {
      border-color: $blue;
    }

    &.fill {
      background-color: $blue;
      border-color: $blue;
    }
  }

  .mglass {
    cursor: pointer;
    position: relative;
    display: inline-block;
    float: left;
    padding: rem-calc(19 8);

    @include breakpoint(medium down) {
      z-index: 1;
    }

    &:hover {
      color: $blue-500;
    }
  }

  input[type='text'],
  input[type='search'] {
    line-height: 0.75;
    box-shadow: none;
    margin-top: rem-calc(10);
  }

  .search-wrapper {
    padding: rem-calc(16 0 35.5);
    position: relative;

    .input-holder {
      position: relative;
      height: rem-calc(50);
      width: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      @include breakpoint(medium only) {
        width: calc(100% - #{rem-calc(46)});
      }

      #init-search {
        margin-top: rem-calc(19);
        display: inline-block;
      }

      .search-input {
        position: absolute !important;
        width: 100%;
        height: rem-calc(39);
        min-width: rem-calc(588);
        opacity: 0;
        top: 0;
        left: 0;
        border-bottom: rem-calc(2) solid $grey-dim-200;
        outline: none;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition-delay: 0s;
        box-sizing: content-box;
        padding: 0;
        box-shadow: none;

        @include breakpoint(medium only) {
          min-width: 0;
        }
      }

      .button {
        opacity: 0;
        margin-top: rem-calc(10);
        left: calc(100% - #{rem-calc(105)});
        z-index: 2;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition-delay: 0s;
        position: absolute;
      }

      .twitter-typeahead {
        display: none !important;
      }

      a {
        color: $grey-800;

        &:hover {
          color: $blue;
        }
      }
    }

    #close-search {
      position: absolute;
      z-index: -1;
      top: rem-calc(36);
      right: rem-calc(20);
      width: rem-calc(25);
      height: rem-calc(25);
      opacity: 0;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    &.active {
      #init-search {
        display: none;
      }

      .input-holder {
        margin-right: rem-calc(38);
        transition: all 0.5s ease-in-out;
        width: rem-calc(726);

        @include breakpoint(medium only) {
          width: calc(100% - #{rem-calc(56)});
        }
      }

      .button {
        opacity: 1;
        transition-delay: 0.4s;
        position: absolute;
      }

      .search-input {
        opacity: 1;
        transition-delay: 0.2s;
      }

      #close-search {
        opacity: 1;
        right: rem-calc(10);
        transition: all 0.6s ease-in-out;
        transition-delay: 0.4s;
        z-index: 1;
      }

      .twitter-typeahead {
        display: inline-block !important;
        position: absolute !important; // Use !important to override element.style
        width: calc(100% - #{rem-calc(140)});
      }
    }
  }
}

/* Search Results Page */

.search-results {
  .search-form-container {
    margin: rem-calc(80) 0;

    @include breakpoint(small only) {
      margin: rem-calc(40) 0;

      [type='submit'] {
        width: 100%;
      }

      .close-link {
        position: absolute;
        top: rem-calc(3);
      }
    }
  }

  .search-result-container {
    .resultsCount {
      padding: rem-calc(30 20);
      margin-bottom: 0;
    }

    .result-inner {
      @include breakpoint(small only) {
        margin-left: 0;
        margin-right: rem-calc(80);
      }

      margin-left: rem-calc(64);
    }

    .page-type-icon {
      &::before {
        @include breakpoint(small only) {
          float: right;
          font-size: rem-calc(64);
        }

        font-family: Fidelity-Icon-Font !important;
        line-height: 1 !important;
        font-weight: 500;
        font-size: rem-calc(48);
        float: left;
      }

      &[data-page-type='djcmspage']::before {
        content: ' ';
      }

      &[data-page-type='article']::before {
        content: icon(newspaper);
      }

      &[data-page-type='pdf']::before {
        content: icon(pdf);
      }
    }
  }

  #fsearch {
    float: none;
    padding-right: 0;

    .search-wrapper {
      width: 100%;
      white-space: nowrap;

      > div {
        float: left;
        width: 87%;
      }

      .twitter-typeahead {
        margin-top: rem-calc(-4);
        width: 100%;
      }

      input {
        padding: 0 rem-calc(15);

        &[type='search'] {
          border-bottom: rem-calc(1) solid $grey-150;
          background: transparent;
        }
      }
    }
  }

  .tabs-content {
    @include breakpoint(small only) {
      border-width: 0;
    }
  }

  .result {
    border-bottom: rem-calc(1) solid $grey-100;

    h3 {
      margin-bottom: rem-calc(12);
    }
  }

  li {
    &::before {
      display: none;
    }
  }

  .tt-menu {
    margin-top: rem-calc(-18);
    border-color: $grey-75 !important;
    border-top-color: $white !important;
    box-shadow: none !important;
  }

  .tabs {
    .tabs-title > a {
      @include breakpoint(small only) {
        padding: 0 rem-calc(17.6);
      }

      padding: 0 rem-calc(60);
    }

    .tabs-title.is-active > a {
      border-top: rem-calc(3) solid $teal-400;
    }
  }

  .tabs-panel {
    padding: rem-calc(30 20 30 0);

    a {
      font-weight: bold;
    }
  }
}

@include breakpoint(small only) {
  #fsearch {
    width: 100%;
    float: none;
    height: auto;
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);

    .search-wrapper {
      padding: 0;

      .input-holder {
        .button {
          position: relative;
          width: 100%;
          display: none;
          left: 0;
          overflow: hidden;
        }

        .twitter-typeahead {
          width: calc(100% - #{rem-calc(82)});
        }
      }

      &.active {
        height: auto;

        .input-holder {
          width: 100%;
          margin-right: rem-calc(14);

          .search-input {
            min-width: 100%;
          }

          .button {
            display: block;
            width: calc(100% - #{rem-calc(20)});
          }
        }

        #close-search {
          top: rem-calc(18);
          right: rem-calc(-3);
        }
      }

      #close-search {
        top: rem-calc(18);
      }

      .close-link {
        margin-top: rem-calc(5);
      }
    }
  }
}

//tabs section - update to plugin

#tabList {
  margin-bottom: 0;

  li {
    background-color: color(grey-100);
    margin-bottom: rem-calc(-1);
    float: left;
    border-right: solid rem-calc(1) color(grey-300);
    border-top: solid rem-calc(1) color(grey-300);

    &:first-child {
      border-left: solid rem-calc(1) color(grey-300);
    }

    a {
      display: inline-block;
      text-align: center;
      padding: rem-calc(10 40);
      border-bottom: solid rem-calc(1) color(grey-300);

      &.isActive {
        background-color: $white;
        border-bottom: solid rem-calc(1) $white;
      }
    }

    @include breakpoint(small only) {
      @include grid-column(6);

      padding: 0;
      margin: 0;
      margin-bottom: rem-calc(-1);
      border: solid rem-calc(1) color(grey-300);

      a {
        display: block;
        border-bottom-style: none;

        &.isActive {
          background-color: $white;
          border-bottom-style: none;
        }
      }
    }
  }
}

.results-container {
  .result,
  h3,
  .wrap {
    width: 100%;

    @include breakpoint(small only) {
      padding: 0 rem-calc(5);
    }
  }

  border: solid rem-calc(1) color(grey-300);

  .noRecords {
    padding: rem-calc(30) 0 0;
    font-weight: bold;

    .countVal {
      color: red;
    }

    @include breakpoint(small only) {
      padding: rem-calc(10 10 0);
    }
  }
}

.row.tabs {
  margin-left: 0;
  padding-left: 0;
}

.search__template {
  display: none;
}


.greyed-out-links {
  color: $grey-500;
  pointer-events: none;
}

.normal-links {
  color: $blue-500;
  pointer-events: all;

  &:hover {
    color: $grey-900;
  }
}

.price-and-performance-banner {
  .message-box--info {
    min-height: rem-calc(120);
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
    padding: rem-calc(0);
    white-space: normal;
    padding-left: rem-calc(15);
  }

  .icon-message {
    position: absolute;
    height: 100%;
    width: 89%;
  }

  .banner-content {
    position: absolute;
    height: 100%;
    align-items: center;
    display: flex;
    white-space: nowrap;
    width: 100%;
  }
}
