ul,
ol {
  padding-left: 0;

  list-style: none;
}

ol {
  counter-reset: list-counter;
}

li {
  position: relative;

  margin-bottom: rem-calc(12);
  font-size: rem-calc(16);

  @include breakpoint(large up) {
    font-size: rem-calc(18);
    margin-bottom: rem-calc(16);
  }
  li > ul > &,
  li > ol > & {
    margin-bottom: rem-calc(4);

    @include breakpoint(medium up) {
      margin-bottom: rem-calc(8);
    }
    &:first-child {
      margin-top: rem-calc(4);

      @include breakpoint(medium up) {
        margin-top: rem-calc(8);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:before {
    position: absolute;
    top: rem-calc(7);
    left: rem-calc(-17);

    width: 6px;
    height: 6px;

    content: '';
    vertical-align: middle;

    background-color: $blue-500;

    @include breakpoint(medium up) {
      width: 8px;
      height: 8px;
    }

    ol & {
      top: 0;

      width: auto;
      height: auto;

      content: counter(list-counter) ".";
      counter-increment: list-counter;

      color: $blue-500;
      background-color: transparent;

      // Primary nested numbered items
      li > & {
        content: counter(list-counter, lower-alpha) ".";
        text-align:right;
        
        ol & {
          
          // Secondary nested numbered items
          li > & {
            content: counter(list-counter, lower-roman) ".";
            text-align:right;
          }
          
        }
        
      }
    }
    li > ul > & {
      background-color: $blue-300;
    }
  }
}

.no-bullet {
  & > li {
    &::before {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }
}