.fil-text {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  $root-class:&;

  .fil-text__body {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &.important-info {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: rem-calc($component-padding-base) !important;
    margin-bottom: rem-calc($component-padding-base);

    #{$root-class}__body > .cell {
      padding-top: rem-calc($component-padding-base);
      padding-bottom: rem-calc($component-padding-base);
      border-top: rem-calc(1) solid color(grey-100);
      border-bottom: rem-calc(1) solid color(grey-100);
    }

    + .important-info {
      border-top: none;
    }

    @at-root section.bg-grey-50 + & {
      margin-top: rem-calc($component-padding-base * 2) !important;
    }

    + section.bg-grey-50 {
      margin-top: rem-calc($component-padding-base * 2) !important;
    }
  }

  &-body-container {
    margin-bottom: 0;

    &.small {
      div,
      p,
      li {
        font-size: inherit;
      }
    }
  }

  table {
    display: block;
    width: 100% !important;
    overflow: hidden;

    th {
      p {
        margin: rem-calc(20 0)
      }
    }

    td {
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .table-scroll {
    width: 100%;
    overflow-x: auto;
    margin-bottom: rem-calc(40);

    table {
      display: table;
      width: 100% !important;
      margin: 0 auto;
      overflow: auto;
    }
  }
}
