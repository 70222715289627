/*
 * 	Fil theme - Owl Carousel
 */

$color-base: $blue !default;
$color-white: $white !default;
$color-gray: $grey-200 !default;

//nav

$nav-color: $color-white !default;
$nav-color-hover: $color-white !default;
$nav-font-size: rem-calc(20) !default;
$nav-margin: rem-calc(-15) !default;
$nav-padding: rem-calc(4) rem-calc(7) !default;
$nav-background: $blue !default;
$nav-background-hover: $color-base !default;
$nav-disabled-opacity: 0.5 !default;

//dots

$dot-width: rem-calc(8) !default;
$dot-height: rem-calc(8) !default;
$dot-rounded: rem-calc(30) !default;
$dot-margin: rem-calc(5) rem-calc(7) !default;
$dot-background: $color-gray !default;
$dot-background-active: $color-base !default;

.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    input[type='button'] {
      outline: none;
    }

    input[type='button']::-moz-focus-inner {
      border: 0;
    }

    *:focus {
      outline: 0 !important;
    }

    [class*='owl-'] {
      background: $nav-background !important;
      color: $nav-color !important;
      cursor: pointer;
      display: inline-block;
      font-size: $nav-font-size !important;
      font-weight: bold !important;
      height: rem-calc(50);
      margin: $nav-margin;
      padding: $nav-padding;
      width: rem-calc(50);

      &:hover {
        text-decoration: none;
      }
    }

    .disabled {
      display: none !important;
      opacity: $nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: rem-calc(10);
  }

  .owl-dots {
    text-align: left;
    -webkit-tap-highlight-color: transparent;
    input[type='button'] {
      outline: none;
    }

    input[type='button']::-moz-focus-inner {
      border: 0;
    }

    *:focus {
      outline: 0 !important;
    }

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $dot-width;
        height: $dot-height;
        margin: $dot-margin;
        background: $dot-background;
        display: block;
        backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: $dot-rounded;
      }

      &.active,
      &:hover {
        span {
          background: $dot-background-active;
        }
      }
    }
  }
}
