.fil-cta {
  padding-top: 0;
  padding-bottom: rem-calc($component-padding-base);

  .align-center {
    justify-content: center;
  }

  .button {
    margin: 0 rem-calc(8);
  }

  .arrow-right-cta {
    font-size: rem-calc(18);

    span {
      font-weight: bold;
    }
  }

  & + .fil-cta {
    padding-top: 0;
  }
}

.bg-grey-50 + .fil-cta.bg-grey-50 {
  margin-top: rem-calc(-$component-padding-base) !important;
}

a[target='_blank'] {
  span {
    display: none;
  }

  &::after {
    content: icon(external-link);
    position: relative;
    font-family: 'fidelity-icon-font';
    // At 18px, the icon must be 13px
    font-size: (13 / 18) * 1em;
    margin-left: rem-calc(10);
  }
}

@include breakpoint(small only) {
  .fil-cta {
    .shrink {
      width: 100%;
      text-align: center;

      &:last-of-type a {
        margin-bottom: 0;
      }

      a {
        width: 100%;
        margin: 0 0 rem-calc(20) 0;
      }
    }
  }
}
