button {
  cursor: pointer;
}
.button-group {
  .button,
  button {
    margin-right: 12px;
    margin-bottom: 12px;

    font-size: rem-calc(16);
  
    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Foundation styles
.button,
.button.primary {
  display: inline-block;

  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;

  cursor: pointer;
  transition: background-color .15s ease-out, color .15s ease-out,
  border-color .15s ease-out;
  white-space: nowrap;

  color: $green-400-contrast;
  background-color: $green-400;

  font-family: $neuzeit_grotesk;
  font-size: rem-calc(16);
  line-height: 1;

  &:hover {
    text-decoration: none;

    color: $green-300-contrast;
    background-color: $green-300;
  }
  &:focus {
    text-decoration: none;
    
    color: $green-400-contrast;
    background-color: $green-400;
    // outline: none;
  }
  &:active,
  &:focus:active {
    text-decoration: none;

    color: $green-600-contrast;
    // outline: none;
    background-color: $green-600;
  }
  &[disabled],
  &:disabled {
    cursor: not-allowed;

    opacity: 1;
    color: $white;
    background-color: $grey-200;
    &:hover {
      background-color: $grey-200;
    }
    &:focus {
      background-color: $grey-200;
    }
  }
}

.button.small {
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 9px;
  padding-left: 16px;

  font-size: rem-calc(13);
  line-height: 1;
}

.button.large {
  padding-top: 19px;
  padding-right: 32px;
  padding-bottom: 19px;
  padding-left: 32px;

  font-size: rem-calc(19);
  line-height: 1;
}

.button.secondary {
  color: $blue-contrast;
  background-color: $blue;
  &:hover {
    color: $blue-dark-contrast;
    background-color: $blue-dark;
  }
  &:focus {
    color: $blue-contrast;
    background-color: $blue;
  }
  &:active,
  &:focus:active {
    color: $blue-300-contrast;
    background-color: $blue-300;
  }
  &[disabled],
  &:disabled {
    cursor: not-allowed;

    opacity: 1;
    color: $white;
    background-color: $grey-200;
    &:hover {
      background-color: $grey-200;
    }
    &:focus {
      background-color: $grey-200;
    }
  }
}

.button.tertiary {
  color: $blue;
  border: 2px solid $blue;
  background: transparent;

  &.dark {
    color: $white;
    border-color: $white;
    background-color: transparent;

    &:hover,
    &:focus {
      color: $grey-700;
      background-color: $white;
    }

    &:focus:hover {
      color: $grey-700;
      background-color: $white;
    }

    &:active {
      opacity: .8;
      color: $grey-700;
      background-color: $white;
    }

    &:disabled,
    &[disabled] {
      opacity: .6;
    }
  }
  &:hover {
    color: $blue-contrast;
    background-color: $blue;
  }
  &:focus {
    color: $white;
    background-color: $blue;
  }
  &:active,
  &:focus:active {
    color: $blue-light-contrast;
    border: 2px solid $blue-light;
    background-color: $blue-light;
  }
  &:focus:hover {
    color: $blue-500-contrast;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;

    opacity: 1;
    color: $grey-200;
    border: 2px solid $grey-200;
    background-color: transparent;


    &:focus {
      background-color: transparent;
    }
  }
}

button,
.button,
.button.primary,
.button.secondary {
  &.dark {
    background-color: $white;
    
    &:focus,
    &:hover {
      opacity: .9;
      background-color: $white;
    }
    
    &:active {
      opacity: .8;
      background-color: $white;
    }

    &[disabled],
    &:disabled {
      opacity: .6;
    }
  }
}

.button.full-width {
  box-sizing: border-box;
  width: 100%;
  max-width: 280px;
}

.button.block {
  display: block;
}

button.fil-icon,
.button.fil-icon {
  &:before,
  &:after {
    vertical-align: middle;

    font-size: 24px;
  }

  &:after {
    margin-left: 14px;
  }
  &.fil-icon--left:before {
    margin-right: 14px;
  }
}

.button.icon-only {
  display: inline-block;

  padding: 12px;
  &:after {
    margin: 0;
  }
}