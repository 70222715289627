.glossary-navigation {
  > .cell {
    box-shadow: 0 0 rem-calc(4 2) rgba($grey-900, 0);
    transition: all 0.5s ease;
  }

  &.is-stuck {
    > .cell {
      box-shadow: 0 0 rem-calc(4 2) rgba($grey-900, 0.2);
      background-color: $white;
      transition: all 0.5s ease;

      .glossary-navigation_btn-group {
        border-bottom: rem-calc(1) solid rgba($grey-100, 0);
      }
    }
  }
}

.js-showhide .glossary-nav-container .accordion-item::after,
.js-showhide .glossary-nav-container .accordion-item.is-active::after {
  display: none;
}

.js-showhide .glossary-nav-container .accordion-item .fil-showhide__button {
  margin-top: rem-calc(8);
  border-top: none;
  margin-bottom: rem-calc(24);
}

.glossary-nav-container .fil-showhide__button {
  &.is-hidden {
    transform: scale(1, 0);
  }
}

// GLossary container base styles
.glossary-navigation_btn-group {
  width: 100%;
  padding: rem-calc(32 48);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7%, 1fr));
  grid-gap: rem-calc(5);
  border-top: rem-calc(1) solid $grey-100;
  border-bottom: rem-calc(1) solid rgba($grey-100, 1);
  background-color: $white;
}

// ie11 specific layout over-ride for non-grid support
_:-ms-fullscreen,
:root {
  .glossary-navigation_btn-group {
    display: table;

    .glossary-navigation_btn {
      display: table-cell;
      float: left;
      width: 7.5%;
    }
  }
}

// button group responsive
@include breakpoint(medium down) {
  .glossary-navigation_btn-group {
    padding-left: rem-calc(16);
    padding-right: rem-calc(16);
    grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));
  }

  _:-ms-fullscreen,
  :root {
    .glossary-navigation_btn-group {
      .glossary-navigation_btn {
        width: 14%;
      }
    }
  }
}

@include breakpoint(small only) {
  .glossary-navigation_btn-group {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(auto-fill, minmax(17%, 1fr));
  }

  _:-ms-fullscreen,
  :root {
    .glossary-navigation_btn-group {
      .glossary-navigation_btn {
        width: 20%;
      }
    }
  }

  .js-showhide .glossary-nav-container .accordion-item .fil-showhide__button {
    display: block;
    width: 100%;
    height: rem-calc(56);
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    padding-right: 0;

    span {
      float: none;
    }

    .fil-icon-container {
      display: inline-block;
      position: relative;
      top: rem-calc(3.2);
      margin-left: 0;
      width: rem-calc(16);
      right: 0;
    }

    &.is-active {
      .fil-icon-container {
        top: rem-calc(8);
      }
    }
  }
}

$glossary-button-base-scale: 0.9;

// glossary button default styles
.glossary-navigation_btn {
  position: relative;
  border: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: rem-calc(8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: rem-calc(1) solid $grey-200;
    transform: scale($glossary-button-base-scale);

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  // glossary button default DISABLED styles
  &.disabled,
  &[disabled] {
    pointer-events: none;

    span {
      color: $grey-100;
      border: rem-calc(1) solid $grey-100;
      transform: scale($glossary-button-base-scale - 0.1);
    }
  }
}

// glossary button ACTIVE styles
.is-active.glossary-navigation_btn {
  span {
    color: $white;
    background-color: $blue-500;
  }

  // glossary button ACTIVE DISABLED styles
  &.disabled,
  &[disabled] {
    span {
      color: $white;
      background-color: $grey-100;
    }
  }
}

// glossary button HOVER styles
@include breakpoint(medium) {
  .glossary-navigation_btn {
    &:hover span {
      color: $white;
      background-color: $blue-500;
    }
  }
}

$glossary-target-bg-color: $blue-50;

@keyframes target-bg-fade {
  0% {
    background-color: rgba($glossary-target-bg-color, 0);
  }

  10% {
    background-color: rgba($glossary-target-bg-color, 1);
  }

  100% {
    background-color: rgba($glossary-target-bg-color, 0);
  }
}

.glossary-description-section {
  position: relative;
  white-space: normal;

  &:target,
  :target {
    animation-name: target-bg-fade;
    animation-timing-function: ease-out;
    animation-duration: 2.5s;
  }

  h2 {
    font-size: rem-calc(70);
    margin-top: rem-calc(48);
    margin-bottom: rem-calc(16);
  }

  h3 {
    margin-bottom: rem-calc(16);
  }

  &:last-child {
    dl,
    p {
      margin-bottom: 0;
    }
  }
}
