.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}