.fil-house-view-table {
  &__view-column {
    padding-right: rem-calc(16);

    @include breakpoint(large) {
      padding-left: rem-calc(48);
    }
  }

  &__change-column {
    padding-left: rem-calc(0);
  }
}

