.tabs {
  width: 100%;

  white-space: nowrap;
}
.tabs-title {
  display: block;
  float: none;

  height: 48px;
  margin-bottom: 0;

  text-align: left;

  border-top: 1px solid $grey-200;
  border-right: 1px solid $grey-200;
  border-left: 1px solid $grey-200;
  background-color: $grey-25;

  @include breakpoint(medium up) {
    text-align: center;
    display: inline-block;

    vertical-align: bottom;

    border-top: 1px solid $grey-200;
    border-right: 1px solid $grey-200;
    border-left: 0;
  }

  > a {
    overflow: hidden;

    padding-top: 0;
    padding-right: 12px;
    padding-bottom: 0;
    padding-left: 12px;

    transition: background-color .15s ease-out;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: $blue-700;

    font-size: rem-calc(16);
    line-height: 47px;

    @include breakpoint(large up) {
      padding-right: 24px;
      padding-left: 24px;

      font-size: rem-calc(18);
    }

    &:hover {
      background-color: $blue-50;
    }
  }
  &:first-child {
    border-left: 1px solid $grey-200;
  }
  a:hover,
  a:focus {
    text-decoration: none;

    color: inherit;
  }
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 0;
    height: 100%;

    content: '';
    transition: height .15s ease-out, width .15s ease-out;
    pointer-events: none;

    background-color: $teal-300;

    @include breakpoint( medium up ) {
      top: 0;
      left: 0;

      width: 100%;
      height: 0;      
    }
  }
  &.is-active {
    position: relative;
    > a {
      text-overflow: initial;

      color: $grey-800;
      background-color:$white;
    }
    &:after {
      position: absolute;
      bottom: -1px;
      left: 0;

      width: 100%;
      height: 1px;

      content: '';

      background-color:$white;
    }
    &:before {
      width: 4px;

      @include breakpoint( medium up ) {
        width: 100%;
        height: 4px;
      }
    }
  }
}

.tabs-title:last-child.is-active {
  border-bottom: 1px solid $grey-200;

  @include breakpoint( medium up ) {
    border-bottom: none;
  }
}

.tabs {
  border: none;
  background-color: transparent;
}

.tabs-content {
  border: 1px solid $grey-200;
}

.tabs-panel {
  padding-top: 40px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-left: 30px;
}
