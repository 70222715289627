.fil-article-disclaimer {
  // similar styling to site-wide disclaimer

  padding: rem-calc(32 0 8);
  color: $grey-600;

  &__title {
    margin-bottom: rem-calc(28);
  }

  p {
    margin-bottom: rem-calc(24);
  }

  @include breakpoint(small only) {
    padding: rem-calc(56 0 32);
  }

  &-link {
    .fil-icon {
      top: rem-calc(2);
      position: relative;
      margin-right: rem-calc(12);
    }

    &.small {
      margin-bottom: 0;

      @include breakpoint(small only) {
        font-size: rem-calc(15);
        text-align: center;
        margin-bottom: rem-calc(24);
      }
    }
  }
}