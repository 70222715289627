.editorial {
  &.fil-edition-article-list {

    $gutter: rem-calc(24);
    $avatar_thumbnail_width: 832;
    $avatar_thumbnail_height: 500;
    $article-image-medium-height: 112;

    @mixin fil-article-card-list {
      .fil-edition-article-list {
        &__card {
          @include card-container($grey-25, $grey-900, $gutter, 0, 0, 0);

          border-radius: rem-calc(5);
          display: block;

          h4:hover {
            color: $blue;
          }

          p {
            font-size: rem-calc(16);
            color: $grey-600;
          }
        }

        &__card-image {
          width: 100%;

          @include breakpoint(medium up) {
            height: rem-calc($article-image-medium-height);
          }
        }

        &__tag {
          font-weight: bold;
          color: $blue;
          margin-bottom: rem-calc(8);
        }

        &__authors {
          font-weight: bold;
          margin-bottom: rem-calc(0);
        }

        &__subcategory-title-section {
          @include card-section;

          margin-bottom: rem-calc(16);

          &:last-child {
            @include breakpoint(medium up) {
              margin-bottom: 0;
            }

            padding-bottom: 0;
          }

          h4 {
            font-weight: bold;
            margin-bottom: rem-calc(16);
          }

          display: block;
        }

        &__image-subcategory-title-section {
          @include breakpoint(medium up) {
            min-height: rem-calc($article-image-medium-height + 24);
          }
        }
      }
    }

    .category {
      a {
        color: $orange-700;
        display: inline-block;
        font-size: rem-calc(18);
        font-weight: 700;
        margin-bottom: $gutter/2;
      }

      .readtime {
        color: $grey-800;
      }
    }

    .article-category-name {
      color: $blue;
    }

    .fil-edition-article-list__card {
      .reading-time {
        font-weight: normal;
        display: inline-block;
        margin-left: rem-calc(14);
        margin-bottom: 0;

        .fil-icon {
          font-size: rem-calc(16);
          font-weight: normal;
          margin-right: rem-calc(4);
        }
      }

      .bg-image__container {
        margin-left: rem-calc(30);
        position: relative;
        width: rem-calc(300);

        .article__bg-image {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: rem-calc(160);
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }

    .author {
      a {
        display: inline-block;
        font-weight: bold;
        color: $grey-500;

        &:hover {
          color: $blue-500;
        }
      }
    }

    &--outlook,
    &--teaser {
      padding: 0;

      > h4 {
        margin-bottom: $gutter;
      }

      @include fil-article-card-list;

    }

    &--teaser {
      display: flex;
      flex-wrap: wrap;
      margin-left: -($gutter/2);
      margin-right: -($gutter/2);

      .fil-edition-article-list {
        &__card {
          display: flex;
          flex-direction: column;
          margin: $gutter/2;
          flex: 1 0 80%;

          @include breakpoint(medium only) {
            flex: 1 0 50%;
            max-width: calc((100% / 2) - #{$gutter});
          }

          @include breakpoint(medium up) {
            padding-left: rem-calc(8);
            padding-right: rem-calc(8);
            margin-bottom: 0;
            margin-top: 0;
          }

          @include breakpoint(large) {
            flex: 1 0 30%;
            max-width: calc((100% / 3) - #{$gutter});
          }

          background-color: transparent;
          border-radius: 0;
          border-bottom-style: solid;
          border-bottom-width: rem-calc(1);
          border-bottom-color: $grey-100;
        }

        &__card-image {
          object-fit: cover;

          &--avatar {
            @include breakpoint(small only) {
              width: 60%;
            }

            margin: 0;
            object-fit: contain;
          }
        }

        &__card-image-container {
          max-height: 0;
          position: relative;
          text-align: center;

          @include breakpoint(small only) {
            padding-bottom: 100% * $avatar_thumbnail_height / $avatar_thumbnail_width; //thumbnail image sizes to calculate container ratio
            overflow: hidden;
          }

          @include breakpoint(medium up) {
            padding-top: rem-calc(24);
          }
        }

        &__subcategory-title-section {
          .fil-edition-article-list__authors {
            margin-top: auto;

            span {
              font-weight: normal;
            }
          }
        }

        &__tag {
          color: $orange-700;
          display: inline-block;
          font-size: rem-calc(18);
          font-weight: 700;
        }

        &__seo-description {
          padding: rem-calc(16 0);

          @include breakpoint(small down) {
            display: none;
          }
        }

        &__article-category-link {
          @include breakpoint(medium down) {
            font-size: rem-calc(16);
          }
        }

        &__article-category-link,
        &__article-title {
          font-weight: bold;

          @include breakpoint(medium up) {
            font-size: rem-calc(20);
          }
        }

        &__article-title {
          display: block;
          color: $grey-900;

          @include breakpoint(medium down) {
            font-size: rem-calc(23);
          }

          &:hover {
            color: $blue-400;
          }
        }
      }
    }
  }

  .fil-article-grid__articles-container {
    $articles-grid-horizontal-padding: 48;

    @include breakpoint(medium up) {
      padding-left: rem-calc($articles-grid-horizontal-padding);
      padding-right: rem-calc($articles-grid-horizontal-padding);
    }
  }
}

