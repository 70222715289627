@import './fil-tab-accordion';

.fil-tabs {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .tabs {
    position: relative;
    z-index: 30;
    text-align: center;
    border: none;

    a:focus {
      outline: none;
    }

    &.tab-links {
      border-bottom: rem-calc(1) solid $grey-200;
    }

    .tabs-title {
      float: none;

      @include breakpoint(medium) {
        border-right: thin solid $grey-200;
      }

      &:first-child {
        border-left: thin solid $grey-200;
      }

      a {
        font-size: rem-calc(16);
        padding: rem-calc(0 24);
        line-height: rem-calc(47);
      }
    }
  }

  .tabs-content {
    overflow: hidden;
    position: relative;
    transform-origin: top;
    white-space: normal;

    .tabs-panel {
      width: 100%;
      opacity: 0;
      z-index: 10;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: top;
      transition: opacity 0.1s ease;

      > section:last-of-type {
        padding-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.is-active {
        opacity: 1;
        z-index: 20;
        display: block;
        position: relative;
        transition: opacity 0.1s ease 0.4s;
      }

      section:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.full {
    overflow: hidden;

    .tabs-content {
      border-left-width: 0;
      border-right-width: 0;
      background: none;
      border-bottom: none;

      .tabs-panel {
        padding-left: 0;
        padding-right: 0;

        .bg-grey-50 {
          position: relative;

          &::before,
          &::after {
            content: ' ';
            background-color: $grey-50;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
          }

          &::before {
            left: -100%;
          }

          &::after {
            left: 100%;
          }
        }
      }
    }
  }
}

.fil-tabs .tabs-clone .tabs-panel {
  position: relative;
}

.fil-tabs .tabs-clone-extra {
  position: relative;

  .tabs-panel {
    position: relative;
  }
}
