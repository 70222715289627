@import "./color-list";

@each $colorName, $colorVal in $color-defs {

  .bg-#{$colorName} {
    background-color: map-get($colorVal, 'main');
    color: map-get($colorVal, 'contrast');
  }

  .text-#{$colorName} {
    color: map-get($colorVal, 'main');
  }
}