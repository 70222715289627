.fil-share-links {
  position: relative;
  display: inline-block;

  &__share-button {
    color: $blue-500;
    cursor: pointer;

    &:hover {
      color: $grey-900;
    }
  }

  &__share-button-text {
    margin-bottom: 0;
  }

  &__share-icon {
    margin-left: rem-calc(6);
  }

  &__dropdown-container {
    position: relative;
    z-index: 100;
  }

  &__dropdown {
    position: absolute;
    top: rem-calc(16);
    left: 0;
    box-shadow: 0 rem-calc(2) rem-calc(6) 0 rgba(0, 0, 0, 0.25);
    border-radius: rem-calc(4);
    background: $white;
    min-width: rem-calc(250);
    display: none;
  }

  &__dropdown-title-container {
    border-bottom: rem-calc(1) solid $grey-100;
    padding: rem-calc(16 24);
  }

  &__dropdown-title {
    margin-bottom: 0;
    color: $grey-800;
  }

  &__share-link {
    padding: rem-calc(12 16);
    text-decoration: none;
    color: $grey-800;
    display: block;

    &:hover,
    &:active,
    &:focus {
      background-color: $grey-50;
    }

    &::after {
      display: none;
    }
  }

  &__share-link-icon {
    margin-left: rem-calc(8);
    margin-right: rem-calc(16);
  }

  &__share-link-text {
    margin-bottom: 0;
  }

  @include breakpoint(small only) {
    &__dropdown-container {
      position: fixed;
      text-align: center;
      top: 50%;
      width: 100%;
      left: 50%;
      margin-left: -50%;
      transform: translateY(-50%);
    }

    &__dropdown {
      position: static;
      text-align: left;
    }
  }

  &--open {
    .fil-share-links__share-button {
      color: $grey-900;
    }

    .fil-share-links__dropdown {
      display: initial;

      @include breakpoint(small only) {
        display: inline-block;
      }
    }
  }
}