.fil-key-stats {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);

  .key-stat {
    text-align: center;
    padding: 0 rem-calc(20);
    border-right: rem-calc(1) solid $grey-100;

    .above {
      margin-bottom: rem-calc(5);
    }

    .figure {
      display: block;
      font-size: rem-calc(80);
      color: $blue-500;
      margin-bottom: rem-calc(20);
    }

    .below {
      margin-bottom: 0;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  &.bg-grey-50 {
    .key-stat {
      border-right: rem-calc(1) solid $grey-200;

      &:last-of-type {
        border-right: none;
      }
    }
  }
}

@include breakpoint(medium only) {
  .fil-key-stats {
    .key-stat {
      .figure {
        font-size: rem-calc(60);
      }
    }
  }
}

@include breakpoint(small only) {
  .fil-key-stats {
    .key-stat {
      border-right: none;
      border-bottom: rem-calc(1) solid $grey-100;

      .above {
        padding-top: rem-calc(20);
      }

      .below {
        padding-bottom: rem-calc(20);
      }

      &:last-of-type {
        border-bottom: none;

        .below {
          padding-bottom: 0;
        }
      }
    }

    &.bg-grey-50 {
      .key-stat {
        border-bottom: rem-calc(1) solid $grey-200;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
