.global-section {
  background-color: $grey-600;
  min-height: rem-calc(40);

  p {
    margin-bottom: 0;
    font-size: rem-calc(16);
  }

  .fil-icon {
    &::after,
    &::before {
      line-height: 0;
      padding-left: rem-calc(5);
      vertical-align: middle;
    }

    &::before {
      margin-right: rem-calc(5);
      vertical-align: middle;
      font-size: rem-calc(22);
    }
  }

  a,
  .nav-label {
    color: $white;
    font-size: rem-calc(15);
    padding: rem-calc(7.5 20);
    display: inline-block;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }

  .nav-label {
    font-weight: bold;
  }

  .wfb-nav {
    .link {
      &.disabled {
        color: $grey-200;
        pointer-events: none;
      }
    }
  }

  .wayfinding-text-left {
    .link {
      border-right: rem-calc(1) solid $grey-400;

      @include breakpoint ( medium down ) {
        padding: rem-calc(20);
        border-bottom: rem-calc(1) solid $grey-400;
        width: 100%;
      }
    }

    ul.text-left {
      position: relative;
      padding-right: rem-calc(15);

      @include breakpoint ( medium down ) {
        padding-right: 0;
        width: 100%;
      }

      ul {
        padding-right: 0;
        width: 100%;
        min-width: rem-calc(264);
      }
    }

  }

  .text-right {
    font-size: 0;

    a {
      border-left: rem-calc(1) solid $grey-400;
      line-height: rem-calc(20);
      padding: rem-calc(10 32);

      @include breakpoint(large only) {
        padding: rem-calc(10 25);
      }
    }

    .fil-icon-login {
      background: url($login-icon) no-repeat;
      display: inline-block;
      width: rem-calc(22);
      height: rem-calc(19);
      vertical-align: middle;
      margin-right: rem-calc(8);
    }

    .fil-icon-logout {
      background: url($logout-icon) no-repeat;
      display: inline-block;
      width: rem-calc(22);
      height: rem-calc(20);
      vertical-align: middle;
      margin-right: rem-calc(8);
    }

    .private {
      display: none;
      border-left: none;

      &.bg-blue {
        border-left: rem-calc(1) solid $blue-400;
      }

      &.my-fid {
        border-right: rem-calc(1) solid $blue-300 !important;
      }
    }

    .public {
      &.bg-blue {
        border-left: rem-calc(1) solid $blue-400;
      }
    }

    &.logged-in {
      .private {
        display: inline-block;
      }

      .public {
        display: none;
      }
    }
  }

  ul,
  li {
    margin: 0;
  }

  li::before {
    display: none;
  }

  .language-selector-container {
    display: inline-block;
  }

  .personal-investing-tab,
  language-selector-tab {
    z-index: 999;
    padding-top: rem-calc(2);
    padding-bottom: rem-calc(2);

    a {
      display: inline-block;
      color: $white;
      position: relative;

      &::after {
        color: $white;
        display: inline-block;
        left: 100%;
        line-height: inherit;
        margin-left: rem-calc(-20);
        padding-left: rem-calc(5);
        position: absolute;
        top: rem-calc(8);
        transition: transform 0.4s ease-in-out;
        vertical-align: middle;
      }
    }
  }

  .sub-menu,
  .language-selector-sub-menu {
    z-index: 999;
    transition: all 0.3s ease-in-out;
    background-color: $grey-600;
    position: absolute;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    min-width: rem-calc(150);

    li {
      &:hover,
      &:active {
        a {
          background-color: $grey-dark !important;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: rem-calc(3);
            height: rem-calc(51);
            left: 0;
            top: 0;
            background-color: $blue-200;
          }
        }
      }
    }
  }

  .language-selector-sub-menu {
    a {
      display: block;
    }

    li:hover a::before {
      height: 100%;
    }
  }

  .fil-multi-dropdown {
    display: inline-block;
    position: relative;

    li {
      &.fil-multi-dropdown-parent {
        position: relative;

        &.dropdown-open {
          background-color: $grey-25;

          a {
            color: $grey-900;

            &::after {
              color: $blue;
              transform: rotateX(180deg);
            }
          }
        }
      }

      a {
        padding: rem-calc(10) rem-calc(55);
      }
    }

    .backdrop {
      content: '';
      z-index: 9;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      transition: background-color 0.2s ease;
      background-color: transparent;
    }

    .dropdown-active ~ .backdrop {
      height: 100vh;
    }

    .fil-multi-dropdown-submenu {
      position: absolute;
      z-index: 100;
      display: none;
      min-width: rem-calc(200);
      background: $grey-25;
      padding: rem-calc(10 6);

      &.dropdown-active {
        display: block;
      }

      &.first-sub {
        border: rem-calc(1) solid $grey-50;
        right: 0;

        &.is-sub-opened {
          left: -190%;

          > li {
            width: 50%;
          }
        }

        &.dropdown-right {
          right: 0;
        }
      }

      li {
        a {
          color: $blue;
          padding: rem-calc(10);
          border-left: none;
          width: 100%;
          white-space: nowrap;

          &:hover {
            color: $grey-900;
          }

          &.cur-active-sub-level {
            .fil-icon::after {
              color: $blue-500;
            }
          }

          .fil-icon::after {
            color: $grey-300;
          }
        }
      }

      .dropdown-submenu-item {
        padding: rem-calc(10);
      }

      .dropdown-right-arrow {
        padding: 0 rem-calc(10);
        position: relative;

        span {
          &::after {
            color: $grey-300;
          }
        }

        &.dropdown-open {
          span {
            &::after {
              color: $blue;
              content: icon(chevron-left-s);
            }
          }
        }
      }

      &.fil-multi-dropdown-innermenu {
        top: 0;
        left: 50%;
        right: 0;
        border-left: rem-calc(1) solid $grey-50;
      }
    }

    .fil-multi-dropdown-parent-element {
      a {
        &::before {
          content: icon(person);
          font-family: 'fidelity-icon-font';
          vertical-align: middle;
          position: absolute;
          left: 15%;
          top: rem-calc(8);
          line-height: inherit;
          padding-right: rem-calc(19);
          margin-right: rem-calc(18);
        }

        &::after {
          position: absolute;
          left: 70%;
          color: $white;
          top: rem-calc(8);
          font-size: rem-calc(16);
          line-height: inherit;
          transition: transform 0.4s ease-in-out;
          vertical-align: middle;
        }
      }
    }
  }

  .sub-menu {
    left: 0;

    a {
      padding: rem-calc(15 20);
      width: 100%;
    }
  }

  .is-open {
    + .sub-menu,
    + .language-selector-sub-menu {
      max-height: rem-calc(999);
      opacity: 1;
      transform: none;
    }

    .sub-menu-trigger::after {
      transform: rotateX(180deg);
    }
  }

  &__mobile-message {
    background-color: $grey-600;
    height: rem-calc(40);
    color: $white;
    font-size: rem-calc(15);
    padding: rem-calc(10 20);
    font-weight: bold;
  }
}

.top-section {
  border-bottom: rem-calc(1) solid color(grey-50);

  .header-logo {
    max-width: rem-calc(170);
    margin: rem-calc(16) 0;
  }

  .menu-toggle {
    margin-top: rem-calc(32);
    margin-right: rem-calc(29);
    float: right;
    position: relative;

    &::after {
      font-family: 'fidelity-icon-font' !important;
      content: icon(chevron-down-s);
      position: absolute;
      border: none;
      display: inline-block;
      color: $grey-200;
      transition: transform 0.3s ease;
      padding: 0;
      height: auto;
      top: rem-calc(-5);
      font-size: rem-calc(24);
      left: 110%;
    }

    &.is-open {
      &::after {
        transform: rotateX(180deg);
      }
    }
  }

  .header-search {
    float: right;
    margin-top: rem-calc(32);

    &__label {
      color: $grey-700;

      &::before {
        font-family: 'fidelity-icon-font' !important;
        content: icon(search);
        padding-right: rem-calc(8);
      }
    }
  }

  // TODO: replace ID selector
  #fsearch { /* stylelint-disable-line selector-max-specificity */
    margin-top: rem-calc(11);
  }
}

@include breakpoint(medium down) {
  .search-col {
    border-top: rem-calc(1) solid $grey-40;
  }

  .global-section {
    display: none;

    .personal-investing-tab {
      padding: rem-calc(20) 0;
      border-bottom: rem-calc(1) solid $grey-400;
      z-index: 9;

      a {
        padding: 0 rem-calc(20);

        &::after {
          top: 0;
        }
      }
    }

    .language-selector-container {
      display: block;
    }

    .language-selector-sub-menu {
      position: relative;
    }

    .sub-menu {
      width: 100%;

      li {
        border-bottom: rem-calc(1) solid $grey-400;

        a {
          padding: rem-calc(20);

          &:active {
            &::before {
              height: rem-calc(61);
            }
          }
        }
      }
    }

    .wfb-nav {
      order: 1;

      a {
        width: 100%;
        text-align: left;
        padding: rem-calc(20);
        border-bottom: rem-calc(1) solid $grey-400;
      }

      .login,
      .logout,
      .fil-multi-dropdown {
        display: none !important;
      }
    }
  }
}

.global-section-jp {
  min-height: rem-calc(44);

  a {
    line-height: rem-calc(24) !important;
  }
}
