@keyframes spinningZ {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(1turn);
  }
}

$spin-duration: 0.5s;
$outro-duration: 0.3s;
$panelTranslate: rem-calc(1000);

.loading-spinner {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  background: $white;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 1;

  .loading-spinner_inner {
    position: relative;
    width: 30%;
    height: 30%;
    max-width: rem-calc(200);
    max-height: rem-calc(200);
    transform: translateX($panelTranslate * -1);
    opacity: 0;

    &::after {
      display: block;
      content: ' ';
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border-top: rem-calc(20) solid $blue-500;
      border-right: rem-calc(20) solid $blue-500;
      border-bottom: rem-calc(20) solid $blue-500;
      border-left: rem-calc(20) solid transparent;
      animation-name: spinningZ;
      animation-duration: $spin-duration;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.is-intro {
    .loading-spinner_inner {
      opacity: 1;
      transform: translateX(0);
      transition: transform $outro-duration ease-out, opacity $outro-duration/1.5 linear;
    }
  }

  &.is-outro {
    opacity: 0;
    transition: opacity $outro-duration/1.5 linear;

    .loading-spinner_inner {
      opacity: 1;
      transform: translateX($panelTranslate);
      transition: transform $outro-duration ease-in;
    }
  }
}

/* stylelint-disable selector-max-specificity */
// TODO: replace ID selector
#select50-results {
  > section .section-inner {
    opacity: 0;
    transform: translateX($panelTranslate * -1);
  }

  &.results-loaded {
    > section .section-inner {
      opacity: 1;
      transform: translateX(0);
      transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    }
  }
}
/* stylelint-enable */
