$staticPrefix: '/static/' !default;
$no-video: '#{$staticPrefix}fil_site_assets/images/no-video.png' !default;

.fil-video {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .video-empty {
    .kv-empty {
      background: url($no-video) no-repeat 50%;
      padding: 0;
      cursor: not-allowed;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100% !important;
    }
  }

  .video-player-container {
    &.youtube {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: rem-calc(25);
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .text-container {
    p {
      margin-bottom: 0;
    }
  }
}

.accordion-content {

  .kv-viewer {
    @media screen and (max-width: rem-calc(320)) {
      width: rem-calc(233.328) !important;
      height: rem-calc(131) !important;
    }

    @media screen and (min-width: rem-calc(321)) and (max-width: rem-calc(375)) {
      width: rem-calc(279.156) !important;
      height: rem-calc(157) !important;
    }

    @media screen and (min-width: rem-calc(376)) and (max-width: rem-calc(425)) {
      width: rem-calc(320.828) !important;
      height: rem-calc(180) !important;
    }

    @media screen and (min-width: rem-calc(426)) and (max-width: rem-calc(550)) {
      width: rem-calc(360) !important;
      height: rem-calc(203) !important;
    }

    @media screen and (min-width: rem-calc(551)) and (max-width: rem-calc(768)) {
      width: rem-calc(515) !important;
      height: rem-calc(290) !important;
    }

    @media screen and (min-width: rem-calc(769)) and (max-width: rem-calc(1024)) {
      width: rem-calc(615) !important;
      height: rem-calc(346) !important;
    }

    @media screen and (min-width: rem-calc(1025)) and (max-width: rem-calc(1440)) {
      width: rem-calc(828.328) !important;
      height: rem-calc(466) !important;
    }

    @media screen and (min-width: rem-calc(1441)) and (max-width: rem-calc(2560)) {
      width: rem-calc(975) !important;
      height: rem-calc(548) !important;
    }

    @media screen and (min-width: rem-calc(2561)) {
      width: rem-calc(975) !important;
      height: rem-calc(548) !important;
    }

    .kv-thumbnail-holder {
      @media screen and (max-width: rem-calc(320)) {
        width: rem-calc(233.328) !important;
        height: rem-calc(131) !important;
      }

      @media screen and (min-width: rem-calc(321)) and (max-width: rem-calc(375)) {
        width: rem-calc(279.156) !important;
        height: rem-calc(157) !important;
      }

      @media screen and (min-width: rem-calc(376)) and (max-width: rem-calc(425)) {
        width: rem-calc(320.828) !important;
        height: rem-calc(180) !important;
      }

      @media screen and (min-width: rem-calc(426)) and (max-width: rem-calc(550)) {
        width: rem-calc(360) !important;
        height: rem-calc(203) !important;
      }

      @media screen and (min-width: rem-calc(551)) and (max-width: rem-calc(768)) {
        width: rem-calc(515) !important;
        height: rem-calc(290) !important;
      }

      @media screen and (min-width: rem-calc(769)) and (max-width: rem-calc(1024)) {
        width: rem-calc(615) !important;
        height: rem-calc(346) !important;
      }

      @media screen and (min-width: rem-calc(1025)) and (max-width: rem-calc(1440)) {
        width: rem-calc(828.328) !important;
        height: rem-calc(466) !important;
      }

      @media screen and (min-width: rem-calc(1441)) and (max-width: rem-calc(2560)) {
        width: rem-calc(975) !important;
        height: rem-calc(548) !important;
      }

      @media screen and (min-width: rem-calc(2561)) {
        width: rem-calc(975) !important;
        height: rem-calc(548) !important;
      }

      .kv-thumbnail {
        @media screen and (max-width: rem-calc(320)) {
          width: rem-calc(233.328) !important;
          height: rem-calc(131) !important;
        }

        @media screen and (min-width: rem-calc(321)) and (max-width: rem-calc(375)) {
          width: rem-calc(279.156) !important;
          height: rem-calc(157) !important;
        }

        @media screen and (min-width: rem-calc(376)) and (max-width: rem-calc(425)) {
          width: rem-calc(320.828) !important;
          height: rem-calc(180) !important;
        }

        @media screen and (min-width: rem-calc(426)) and (max-width: rem-calc(550)) {
          width: rem-calc(360) !important;
          height: rem-calc(203) !important;
        }

        @media screen and (min-width: rem-calc(551)) and (max-width: rem-calc(768)) {
          width: rem-calc(515) !important;
          height: rem-calc(290) !important;
        }

        @media screen and (min-width: rem-calc(769)) and (max-width: rem-calc(1024)) {
          width: rem-calc(615) !important;
          height: rem-calc(346) !important;
        }

        @media screen and (min-width: rem-calc(1025)) and (max-width: rem-calc(1440)) {
          width: rem-calc(828.328) !important;
          height: rem-calc(466) !important;
        }

        @media screen and (min-width: rem-calc(1441)) and (max-width: rem-calc(2560)) {
          width: rem-calc(975) !important;
          height: rem-calc(548) !important;
        }

        @media screen and (min-width: rem-calc(2561)) {
          width: rem-calc(975) !important;
          height: rem-calc(548) !important;
        }
      }
    }
  }
}
