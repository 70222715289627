.fil-overview {
  &__heading {
    font-weight: bold;
    margin-bottom: rem-calc($outlook-margin-small);
  }

  &__whats-changed,
  &__key-takeaways,
  &__investment-implications {
    margin-bottom: rem-calc($outlook-margin-large);
  }

  &__key-takeaways {
    /*
    Lists may be entered as multiple UL elements.
    Remove the padding between each UL, then re-add it underneath the Key Takeaways.
    */
    margin-bottom: rem-calc(40);

    ul {
      margin-bottom: 0;
    }
  }

  &__investment-implications {
    border: solid rem-calc(2) $blue-50;
    padding: rem-calc(16) rem-calc(24);

    p {
      font-size: rem-calc(16);
      margin-bottom: rem-calc(8);
    }
  }

  @include breakpoint(medium down) {
    &__whats-changed,
    &__key-takeaways,
    &__investment-implications {
      margin-bottom: rem-calc($outlook-margin-medium);
    }

    p {
      margin-bottom: rem-calc($outlook-margin-medium);
    }
  }
}
