.select50-overlay {
  padding: 0;
  // Mirror spacing above overlay - seems to have no effect on IE11...
  margin-bottom: 10vh;

  .overlay-section {
    &:first-of-type {
      padding: rem-calc(32);
    }
  }

  .select50-overlay_carousel {
    .slide {
      .slide-container {
        padding: 0 rem-calc(16);

        @include breakpoint(medium) {
          padding: 0 rem-calc(64);
        }
      }
    }
  }
}

.broker-img-list {
  padding-bottom: rem-calc(32);

  a {
    display: block;
    margin-left: rem-calc(20);
    max-height: rem-calc(100);
    max-width: rem-calc(100);

    img {
      height: auto;
      width: 100%;
    }
  }
}
