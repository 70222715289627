@import './fil-owl-theme';
$component-padding-bottom-grey: $component-padding-base * 2;

.fil-carousel {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  &.bg-grey-50 {
    padding-bottom: rem-calc($component-padding-bottom-grey) !important;
  }

  @include breakpoint(small only) {
    padding-bottom: rem-calc($component-padding-base);

    &.bg-grey-50 {
      padding-bottom: rem-calc($component-padding-bottom-grey) !important;
    }
  }

  .owl-nav {
    height: rem-calc(0.05);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 10;

    @include breakpoint(small only) {
      left: rem-calc(340);
      top: rem-calc(280);
      width: rem-calc(50);
    }

    @include breakpoint(medium only) {
      width: 93%;
    }

    .owl-prev {
      float: left;

      @include breakpoint(small only) {
        margin: rem-calc(55 -50 0 0);
      }
    }

    .owl-next {
      float: right;

      @include breakpoint(small only) {
        margin: rem-calc(4 0 5 0);
      }
    }
  }

  .owl-carousel {
    position: relative;
    padding: 0 rem-calc(15);
  }

  .owl-dots {
    //Temporarily disabling dots until solution decided
    display: none;
    position: absolute;
    top: calc(50% - #{rem-calc(124)});
    left: rem-calc(90);

    @include breakpoint(small only) {
      top: rem-calc(218);
      left: rem-calc(14);
    }

    .owl-dot {
      margin: 0 rem-calc(5);
      vertical-align: middle;
    }
  }

  .owl-stage {
    transition: 0.5s !important;

    h2 {
      color: inherit;
      margin-bottom: rem-calc(24);

      @include breakpoint(small only) {
        font-size: rem-calc(33);
        margin-top: rem-calc(50);
      }
    }
  }

  .arrow-right-cta {
    span {
      font-weight: bold;
      position: relative;
    }
  }

  .fil-play-icon {
    height: rem-calc(80);
    width: rem-calc(80);

    span {
      color: $white;
      font-size: rem-calc(45);
      left: 50% !important;
      margin-top: rem-calc(-35.5) !important;
      margin-left: rem-calc(-22.5);
      padding: 0 !important;
      position: absolute;
      top: 50% !important;
      z-index: 9999;
    }
  }

  .fil-image {
    position: relative;
    margin: 0;

    img {
      @include breakpoint(small only) {
        height: rem-calc(200);
        object-fit: cover;
      }
    }
  }
}
