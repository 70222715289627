@mixin defineSiteTheme($themeColor) {
  &.tier-one {
    &::before {
      background-color: $themeColor !important;
    }

    + .megamenu {
      a {
        &::before {
          background-color: $themeColor !important;
        }
      }
    }
  }

  .title-keyline {
    &::after {
      background-color: $themeColor;
    }
  }

  .card {
    h4.keyline {
      &::after {
        background-color: $themeColor;
      }
    }
  }

  ul {
    li {
      &::before {
        background-color: $themeColor;
      }
    }
  }

  .fil-tabs {
    ul {
      li {
        &::before {
          background-color: $teal-400;
        }
      }
    }
  }

  .fil-feature__content {
    background-color: $themeColor;

    &::after {
      background-color: $themeColor;
    }
  }

  .fil-carousel {
    .owl-theme {
      .owl-nav {
        button {
          background: $themeColor !important;
        }
      }
    }
  }

  @include breakpoint(medium down) {
    &.tier-one {
      &::before {
        background-color: $themeColor;
      }

      + .megamenu {
        .tier-two {
          h6,
          a {
            &::before {
              background-color: $themeColor;
            }
          }
        }

        .category-body-return,
        .tier-three-return {
          &::before {
            background-color: $themeColor;
          }
        }
      }
    }
  }
}

.theme-blue-500 {
  @include defineSiteTheme($blue-500);
}

.theme-purple-400 {
  @include defineSiteTheme($purple-400);
}

.theme-green-400 {
  @include defineSiteTheme($green-400);
}

.theme-teal-500 {
  @include defineSiteTheme($teal-500);
}

.theme-orange-400 {
  @include defineSiteTheme($orange-400);
}

/* FFB specific theme */
.theme-blue-ffb {
  @include defineSiteTheme($blue-ffb);

  .global-section {
    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }

    .text-right {
      .public.bg-blue {
        border-left: rem-calc(1) solid $blue-ffb;
        background-color: $blue-ffb;
      }
    }
  }

  .button,
  .button.primary {
    color: $white;
    background-color: $blue-ffb;

    &:hover {
      color: $white;
      background-color: $blue-ffb-light;
    }
  }

  a {
    color: $blue-ffb;

    &:hover {
      color: $blue-ffb-light;
    }

    span {
      color: $blue-ffb;

      &:hover {
        color: $blue-ffb-light;
      }
    }
  }

  .fil-hero-homepage {
    .fil-hero-homepage__content {
      background-color: $blue-ffb;

      a {
        color: $blue-ffb;
        background-color: $white;

        &:hover {
          color: $blue-ffb-light;
          background-color: $white;
        }
      }

      h1,
      p {
        color: $white;
      }
    }
  }

  .fil-hero-component {
    .fil-feature-component__content {
      .countdown-wrapper {
        .countdown {
          color: $blue-ffb;
        }
      }
    }
  }

  .fil-global-footer__bottom-menu {
    a {
      background: $grey-400;
    }
  }
}
