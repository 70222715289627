/*
    Font families, these can be used to override the foundation font settings
    as necessary.

    It's recommended to pick the default according to the main language of the
    target site.
*/

$noto-sc: 'Noto Sans SC', 'Noto Sans CJK SC', sans-serif;
$noto-tc: 'Noto Sans TC', 'Noto Sans CJK TC', sans-serif;
$noto-jp: 'Noto Sans JP', 'Noto Sans CJK JP', 'Hiragino Sans', メイリオ, Meiryo, sans-serif;
$noto-kr: 'Noto Sans KR', 'Noto Sans CJK KR', sans-serif;
$neuzeit_grotesk: 'neuzeit_grotesk', Helvetica, Arial, sans-serif;

@font-face {
  font-family: 'neuzeit_grotesk';
  src: url('#{ $fontPath }neuzeitgro/light/neuzeitgro-lig.eot');
  src: url('#{ $fontPath }neuzeitgro/light/neuzeitgro-lig.eot?#iefix') format('embedded-opentype'),
    url('#{ $fontPath }neuzeitgro/light/neuzeitgro-lig.woff2') format('woff2'),
    url('#{ $fontPath }neuzeitgro/light/neuzeitgro-lig.woff') format('woff'),
    url('#{ $fontPath }neuzeitgro/light/neuzeitgro-lig.ttf') format('truetype'),
    url('#{ $fontPath }neuzeitgro/light/neuzeitgro-lig.svg#neuzeit_grotesk') format('svg');
  font-weight: 300; // Light
  font-style: normal;
}

@font-face {
  font-family: 'neuzeit_grotesk';
  src: url('#{ $fontPath }neuzeitgro/regular/neuzeitgro-reg.eot');
  src: url('#{ $fontPath }neuzeitgro/regular/neuzeitgro-reg.eot?#iefix') format('embedded-opentype'),
    url('#{ $fontPath }neuzeitgro/regular/neuzeitgro-reg.woff2') format('woff2'),
    url('#{ $fontPath }neuzeitgro/regular/neuzeitgro-reg.woff') format('woff'),
    url('#{ $fontPath }neuzeitgro/regular/neuzeitgro-reg.ttf') format('truetype'),
    url('#{ $fontPath }neuzeitgro/regular/neuzeitgro-reg.svg#neuzeit_grotesk') format('svg');
  font-weight: normal; // Regular
  font-style: normal;
}

@font-face {
  font-family: 'neuzeit_grotesk';
  src: url('#{ $fontPath }neuzeitgro/bold/neuzeitgro-bol.eot');
  src: url('#{ $fontPath }neuzeitgro/bold/neuzeitgro-bol.eot?#iefix') format('embedded-opentype'),
    url('#{ $fontPath }neuzeitgro/bold/neuzeitgro-bol.woff2') format('woff2'),
    url('#{ $fontPath }neuzeitgro/bold/neuzeitgro-bol.woff') format('woff'),
    url('#{ $fontPath }neuzeitgro/bold/neuzeitgro-bol.ttf') format('truetype'),
    url('#{ $fontPath }neuzeitgro/bold/neuzeitgro-bol.svg#neuzeit_grotesk') format('svg');
  font-weight: bold; // Bold
  font-style: normal;
}

// FIDELITY ICON FONT

@font-face {
  font-family: 'fidelity-icon-font';
  src: url('#{$icon-font-path}fidelity-icon-font.eot?bkc9k5');
  src: url('#{$icon-font-path}fidelity-icon-font.eot?bkc9k5#iefix') format('embedded-opentype'),
    url('#{$icon-font-path}fidelity-icon-font.woff2?bkc9k5') format('woff2'),
    url('#{$icon-font-path}fidelity-icon-font.ttf?bkc9k5') format('truetype'),
    url('#{$icon-font-path}fidelity-icon-font.woff?bkc9k5') format('woff'),
    url('#{$icon-font-path}fidelity-icon-font.svg?bkc9k5#fidelity-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
