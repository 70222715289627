.fil-article-page {
  .article-header {
    border-top: rem-calc(1) solid $grey-100;
    border-bottom: rem-calc(1) solid $grey-100;
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);

    .label-pro {
      max-width: 100%;
      padding: rem-calc(3 10);
      overflow: hidden;
      border: rem-calc(1) solid transparent;
      border-radius: rem-calc(2);
      font-size: rem-calc(14);
      letter-spacing: rem-calc(.4);
      line-height: 1;
      text-overflow: ellipsis;
      vertical-align: middle;
      background: $blue-200;
      white-space: nowrap;
      color: $white;
      font-weight: 700;
    }

    .category {
      color: $error-red-500;
      font-size: rem-calc(18);
      font-weight: bold;
      margin-bottom: rem-calc(12);

      a {
        color: inherit;
        font-weight: inherit;
      }
    }

    h2 {
      &:not(.title-keyline) {
        color: $grey-900;
        font-size: rem-calc(45);
        margin-bottom: rem-calc(25);
      }

      @at-root :lang(ja) & {
        font-size: rem-calc(26);
      }
    }

    .article-header__author-image {
      border-radius: 50%;
      width: rem-calc(68);
      margin-bottom: rem-calc(10);
    }

    p.small {
      margin-bottom: rem-calc(0);
    }
  }

  @include breakpoint(medium down) {
    .article-header {
      padding: rem-calc(40) 0 0;

      .label-pro {
        max-width: 100%;
        padding: rem-calc(3 10);
        overflow: hidden;
        border: rem-calc(1) solid transparent;
        border-radius: rem-calc(2);
        font-size: rem-calc(14);
        letter-spacing: rem-calc(.4);
        line-height: 1;
        text-overflow: ellipsis;
        vertical-align: middle;
        background: $blue-200;
        white-space: nowrap;
        color: $white;
        font-weight: 700;
      }

      h2 {
        font-size: rem-calc(33);
        line-height: rem-calc(40);
      }

      border: 0;

      .article-header__author-name,
      .article-header__author-title {
        display: block;
      }
    }
  }
}
