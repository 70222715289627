.historical-charges{
    &-main{
        display: flex;
        flex-direction: column;

        &.init-min-height{
            min-height: 100vh;
        }

        .nav-bar{
            border-bottom: 1px solid $grey-100;
        }
    }

    &-search-results{
        flex: 1 1 auto;
        
        &__wrapper{
            border-top: 1px solid $grey-100;
            margin-top: 2rem;
        }
    
        .margin-reset {
            margin: 0 0 2rem 0;
        }

        #search-results__spinner-container{
            display: none;
        }
    
        #fsearch{
            .search-wrapper{
                & div.tt-menu {
                    max-height: rem-calc(350);
                    overflow-y: auto;
                }
    
                & div.tt-suggestion {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border-bottom: 1px solid $grey-100;
                    padding: .8125rem 1.25rem
                }
            }
    
            .tt-menu{
                padding-top: 0;
            }
    
            @include breakpoint(small only) {
                .search-btn.button{
                    width: calc(100% - .9375rem);
                    margin: 0 .9375rem 0 0;
                }
            }
        } 
    
        .selected-info__wrapper {
            padding-top: 3rem;
            border-top: .0625rem solid $grey-100;
        }
        
        .selected-title {
            font-weight: 700;
            margin-bottom: 1.75rem;
        }
    
        .resultTBL{
            margin-top: 3rem;
        }
    
        .result-container{
            .accordion-content {
                display: none; 
            }
    
            .accordion-title{
                position: relative;
                display: block;
                margin-bottom: 0;
                padding: 1.25rem 1rem;
                padding-left: rem-calc(70);
                color: $blue-500;
    
                &:before{
                    right: auto;
                    left: 1.25rem;
                    content: "\e998";
                    -ms-transform: translateY(-20%);
                    transform: translateY(-20%);
                    color: $blue-500;
                    font-family: 'fidelity-icon-font';
                    font-size: 1.625rem;
                    position: absolute;
                    top: 50%;
                    margin-top: -0.5rem;
                }
            }
    
            .is-active .accordion-title:before {
                content: "\e807";
            }
    
            .accordion-wrapper{
                position: relative; 
                border: 1px solid $grey-100;
                margin: 3rem 0 2rem 0;
            }
    
            .accordion-title h5{
                margin: 0;
            }
    
            .accordion-item{
                border-bottom: 1px solid $grey-100;
            }
    
            .item-year{
                padding: 1.125rem;
                border-bottom: 1px solid $grey-100;
    
                &:last-child{
                    border-bottom: 0;
                }
            }
        }

        .search-results__spinner{
            &-container{
                display: none;
            }
            z-index: 2;
        }
    }
}

