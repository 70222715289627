.fil-highcharts {
  margin-bottom: rem-calc($outlook-margin-large);

  &__source {
    margin-bottom: 0;
  }

  @include breakpoint(medium down) {
    margin-bottom: rem-calc($outlook-margin-medium);
  }
}
