.fil-media-grid {

  .left,
  .right {
    &.white {
      &::before, &::after {
        background-color: $white;
      }
    }
  }

  &.bg-grey-50 {
    .grey,
    .white,
    .wealth {
      &::before,
      &::after {
        background-color: $grey-50;
      }
    }
  }
}

