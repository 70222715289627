.fil-messaging {
  .message-box {
    margin: rem-calc($component-padding-base 0);
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: rem-calc(48);
    }
  }
}
