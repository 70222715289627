/* Stop list items appearing on the CMS toolbar */
.cms-toolbar li::before {
  display: none;
}

.cms-icon,
div.cms .cms-structure .cms-dragitem-collapsable::before,
div.cms .cms-submenu-btn::before,
div.cms .cms-submenu-dropdown-settings .cms-submenu-item a[data-cms-icon]::before,
div.cms .cms-submenu-dropdown-settings .cms-submenu-item a[data-rel]::before,
div.cms .cms-toolbar-item-logo a,
div.cms .cms-tooltip-touch::before,
div.cms .cms-tooltip::before {
  font-family: django-cms-iconfont !important;
}
