.pro-badge {
  color: $grey-800;
  background: $blue-100;
  border: rem-calc(1.5) solid $blue-100;
  border-radius: rem-calc(2);
  max-width: 100%;
  overflow: hidden;
  padding: rem-calc(2) rem-calc(6.5);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: rem-calc(14.4);
  line-height: 1.5;
}

.pro-badge--small {
  font-size: rem-calc(12.4) !important;
  line-height: 1.42857 !important;
}

.search-results__wrapper {
  margin-bottom: rem-calc(32);
}

.search-result {
  border-bottom: rem-calc(1) solid $grey-100;
  padding: rem-calc(36 8);
  position: relative;

  .search-result__icon-wrapper {
    float: right;
    margin: 0;

    @include breakpoint(medium) {
      float: left;
    }
  }

  .search-result__content {
    @include breakpoint(medium down) {
      margin-right: rem-calc(56);
    }

    @include breakpoint(medium) {
      margin-left: rem-calc(92);
    }
  }

  .search-result__title {
    margin-bottom: rem-calc(8);
  }

  .search-result__excerpt {
    .pro-badge {
      margin-left: rem-calc(16);
    }

    .search-result__excerpt--inner {
      margin-top: rem-calc(8);

      .pro-badge {
        margin-left: 0;
      }

      .fil-icon {
        font-size: rem-calc(19.2);
        margin-left: rem-calc(8);
        color: $blue-500;
        vertical-align: middle;
      }
    }
  }

  .search-result__icon {
    display: none;
    width: rem-calc(40);

    @include breakpoint(medium) {
      width: rem-calc(60);
    }
  }

  .search-result__isin-link {
    display: block;
    margin-bottom: rem-calc(8);
  }

  .search-result__link-wrapper {
    margin: 1em 0 0 0;
  }

  .search-result__factsheet-link,
  .search-result__deal-link {
    margin-right: rem-calc(40);
  }

  &.search-result--de_fund,
  &.search-result--ukfunds,
  &.search-result--cathaypacificfunds,
  &.search-result--dews_fund {
    .search-result__icon--fund {
      display: block;
    }
  }

  &.search-result--djcmspage {
    .search-result__icon--page {
      display: block;
    }
  }

  &.search-result--article {
    .search-result__icon--news {
      display: block;
    }
  }

  &.search-result--pdf {
    .search-result__icon--pdf {
      display: block;
    }
  }
}

.search-results__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: rem-calc(-5) 0 0 0;
  font-size: rem-calc(10);
  border-radius: 50%;
  border: rem-calc(12) solid $blue;
  border-left-color: $white;
  transform: translateZ(0);
  animation: spinning 0.65s infinite linear;

  &.medium {
    width: rem-calc(80);
    height: rem-calc(80);
    margin-left: rem-calc(-40);
  }

  &.large {
    width: rem-calc(100);
    height: rem-calc(100);
    margin-left: rem-calc(-50);
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}
