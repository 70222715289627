.reveal {
  max-width: calc(#{map-get($breakpoints, xxlarge)} * .7);

  &.tiny {
    max-width: calc(#{map-get($breakpoints, xxlarge)} * .3);
  }
  &.small {
    max-width: calc(#{map-get($breakpoints, xxlarge)} * .5);
  }
  // display: none;

  // width: 100%;
  // // Max width is calculated below this, but for now is hardcoded to prevent cssfix breaking
  // max-width: 1008px;
  padding-top: 40px;
  padding-right: 30px;
  padding-bottom: 40px;
  padding-left: 30px;

  background-color: white;
  box-shadow: 1px 3px 11px 0 rgba(0,0,0,.3);

  &:focus {
    outline: none;
  }

  @include breakpoint(medium up) {
    // top: 50%;
    right: auto;
    bottom: auto;
    // left: 50%;

    width: 70%;

    // transform: translate(-50%, -50%);
  }
}
.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  opacity: .6;
  background-color: $grey-900;
}

.modal__exit {
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;
}
.modal__title {
  margin-bottom: 30px;
}

.modal__body {
  padding-top: 40px;
}
