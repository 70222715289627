.fil-pullout {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  h4 {
    color: color(blue-600);
    font-size: rem-calc(26);
    line-height: rem-calc(36);
    margin-bottom: rem-calc(8);
  }

  span.small {
    font-size: rem-calc(15);
  }

  p.small {
    margin-bottom: 0;
  }
}
