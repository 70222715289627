.reveal {
  width: 95%;
  max-width: rem-calc(974);

  &.detail-compliance {
    max-height: rem-calc(600);
  }

  @supports (-moz-appearance:none) {
    &.detail-compliance-fixer::after {
      content: '';
      display: block;
      padding-bottom: rem-calc(40);
    }
  }

  &.fil-modal {
    padding: rem-calc(40 30);

    ul {
      li {
        &::before {
          display: block;
        }
      }
    }
  }

  &.icon {
    padding: rem-calc(36 94);
  }

  &.compliance-modal {
    .modal__exit {
      display: none;
    }

    .compliance-buttons {
      padding: rem-calc(40);

      .button {
        margin-bottom: 0;
      }
    }

    .detail-compliance-buttons {
      padding: rem-calc(40 40 0 40);

      .button {
        margin-bottom: 0;
      }
    }
  }

  .modal__exit {
    color: $blue-500;
    font-weight: bold;
    top: rem-calc(21.5);
    right: rem-calc(21.5);

    &:hover {
      color: $grey-900;
    }
  }

  .mobile-scroll-close {
    display: none;
    width: 100%;
    position: fixed;
    z-index: 9;
    padding: rem-calc(14) 0;
    text-align: center;
    top: 0;
    right: 0;
    background: $white;
    box-shadow: rem-calc(0 2 4 0) rgba(0, 0, 0, 0.15);
    border-bottom: rem-calc(1) solid $grey-100;
    transform: translateY(#{rem-calc(-56)});
    transition: 0.2s all ease-in-out;

    .message {
      font-size: rem-calc(18);
      font-weight: normal;
    }

    .fil-icon-cross-light {
      position: relative;
      top: rem-calc(1);
      font-size: rem-calc(16);
    }

    &.active {
      transform: translateY(0);
    }
  }

  .top-icon {
    display: block;
    margin-top: rem-calc(5);
    margin-bottom: rem-calc(-26);
    font-size: rem-calc(70);
    line-height: rem-calc(64);
    color: $grey-400;
  }

  .fil-cta {
    padding: rem-calc(14 0 78);
  }

  .fil-text {
    .grid-container {
      padding: 0;

      .medium-10 {
        width: 100%;
      }
    }
  }
}

.default-modal-link {
  &.button {
    position: relative;
    margin-left: 50%;
    margin-right: 50%;
  }
}

@include breakpoint(small only) {
  .reveal,
  .reveal.icon {
    width: 100%;
    padding: rem-calc(40 20);
  }

  .reveal {
    .mobile-scroll-close {
      display: block;
    }

    &.compliance-modal {
      .compliance-buttons {
        padding: rem-calc(40) 0;

        .button {
          margin-bottom: rem-calc(16);
        }
      }

      .detail-compliance-buttons {
        padding: rem-calc(40 0 0 0);

        .button {
          margin-bottom: rem-calc(16);
        }
      }
    }
  }
}
