.country-selector {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;

  @include breakpoint(medium) {
    flex-wrap: wrap;
    max-height: rem-calc(384);
  }

  .country {
    width: 100%;

    &:first-of-type {
      border: none;
    }

    @include breakpoint(small only) {
      border-top: rem-calc(1) solid $grey-100;
      margin: 0;
      padding: rem-calc(16) 0;
    }

    @include breakpoint(medium) {
      width: (100% / 4);
    }

    a {
      display: block;
    }

    img {
      height: rem-calc(24);
      width: rem-calc(34);
      margin-right: rem-calc(24);

      @include breakpoint(medium) {
        margin-right: rem-calc(8);
      }
    }
  }
}
