$component-desktop-height: rem-calc(455);
$hero-content-padding: 56;
$hero-content-item-padding: 32;

.fil-hero-homepage__wrapper {
  margin-bottom: rem-calc($component-padding-base);
  padding-bottom: 0;

  @include breakpoint(medium) {
    max-height: $component-desktop-height;
  }
}

.fil-hero-homepage {
  position: relative;
  overflow: hidden;

  .fil-hero-homepage__bg-image {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;

    @include breakpoint(medium large) {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        max-height: $component-desktop-height;
      }
    }


  }

  .fil-hero-homepage__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    max-height: $component-desktop-height;
    z-index: 10;
    padding-top: rem-calc($hero-content-padding);
    padding-bottom: rem-calc($hero-content-padding);

    h1 {
      font-weight: bold;
      margin-bottom: rem-calc($hero-content-item-padding);
      margin-top: rem-calc(-11);

      @include breakpoint(medium down) {
        font-size: rem-calc(40);
      }
    }

    .countdown-wrapper {
      padding: rem-calc(0 0 40 0);
      text-align: center;

      .countdown {
        display: block;
        width: 100%;
        font-size: rem-calc(44);
        font-weight: bold;

        div {
          padding: 0 rem-calc(8);
          display: inline-block;
          float: left;

          &:first-child {
            padding-left: 0;
          }

          .date-label {
            display: block;
            color: inherit;
            padding: 0;
            margin-top: rem-calc(-9);
            font-size: rem-calc(15);
            font-weight: normal;
            text-align: center;
          }
        }

        @include breakpoint(medium only) {
          font-size: rem-calc(34);
        }

        @include breakpoint(small only) {
          width: auto;
          display: inline-block;
          font-size: rem-calc(34);

          div {
            padding: rem-calc(0 5);
          }
        }
      }
    }

    p {
      margin-bottom: rem-calc($hero-content-item-padding);
    }

    @at-root :lang(ja) & {
      h1 {
        font-size: rem-calc(37);
        line-height: rem-calc(45);
      }

      p.intro {
        font-size: rem-calc(18);
        line-height: rem-calc(32);
      }

      @include breakpoint(small only) {
        h1 {
          font-size: rem-calc(33);
          line-height: rem-calc(40);
        }

        p.intro {
          font-size: rem-calc(16);
          line-height: rem-calc(23);
        }
      }
    }

    .fil-feature-homepage__text-link {
      display: inline-block;
      margin-bottom: rem-calc(24);

      &:hover {
        color: $white;
        opacity: 0.7;
      }
    }

    .button {
      margin-bottom: 0;
      align-self: flex-start;
    }

    &::after {
      display: block;
      content: ' ';
      background-color: inherit;
      position: absolute;
      z-index: -1;
      width: rem-calc(10000);
      right: 0;
      top: 0;
      height: 100%;
      transform: skew(-15deg);
      transform-origin: bottom right;
    }

    &.reduced {
      &::after {
        right: -50%;
      }
    }
  }

  .fil-feature-homepage__bgImage {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: $grey-400;
  }
}

@include breakpoint(small only) {
  .fil-hero-homepage {
    .fil-feature-homepage__bgImage {
      padding-bottom: 0;
    }

    .fil-hero-homepage__content {
      min-height: 0;
      padding-top: rem-calc($hero-content-padding);
      padding-bottom: rem-calc($hero-content-padding);

      &::after {
        left: -50%;
        right: -50%;
        transform: none;
      }

      h1 {
        text-align: center;
      }

      p {
        text-align: center;
      }

      .button {
        align-self: center;
        text-align: center;
      }
    }

    .fil-hero-homepage__bg-image {
      position: relative;
      left: 0;
      object-fit: cover;
      width: 100%;
    }

    .fil-feature-homepage__content {
      padding-top: rem-calc(32);

      &::after {
        left: rem-calc(-9000);
      }
    }
  }
}
