.fil-hero-component__wrapper {
  margin-bottom: rem-calc($component-padding-base);
  padding-bottom: 0;

  &--outlook {
    + .outlook-layout-submenu__wrapper {
      // Close space between hero and submenu
      margin-top: rem-calc($component-padding-base * -1);
    }
  }
}

.fil-hero-component {
  position: relative;
  transition: all 0.5s ease;
  overflow: hidden;

  .fil-hero__bg-image {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;

    img {
      max-width: inherit;
      height: 100%;
      width: 100%;
      left: -20%;
      object-fit: cover;
    }
  }

  &.fil-hero-component__content {
    position: relative;
    margin-top: rem-calc(-48);
    z-index: 3;

    p {
      padding: rem-calc(48 0);
      margin-bottom: 0;
    }

    .button-wrapper {
      margin-top: rem-calc(40);

      a {
        margin-bottom: 0;
      }
    }
  }

  .title-keyline {
    &::after {
      width: rem-calc(80);
    }

    &.no-keyline::after {
      display: none;
    }
  }

  .title-keyline-hero {
    @include breakpoint(large) {
      margin-top: rem-calc(24);
    }

    margin-bottom: rem-calc(56) !important;
  }

  .fil-feature-component__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    justify-content: center;
    height: 100%;
    min-height: rem-calc(400);
    z-index: 2;
    transition: all 0.5s ease;

    &.reduced {
      min-height: rem-calc(300);

      &::after {
        right: -50%;

        @include target-metro {
          right: 0;
        }
      }
    }

    .button-wrapper {
      justify-self: end;
    }

    h1 {
      font-weight: bold;
    }

    .countdown-wrapper {
      margin-top: rem-calc(-32);
      padding: rem-calc(0 0 40 0);
      text-align: center;

      @include breakpoint(medium down) {
        padding: rem-calc(0 0 72 0);
        margin-top: rem-calc(-26);
      }

      @include breakpoint(small only) {
        margin-top: rem-calc(-12);
      }

      .countdown {
        display: block;
        width: 100%;
        font-size: rem-calc(44);
        font-weight: bold;
        color: $blue-500;

        div {
          padding: 0 rem-calc(8);
          display: inline-block;
          float: left;

          &:first-child {
            padding-left: 0;
          }

          .date-label {
            display: block;
            color: inherit;
            padding: 0;
            margin-top: rem-calc(-9);
            font-size: rem-calc(15);
            font-weight: normal;
            text-align: center;
          }
        }

        @include breakpoint(medium down) {
          width: auto;
          display: inline-block;
          font-size: rem-calc(34);

          div {
            padding: rem-calc(0 5);
          }
        }
      }
    }

    h3 {
      font-weight: bold;
    }

    p {
      margin-bottom: rem-calc(48);
      flex-grow: 1;
    }

    .section-breadcrumb {
      position: absolute;
      top: rem-calc(30);
      margin-bottom: 0;
    }

    .fil-feature-component__text-link {
      display: inline-block;
      margin-bottom: rem-calc(24);

      &:hover {
        color: $white;
        opacity: 0.7;
      }
    }

    .button {
      margin-bottom: 0;
    }

    &::after {
      display: block;
      content: ' ';
      background-color: inherit;
      position: absolute;
      z-index: -1;
      width: rem-calc(10000);
      right: 0;
      top: 0;
      height: 100%;
      transform: skew(-15deg);
      transform-origin: bottom right;
    }
  }

  .fil-feature-component__bgImage {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: $grey-400;
  }

  .fil-hero-component__mask {
    display: none;

    @include target-metro {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  &--outlook {
    .fil-hero-component__outlook-content-wrapper .fil-feature-component__content {
      background-color: $grey-50;

      h1 {
        color: $grey-900;
      }

      .section-breadcrumb {
        position: static;
        color: $grey-600;

        a,
        span {
          color: $grey-600;
        }
      }
    }

    .fil-hero-component__intro-wrapper.title-keyline {
      .intro {
        margin-bottom: 0;
        color: $grey-900;
      }

      &::after {
        display: none;
      }
    }

    .countdown-wrapper {
      display: none;
    }

    @include breakpoint(large) {
      .fil-hero-component__outlook-grid-container > .fil-hero-component__outlook-content-wrapper {
        margin-left: rem-calc(30);
        // ensures header content remains aligned with body text as page expands
        width: calc((500% / 12) + #{rem-calc(70)});

        .fil-feature-component__content {
          min-height: rem-calc(320);
          padding: rem-calc(24) rem-calc(16) rem-calc(24) 0;

          .section-breadcrumb {
            margin-bottom: rem-calc(32);
          }

          h1 {
            padding-bottom: rem-calc(28);
            margin-bottom: rem-calc(32);
          }

          .fil-hero-component__intro-wrapper {
            padding-bottom: 0;
            margin-bottom: rem-calc(48);

            .title-keyline {
              padding-bottom: 0;
              margin-bottom: rem-calc(48);
            }
          }
        }
      }
    }

    @include breakpoint(medium down) {
      .fil-hero-component__outlook-content-wrapper .fil-feature-component__content {
        min-height: rem-calc(200);
        padding: rem-calc(16);

        .section-breadcrumb {
          margin-bottom: rem-calc(12);
        }

        h1 {
          padding-bottom: 0;
          margin-bottom: rem-calc(24);

          &::after {
            display: none;
          }
        }
      }

      .fil-hero-component__intro-wrapper.title-keyline {
        text-align: center;
        padding-bottom: rem-calc(24);
        margin-bottom: rem-calc(24);

        &::after {
          display: block;
          margin-left: -rem-calc(20);
          width: rem-calc(40);
        }
      }
    }
  }
}

@include breakpoint(medium only) {
  .fil-hero-component {
    .fil-hero__bg-image {
      padding-bottom: 0;
      object-fit: cover;
    }
  }

  .title-keyline-hero {
    margin-bottom: rem-calc(24) !important;
  }
}

@include breakpoint(small only) {
  .title-keyline-hero {
    margin-bottom: 0;
  }
}

@include breakpoint(medium down) {
  .fil-hero-component {
    .hero-introduction-pull-up {
      margin-left: rem-calc(20);
      margin-right: rem-calc(20);
      padding-left: 0;
      padding-right: 0;
    }

    .section-breadcrumb {
      text-align: center;
      margin-bottom: rem-calc(18);
    }

    h1 {
      text-align: center;
      padding-bottom: rem-calc(32);

      &.title-keyline {
        &.keyline-left {
          &::after {
            left: 50%;
            margin-left: rem-calc(-40);
          }
        }
      }
    }

    &.fil-hero-component__content {
      p {
        padding-top: rem-calc(24);
        padding-left: rem-calc(24);
        padding-right: rem-calc(24);
        text-align: center;
      }
    }

    .fil-feature-component__content {
      min-height: rem-calc(250);
      padding-top: rem-calc(32);

      &::after {
        left: rem-calc(-9000);
      }
    }

    .fil-hero__bg-image {
      position: relative;
      left: 0;
      width: 100%;
      object-fit: cover;
    }
  }
}
