.fil-isa-saving-tool {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .isa-saving-tool__timeline {
    box-sizing: border-box;
    text-align: center;
    margin: rem-calc(30 0 12 0);
  }

  .isa-saving-tool__timeline-scale {
    border: rem-calc(2) solid $grey-300;
    border-bottom: none;
    height: rem-calc(24);
    margin-top: rem-calc(8);
    margin-bottom: rem-calc(20);
  }

  .dataBar {
    background: $blue;
    color: $white;
    text-indent: rem-calc(6);
    padding: rem-calc(1) 0;
    font-weight: bold;
    margin-bottom: 0;
  }

  .data {
    margin-bottom: 0;
  }

  tr:hover {
    background-color: $white;
  }

  .tabs-shame {
    border-bottom: rem-calc(1) solid $grey-100;
  }

  .tabs-title {
    min-width: rem-calc(180);
    border-bottom: none;

    &:focus {
      outline-style: none !important;
    }
  }

  .tabs-panel {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .tabs-content {
    min-height: rem-calc(80);
    border: none;
  }

  .bg-grey-fnw-1 {
    background: color(grey);
    color: $white;
  }

  .bg-grey-fnw-2 {
    background: $grey-500;
    color: $white;
  }

  .merged-cell {
    border-left: rem-calc(1) solid $grey-100;
    border-right: rem-calc(1) solid $grey-100;
  }
}
