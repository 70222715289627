@import "radio";
@import "checkbox";

// #FORM VARIABLES
$radioWidth: 12px;

.input,
input {
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;

  font-size: rem-calc(18px);
  &:focus {
    // Outline styles for webkit browsers, will need a proper fallback 
    // for IE.
    outline: auto 5px -webkit-focus-ring-color;
    box-shadow: none;
  }
  &[disabled] {
    color: $grey-500;
    background-color: $grey-50;
    &::placeholder {
      color: $grey-500;
    }
  }
}

input,
select {
  margin-bottom: 16px;
  
  &.small {
    height: 32px;
    margin-bottom: 12px;
  }
  &.large {
    height: 60px;
    margin-bottom: 24px;

    font-size: rem-calc(20);
  }
}

textarea {
  padding-top: 16px;

  line-height: 1.45;
}

// #SELECT BOX (Drop Down)

// For successful cross-browser styling modification select element requires wrapper to
// customise the select arrow icon using the ::after pseudo element
.select-field-wrapper {
  position:relative; 
  display: inline;
  &::after {
    position: absolute;
    display: block;
    
    top: 1px;
    right: 1rem;
    width: 16px;
    height: 16px;

    font-family: 'fidelity-icon-font';
    content: "\e934";
    font-size: 20px;
    line-height: 16px;
    
    pointer-events: none;
    opacity: 0.6;
  }
}

select {
  margin: 0;
  margin-bottom: 1rem;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;

  cursor: pointer;

  border: 1px solid $grey-300;
  border-radius: 0;
  
  // background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>icon-chevron-down</title><path d="M7.293 11.207l-5-5a.999.999 0 1 1 1.414-1.414L8 9.086l4.293-4.293a.999.999 0 1 1 1.414 1.414l-5 5a.997.997 0 0 1-1.414 0z" fill="#016FAD" fill-rule="evenodd"/></svg>');
  // background-position: right -4px center;
  background-size: 0;
  box-shadow: none;
  font-size: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  


  &::-ms-expand {
    display: none;
  }
  option[disabled] {
    color: $grey-400;
  }
  &:required:invalid {
    color: $grey-400;
  }
  &:focus {
    // Outline styles for webkit browsers, will need a proper fallback 
    // for IE.
    outline: auto 5px -webkit-focus-ring-color;
    box-shadow: none;
  }
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  padding-right: 24px;
  padding-left: 24px;
}

[type='password'].toggle-visible {
  padding-right: 40px;
}

.date-group input {
  padding-right: 0;
  padding-left: 0;

  text-align: center;
}

// #LABEL
label,
.label {
  margin-bottom: rem-calc(8);
  padding-left: 0;

  color: $grey-800;
  background-color: transparent;

  font-size: rem-calc(16);

  @include breakpoint(medium up) {
    font-size: rem-calc(18);
  }
}

//#LEGEND
legend {
  margin-bottom: 16px;

  color: $grey-800;
  background-color: transparent;

  font-size: rem-calc(18);
  line-height: 1.8;
}

// Validation

.required {
  &:before {
    content: '*';
    vertical-align: top;

    color: $error-red;

    font-size: rem-calc(20px);
    font-weight: 700;
  }
}

.required-jp {
  padding: 4px 8px;

  vertical-align: middle;
  letter-spacing: .1rem;

  color: $white;
  border-radius: 100px;
  background-color: $error-red;

  font-size: 12px;
  font-weight: 700;
}

.is-invalid-input:focus,
.is-invalid-input:not(:focus),
.is-invalid-input {
  border: 2px solid $error-red-500;
  background-color:$white;
}

.is-valid-input,
.is-valid-input:not(:focus),
.is-valid-input:focus {
  border: 2px solid $green-600;
  background-color: $white;
}

.is-warning-input,
.is-warning-input:not(:focus),
.is-warning-input:focus {
  border: 2px solid $yellow-500;
  background-color: $white;
}

.is-info-input,
.is-info-input:not(:focus),
.is-info-input:focus {
  border: 2px solid $blue-500;
  background-color: $white;
}

.form-error,
.form-valid {
  display: none;

  color: $grey-800;

  @include p();
  .fil-icon {
    vertical-align: middle;
  }
}
// Icon colors for various validation states
.fil-icon {
  .form-error & {
    color: $error-red-500;

    font-size: 24px;
  }
  .form-valid & {
    color: $green-400;

    font-size: 24px;
  }
}


// Field Group
.field-group.horizontal {
  display: inline-block;

  margin-right: 16px;

  &:last-of-type {
    margin-right: 0;
  }

  input,
  select,
  label {
    display: inline-block;

    width: auto;
    margin-bottom: 0;
  }

  label {
    margin-right: 12px;
  }
}


.radio,
.checkbox {
  label,
  input {
    display: inline-block;

    margin-bottom: 0;

    vertical-align: middle;
  }
  
  &.vertical {
   label,
   input {
     display: block;

     margin-bottom: 12px;
   }
  }
}