.fil-comparison-list {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .cell {
    .card {
      background-color: $grey-25;
      border-radius: rem-calc(4);
      border: rem-calc(1) solid $grey-100;

      .card-divider {
        text-align: center;
        padding: rem-calc(24) 0;
        border-radius: rem-calc(4 4 0 0);

        h3 {
          color: $white;
          margin-bottom: 0;
          padding: rem-calc(0 30);
          font-weight: bold;
        }
      }

      .card-section {
        padding: rem-calc(35 35 1 35);

        ul {
          margin-bottom: 0;

          li {
            margin-bottom: rem-calc(40);

            &::before {
              width: rem-calc(6);
              height: rem-calc(6);
            }
          }
        }
      }

      .cta-section {
        min-height: rem-calc(182);
        padding: rem-calc(30 30 40);
        border-top: rem-calc(1) solid $grey-100;

        .grid-y {
          height: rem-calc(111);

          a {
            display: block;
            width: 100%;
            font-size: rem-calc(18);
            margin-bottom: rem-calc(30);

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &:nth-of-type(1) {
      .card-divider {
        background-color: $grey-600;
      }
    }

    &:nth-of-type(2) {
      .card-divider {
        background-color: $grey-400;
      }
    }

    &:nth-of-type(3) {
      .card-divider {
        background-color: $grey-200;

        h3 {
          color: $grey-900;
        }
      }
    }
  }

  .cta-below-section {
    margin-top: rem-calc(40);

    a {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: rem-calc(20);
    }
  }

  .arrow-right-cta {
    span {
      font-weight: bold;
    }
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    text-align: center;
    margin-top: rem-calc(30);
  }

  @include breakpoint(small only) {
    .cell {
      width: 100%;

      .card {
        margin-bottom: rem-calc(20);
      }

      &:last-of-type {
        .card {
          margin-bottom: 0;
        }
      }
    }
  }
}
