.fil-article-date-and-read-time {
  color: $grey-600;
  font-weight: normal;
  margin-bottom: rem-calc(32);

  &__wrapper {
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  &__date-wrapper {
    padding-right: rem-calc(8);
  }

  &__read-time-wrapper {
    text-align: left;
    display: inline;
    padding-left: rem-calc(14);
    border-left: rem-calc(1) solid $grey-100;
  }

  &__date,
  &__read-time {
    font-weight: normal;
  }

  @include breakpoint(small) {
    margin-bottom: rem-calc(24);

    &__date-wrapper {
      padding-right: rem-calc(4);
      display: inline;
    }
  }
}