.outlook-container {
  .outlook-layout {
    // This is a combination of outer padding of 30px
    // and an inner (column) padding of 15px
    padding-left: rem-calc(45);
    padding-right: rem-calc(45);

    // Add to footer top spacing to make 80px gap
    padding-bottom: rem-calc(20);

    &__download-container {
      margin-left: auto;
    }

    &__separator {
      margin: 0 rem-calc(24);
      border-right: rem-calc(1) solid $grey-100;
    }

    &__important-information-container {
      margin-bottom: 0;

      > a {
        margin: 0;
        // Large line-height to ensure alignment
        // with download and share plugins
        line-height: rem-calc(26);
        display: inline-block;
        vertical-align: bottom;
      }

      > .fil-icon-info {
        color: $blue-500;
        position: relative;
        top: rem-calc(-2);
        padding-right: rem-calc(4);
      }
    }

    // Using top margin for page-data and layout-container
    // as the former is optional
    &__page-data-container {
      margin-top: rem-calc(24);
    }

    &__layout-container {
      margin-top: rem-calc(40);
    }

    @include breakpoint(medium down) {
      &__download-container {
        width: 100%;
        text-align: center;
      }

      &__share-links-container {
        width: 100%;
        text-align: center;
      }

      &__important-information-container {
        text-align: center;
        margin-bottom: rem-calc(24);
      }

      &__layout-container {
        margin-top: rem-calc(24);
      }
    }


    @include breakpoint(small only) {
      // This is a combination of outer padding of 20px
      // and an inner (column) padding of 10px
      padding-left: rem-calc(30);
      padding-right: rem-calc(30);
    }
  }
}
