.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background: rgba( $black, 0.5);

  .dialog {
    max-width: rem-calc(900);
    background-color: $white;
    box-shadow: rem-calc( 0 0 20 ) rgba($grey-900 , 0.2);
    border-radius: rem-calc(3);
    overflow: hidden;
    margin: rem-calc(100 ) auto 0;

    @include breakpoint(small only) {
      margin: 0;
      height: 100%;
    }

    .cross-button {
      float: right;
      padding: rem-calc(20);
      cursor: pointer;
    }

    .content-wrap-styler {
      bottom: rem-calc(30);
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      padding: rem-calc(20 40);

      header {
        &.confirm-header {
          padding: 0;
          width: 100%;

          h1 {
            margin-bottom: 0;
            font-size: rem-calc(31);

            @include breakpoint(small only) {
              font-size: rem-calc(24);
            }

            .icon-green {
              color: $grey-900;

              .fil-icon-confirm {
                &::after {
                  left: 0;
                  position: relative;
                  color: $green-400;
                }
              }
            }
          }
        }
      }

      .messageWrapper {
        padding-top: rem-calc(55);
        width: 100%;

        @include breakpoint(small only) {
          width: auto;
        }

        footer {
          .controls {
            text-align: center;
          }
        }
      }
    }
  }
}
