.fil-cookie-policy {
  position: fixed;
  width: 100vw;
  min-height: rem-calc(80);
  background-color: rgba(32, 36, 43, 0.8);
  color: $white;
  z-index: 990;
  bottom: 0;
  padding: rem-calc(10) 0;
  text-align: center;

  p {
    margin-bottom: rem-calc(13);
    color: $white;
  }

  a {
    text-decoration: underline;
    color: $white;

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }

  .button {
    white-space: normal;
    margin-right: 0;
  }

  @include breakpoint(medium) {
    .button.secondary {
      margin-right: rem-calc(20);
    }
  }

  @include breakpoint(medium down) {
    padding: rem-calc(10 15);

    .button.secondary {
      margin-right: rem-calc(20);
    }
  }
}
