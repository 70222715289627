.fil-attribution {
  margin-bottom: rem-calc($outlook-margin-medium);
  color: $grey-600;

  &__wrapper {
    align-items: center;
    flex-wrap: nowrap;
  }

  &__author-image-wrapper {
    flex-shrink: 0;
  }

  @include author_avatar(60);

  &__text-wrapper {
    padding: rem-calc(7) 0;
    word-break: break-word;
  }

  &__text,
  &__author {
    margin-bottom: 0;
  }

  &__author {
    font-weight: bold;
  }

  @include breakpoint(medium down) {
    margin-bottom: rem-calc($outlook-margin-small);
  }
}
