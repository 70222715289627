$print-grid-border: rem-calc(1) solid $grey-100;

@media print {
  //force wayfindingbar to be in one row
  .global-section {
    white-space: nowrap;
    overflow: hidden;
  }

  //force main menu to be in one row
  .main-menu {
    white-space: nowrap;
    overflow: hidden;
  }

  //fskk: show only small logo
  .logo-container {
    .logo-jp:first-child {
      display: none !important;
    }
  }

  //hide tier-one menu dropdown arrow
  .menu a.tier-one span {
    display: none;
  }

  //card grid
  .fil-cards {
    .card.mini-promo {
      .image {
        width: rem-calc(16);
        border-right: $print-grid-border;
      }

      .cta-section {
        position: relative;
        left: 0;
        top: rem-calc(16);
        margin-bottom: rem-calc(20);
      }
    }
  }

  //card grid feature
  .fil-card-grid-feature {
    .card {
      border: $print-grid-border;
    }

    &.children-3,
    &.children-5,
    &.children-6 {
      .cell {
        &:nth-child(3),
        &:nth-child(6) {
          padding-left: 25%;
          padding-right: 25%;
        }
      }
    }
  }

  //contact us
  //add border to card
  .fil-contact-us {
    &__card-wrapper {
      position: relative !important;
    }

    &__bgImage > .cell {
      width: 83.33333%;
    }

    &-card {
      width: 50% !important;
      border: $print-grid-border;

      &--second {
        border-right: none;
      }
    }
  }

  //comparison list
  .fil-comparison-list {
    .cell .card .card-section {
      height: auto !important;
    }
  }

  //article
  .fil-article-list {
    .card {
      .card-section.author .show-for-small-only {
        display: none !important;
      }
    }
  }

  //hide search
  // TODO: replace ID selector
  #fsearch { /* stylelint-disable-line selector-max-specificity */
    display: none !important;
  }
}
