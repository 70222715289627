.fil-image {
  padding: rem-calc($component-padding-base 0);
  text-align: center;

  .no-description {
    margin-bottom: rem-calc(20);
  }

  .image-description {
    margin-top: rem-calc(20);

    p {
      text-align: center;
      color: $grey-400;
      font-size: rem-calc(15);
      margin-bottom: 0;
    }
  }

  a {
    &::after {
      display: none;
    }
  }
}
