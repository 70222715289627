/* stylelint-disable selector-max-specificity */
// TODO: replace use of ID selectors throughout this document

.page-wrapper {
  clear: both;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.yext-search-enabled {
  #fsearch {
    float: right;
    display: block;
    padding-right: rem-calc(32);
    max-height: rem-calc(113);

    @include breakpoint(medium only) {
      width: 100%;
    }

    @include breakpoint(medium down) {
      padding-left: rem-calc(16);
      padding-right: rem-calc(0);
      width: 100%;
    }

    .typeahead,
    .tt-query,
    .tt-hint {
      max-width: 100%;
      padding: rem-calc(17) 0;
      border: rgba(255, 255, 255, 0);
      outline: none;
    }

    .typeahead {
      background-color: $white;
      border-bottom: rem-calc(2) solid $white;
    }

    .typeahead:focus {
      border-bottom: rem-calc(2) solid $grey-dim-200;
    }

    .tt-query {
      -webkit-box-shadow: inset rem-calc(0 1 1) rgba(0, 0, 0, 0.075);
      -moz-box-shadow: inset rem-calc(0 1 1) rgba(0, 0, 0, 0.075);
      box-shadow: inset rem-calc(0 1 1) rgba(0, 0, 0, 0.075);
    }

    .tt-hint {
      color: $grey-medium-400;
    }

    .tt-menu {
      width: 100%;
      min-width: rem-calc(205);
      top: rem-calc(70) !important;
      padding: rem-calc(8) 0;
      background-color: $white;
      border: rem-calc(1) solid rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: rem-calc(0 5 10) rgba(0, 0, 0, 0.2);
      -moz-box-shadow: rem-calc(0 5 10) rgba(0, 0, 0, 0.2);
      box-shadow: rem-calc(0 5 10) rgba(0, 0, 0, 0.2);
    }

    .tt-suggestion {
      padding: rem-calc(3 20);
      font-size: rem-calc(18);
      line-height: rem-calc(24);

      &:hover {
        cursor: pointer;
        color: $white;
        background-color: $blue-250;
      }

      &.tt-cursor {
        color: $white;
        background-color: $blue-250;
      }

      p {
        margin: 0;
      }
    }

    .twitter-typeahead,
    input#q {
      display: inline-block;
      float: left;
    }

    .button {
      margin-left: rem-calc(8);
      float: left;
      color: $grey-dim-200;
      border-color: $grey-dim-200;
      font-size: rem-calc(18);

      &:hover {
        border-color: $blue;
      }

      &.fill {
        background-color: $blue;
        border-color: $blue;
      }
    }

    .search-form {
      @include breakpoint(small only) {
        z-index: 104;
        width: rem-calc(416);
      }
    }

    .mglass {
      cursor: pointer;
      background-color: color(grey-25);
      position: relative;
      display: block;
      color: $grey-800;
      float: left;
      text-decoration: none;
      padding: rem-calc(4 14);
      margin-top: rem-calc(14);
      font-size: rem-calc(18);
      margin-left: rem-calc(8);

      @include breakpoint(medium down) {
        z-index: 1;
      }

      &:hover {
        color: $grey-800;
      }
    }

    input[type='text'],
    input[type='search'] {
      width: 100% !important;
      line-height: 0.75;
      box-shadow: none;
      box-sizing: border-box;
      border: rem-calc(1) solid $grey-300;
      border-radius: 0;
      padding: rem-calc(8 24);
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      margin-top: rem-calc(5);
      margin-bottom: rem-calc(5);
    }

    .search-wrapper {
      padding: rem-calc(16 0 35.5);
      position: relative;

      .input-holder {
        position: relative;
        height: rem-calc(50);
        width: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        @include breakpoint(medium only) {
          width: calc(100% - #{rem-calc(46)});
        }

        #init-search {
          margin-top: rem-calc(19);
          display: inline-block;
        }

        .search-input {
          position: absolute !important;
          width: 100%;
          height: rem-calc(39);
          min-width: rem-calc(588);
          opacity: 0;
          top: 0;
          left: 0;
          border-bottom: rem-calc(2) solid $grey-dim-200;
          outline: none;
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
          transition-delay: 0s;
          box-sizing: content-box;
          padding: 0;
          box-shadow: none;

          @include breakpoint(medium only) {
            min-width: 0;
          }
        }

        .button {
          opacity: 0;
          margin-top: rem-calc(10);
          left: calc(100% - #{rem-calc(105)});
          z-index: 2;
          cursor: pointer;
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
          transition-delay: 0s;
          position: absolute;
        }

        .twitter-typeahead {
          display: none !important;
        }

        a {
          color: $grey-800;

          &:hover {
            color: $blue;
          }
        }
      }

      #close-search {
        position: absolute;
        z-index: -1;
        top: rem-calc(36);
        right: rem-calc(20);
        width: rem-calc(25);
        height: rem-calc(25);
        color: inherit;
        background-color: transparent;
        opacity: 0;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      &.active {
        #init-search {
          display: none;
        }

        .input-holder {
          margin-right: rem-calc(38);
          transition: all 0.5s ease-in-out;
          width: rem-calc(726);

          @include breakpoint(medium only) {
            width: calc(100% - #{rem-calc(56)});
          }

          @include breakpoint(small only) {
            width: rem-calc(336);
          }
        }

        .button {
          opacity: 1;
          transition-delay: 0.4s;
          position: absolute;
        }

        .search-input {
          opacity: 1;
          transition-delay: 0.2s;
        }

        #close-search {
          opacity: 1;
          right: rem-calc(10);
          transition: all 0.6s ease-in-out;
          transition-delay: 0.4s;
          z-index: 1;
        }

        .twitter-typeahead {
          display: inline-block !important;
          position: absolute !important; // Use !important to override element.style
          width: calc(100% - #{rem-calc(140)});
        }
      }
    }
  }
}

