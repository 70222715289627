/****************
Standard Tooltips
****************/

.fil-tooltip {
  font-family: 'fidelity-icon-font';
  font-size: rem-calc(20);
  vertical-align: middle;

  color: $blue-500;
  &:after {
    content: icon(info);
  }
  &:hover {
    cursor: pointer;
  }
}

// Don't display template content outside of the tooltip
.fil-tooltip__template {
  display: none;
}

.fil-tooltip__title {
  font-size: rem-calc(16);
  font-weight: 700;
  display: block;
  margin-bottom: 8px;
  padding-right: 16px;

  @include breakpoint(large up) {
    font-size: rem-calc(18);
  }
}

.fil-tooltip__content {
  font-size: rem-calc(13);
  display: block;
  margin-bottom: 0;

  @include breakpoint(large up) {
    font-size: rem-calc(15);
  }
}

// Tooltip close icon
.fil-tooltip__close {
  font-weight: 700;
  position: absolute;
  top: 21px;
  right: 36px;
  
  @include breakpoint(medium up) {
    right: 59px;
  }
  
  @include breakpoint(large up) {
    right: 16px;
  }

  cursor: pointer;
  color: $grey-300;
  &:after {
    font-size: rem-calc(12);

    @include breakpoint(large up) {
      font-size: rem-calc(16);
    }
  }
}

// Extend tooltipster theme with custom styling
.tooltipster-sidetip.tooltipster-customized .tooltipster-box {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 260px;
  padding: 16px;

  border: 1px solid $grey-300;
  border-radius: 0;
  background: white;
  
  @include breakpoint(medium up) {
    margin-left: 45px;
    margin-right: 45px;
  }

  @include breakpoint(large up) {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
  }
}

.tooltipster-sidetip.tooltipster-customized .tooltipster-content {
  padding: 0;

  color: black;
}

.tooltipster-sidetip.tooltipster-customized .tooltipster-arrow {
  top: -1px;
}

.tooltipster-sidetip.tooltipster-customized .tooltipster-arrow .tooltipster-arrow-uncropped .tooltipster-arrow-background {
  top: 1px;

  border-bottom-color: white;
}

.tooltipster-sidetip.tooltipster-customized .tooltipster-arrow-border {
  border-bottom-color: $grey-300;
}


/****************
Perma Tooltips
****************/

.perma-tooltip-wrapper {
  padding-right: 12px;
  padding-left: 12px;
}
.perma-tooltip {
  position: relative;
  display: inline-block;
  width: 296px;
  // margin: 0 12px;
  padding: 12px;
  border: 1px solid $grey-100;
  background-color: white;

  @include breakpoint(large up) {
    width: 432px;
    margin: 0 24px;
  }
}

.perma-tooltip--flexible {
  width: auto;
}

.perma-tooltip--arrow-top {
  &::before {
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    content: ' ';
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent $grey-100 transparent;
  }
  &::after {
    position: absolute;
    z-index: 1;
    bottom: 99%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    content: ' ';
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
}

.perma-tooltip__title {
  font-weight: 700;

  margin-bottom: 0;
}

.perma-tooltip__icon {
  &:before {
    font-family: 'fidelity-icon-font';
    font-size: rem-calc(20);
    margin-right: 5px;
    content: icon(info);
    vertical-align: middle;
    color: $grey-500;
  }
}

.perma-tooltip__content {
  margin-bottom: 0;
}


/****************
Message Boxes
****************/

.message-box {
  position: relative;
  display: table;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
  text-align: left;
  border: 1px solid $grey-100;
  background-color:$white;
}

.message-box__exit {
  position: absolute;
  top: rem-calc(12);
  right: rem-calc(12);
  font-size: rem-calc(14);
}

.message-box__icon {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 48px;

  @include breakpoint(medium up) {
    width: 60px;
  }
  @include breakpoint(large up) {
    width: 90px;
  }
  // small 24 x 24
  // medium 32 x 32
  &:after {
    font-family: 'fidelity-icon-font';
    font-size: 24px;

    color:$white;

    @include breakpoint(medium up) {
      font-size: 32px;
    }
    @include breakpoint(large up) {
      font-size: 40px;
    }
  }
  .message-box--success & {
    background-color: $green-600;
    &:after {
      content: icon(confirm);
    }
  }
  .message-box--info & {
    background-color: $blue-500;
    &:after {
      content: icon(info);
    }
  }
  .message-box--warning & {
    background-color: $yellow-500;
    &:after {
      content: icon(warning-square);
      color: $grey-800;
    }
  }
  .message-box--error & {
    background-color: $error-red-500;
    &:after {
      content: icon(warning);
    }
  }
}

.message-box__body {
  display: table-cell;
  width: calc(100% - 90px);
  padding-top: 24px;
  padding-right: 28px;
  padding-bottom: 24px;
  padding-left: 24px;

  @include breakpoint(medium up) {
    padding-right: 56px;
  }
  @include breakpoint(large up) {
    padding-right: 64px;
  }
}

.message-box__title {
  line-height: 1;

  margin-bottom: rem-calc(10);
}

.message-box__desc {
  margin-bottom: rem-calc(0);
}

/****************
Message Box expanding version
****************/

.message-box__expand {

  .message-box-expand--button {
    display: block;
    position: relative;
    margin: 1rem auto 0 auto;
    &:hover {
      transform: translateY( 2px );
    }
  }

  .message-box__desc {
    margin-bottom: rem-calc( 16 );
  }
  .message-box__desc:nth-of-type( n+3 ) {
    display: none;
  }

  &.is-expanded {
    // .message-box__desc:nth-of-type( n+3 ) {
    //   display: block;
    // }

    .message-box-expand--button {
      &::after {
        transform: scale( 1, -1 );
      }
    }
  }
}
