.editorial {
  &.fil-article-list {

    $gutter: rem-calc(24);
    $avatar_thumbnail_width: 832;
    $avatar_thumbnail_height: 500;

    @mixin fil-article-card-list {
      .fil-article-list {
        &__card {
          @include card-container($grey-25, $grey-900, $gutter, 0, 0, 0);

          border-radius: rem-calc(5);
          display: block;

          h4:hover {
            color: $blue;
          }

          img {
            width: 100%;
          }

          p {
            font-size: rem-calc(16);
            color: $grey-600;
          }
        }

        &__tag {
          font-weight: bold;
          color: $blue;
          margin-bottom: rem-calc(8);
        }

        &__authors {
          font-weight: bold;
          margin-bottom: rem-calc(0);
        }

        &__card-section {
          @include card-section;

          padding: $gutter;
          margin-bottom: rem-calc(16);

          h4 {
            font-weight: bold;
            margin-bottom: rem-calc(16);
          }
        }
      }
    }

    .category {
      a {
        color: $orange-700;
        display: inline-block;
        font-size: rem-calc(18);
        font-weight: 700;
        margin-bottom: $gutter/2;
      }

      .readtime {
        color: $grey-800;
      }
    }

    .fil-article-list__card {
      .reading-time {
        font-weight: normal;
        display: inline-block;
        margin-left: rem-calc(14);
        margin-bottom: 0;

        .fil-icon {
          font-size: rem-calc(16);
          font-weight: normal;
          margin-right: rem-calc(4);
        }
      }

      .bg-image__container {
        margin-left: rem-calc(30);
        position: relative;
        width: rem-calc(300);

        .article__bg-image {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: rem-calc(160);
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }

    .author {
      a {
        display: inline-block;
        font-weight: bold;
        color: $grey-500;

        &:hover {
          color: $blue-500;
        }
      }
    }

    &--outlook,
    &--teaser {
      padding: 0;

      > h4 {
        margin-bottom: $gutter;
      }

      @include fil-article-card-list;

    }

    &--teaser {
      display: flex;
      flex-wrap: wrap;
      margin-left: -($gutter/2);
      margin-right: -($gutter/2);

      .fil-article-list {
        &__card {
          display: flex;
          flex-direction: column;
          margin: $gutter/2;
          flex: 1 0 80%;

          @include breakpoint(medium up) {
            flex: 1 0 30%;
            max-width: calc((100% / 3) - #{$gutter});
          }

          &-image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &--avatar {
              width: rem-calc(160);
              height: rem-calc(160);
              margin: 0;
            }

            &-container {
              width: 100%;
              flex: 1;
              max-height: 0;
              padding-bottom: 100% * $avatar_thumbnail_height / $avatar_thumbnail_width; //thumbnail image sizes to calculate container ratio
              position: relative;
              overflow: hidden;
            }
          }

          &-section {
            display: flex;
            flex: 1 0 auto;
            flex-flow: column;

            * {
              flex-shrink: 0;
            }

            .fil-article-list__authors {
              margin-top: auto;

              span {
                font-weight: normal;
              }
            }
          }
        }

        &__tag {
          color: $orange-700;
          display: inline-block;
          font-size: rem-calc(18);
          font-weight: 700;
        }
      }
    }
  }
}

