/* component base */
$component-padding-base: 28;
$title-keyline-above-baton: 30;
$title-keyline-below-baton: 40;

/* card components */
$card-padding-bottom-mobile: 20;
$card-grid-padding-bottom-mobile: $component-padding-base - $card-padding-bottom-mobile;
$card-grid-padding-bottom-grey-mobile: $component-padding-base * 2 - $card-padding-bottom-mobile;

// TODO: replace ID selector
#body-container { /* stylelint-disable-line selector-max-specificity */
  overflow-x: hidden;
  min-height: 100vh;
}

body {
  background: $white;
  font-size: rem-calc(16) !important;

  &.is-mobile {
    &.is-sub-open {
      position: fixed;
      overflow: hidden;
      width: 100vw;
      height: 100vh;
    }
  }
}

h2 {
  margin-bottom: rem-calc(8);
}

section.fil-iframe,
section.fil-factsheet {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);
}

section.bg-grey-50 {
  padding-top: rem-calc($component-padding-base * 2) !important;
  padding-bottom: rem-calc($component-padding-base * 2) !important;
  margin-top: rem-calc($component-padding-base) !important;
  margin-bottom: rem-calc($component-padding-base) !important;

  + section.bg-grey-50 {
    margin-top: rem-calc(-$component-padding-base) !important;
    padding-top: 0 !important;
  }

  + .fil-global-footer {
    margin-top: rem-calc(-$component-padding-base * 2);
  }
}

.title-keyline {
  position: relative;
  color: $grey-500;
  margin-bottom: rem-calc($title-keyline-below-baton);
  padding-bottom: rem-calc($title-keyline-above-baton);
  font-weight: normal;

  &::after {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-25);
    bottom: 0;
    content: '';
    height: rem-calc(3);
    width: rem-calc(50);
    background-color: $blue-500;
  }

  &.keyline-left {
    &::after {
      left: 0;
      margin-left: 0;
    }
  }

  &.super {
    &::after {
      height: rem-calc(4);
    }
  }

  + p {
    margin-top: rem-calc(-26) !important;
    margin-bottom: rem-calc(56) !important;
  }
}

.grid-x.align-center {
  justify-content: center;
}

.fil-title-paragraph + .fil-cta {
  padding-top: 0;
}

.fil-title-paragraph p:last-child {
  margin-bottom: 0;
}

[class*="fil-"] {
  .cell table {
    width: 100% !important;
    overflow-x: auto;
    border-left: none;
    border-right: rem-calc(1) solid $grey-100;
    border-bottom: none;
    margin-bottom: rem-calc(40);

    td,
    th {
      border: rem-calc(1) solid $grey-100;
    }
  }

  .has-kome-symbol {
    margin-left: rem-calc(28);
    text-indent: rem-calc(-28);
    margin-bottom: rem-calc(16);

    .kome {
      padding-right: rem-calc(10);
    }
  }
}

@include breakpoint(small only) {
  .grid-container {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  .title-keyline {
    margin-bottom: rem-calc(40);
    padding-bottom: rem-calc(22);
    font-size: rem-calc(33);

    + p {
      margin-top: rem-calc(-18) !important;
      margin-bottom: rem-calc(40) !important;
    }
  }

  .fil-comparison-list-container {
    &.grid-margin-x {
      margin-left: 0;
      margin-right: 0;

      .cell {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .owl-item.active {
      margin-right: 0 !important;
    }
  }
}

.headless {
  .global-section,
  .header-wrapper,
  .fil-global-footer,
  a[headless="hide"] {
    display: none !important;
  }
}

@mixin support-cjk-lang {
  @at-root {
    :lang(ja) &,
    :lang(zh-hans) &,
    :lang(zh-hant) &,
    :lang(ko) & {
      @content;
    }
  }
}

