.fil-table {
  padding-top: rem-calc($component-padding-base);
  padding-bottom: rem-calc($component-padding-base);

  .table-wrapper {
    width: 100%;
    overflow-x: auto;

    table {
      width: 100% !important;
      margin-bottom: 0;

      th {
        p {
          margin: rem-calc(20 0)
        }
      }

      td {
        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
