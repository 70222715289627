// visual layers ( z-index )
// remember to check stacking context on element and parentage
// layers run from base ( lowest ), 1 - 20 ( highest )
$layer-base: 0;
$layer-1: 1;
$layer-2: 2;
$layer-3: 3;
$layer-4: 4;
$layer-5: 5;
$layer-6: 6;
$layer-7: 7;
$layer-8: 8;
$layer-9: 9;
$layer-10: 10;
$layer-11: 11;
$layer-12: 12;
$layer-13: 13;
$layer-14: 14;
$layer-15: 15;
$layer-16: 16;
$layer-17: 17;
$layer-18: 18;
$layer-19: 19;
$layer-20: 20;
// special layer definition for "-1" ( often required when using :before/:after pseudo-classes )
$layer-below: -1;

$login-icon: '#{$staticPrefix}fil_site_assets/images/login-icon.svg' !default;
$login-icon-black: '#{$staticPrefix}fil_site_assets/images/login-icon-black.svg' !default;
$logout-icon: '#{$staticPrefix}fil_site_assets/images/logout.svg' !default;
$logout-icon-black: '#{$staticPrefix}fil_site_assets/images/logout-black.svg' !default;
$products-and-services: '#{$staticPrefix}fil_site_assets/images/icon-ps.svg' !default;
$products-and-services-black: '#{$staticPrefix}fil_site_assets/images/icon-ps-black.svg' !default;

/*
Usage:
A sass mixin to write css that targets only IE 10 and IE 11
@include target-metro {
    color: cyan;
}
*/
@mixin target-metro {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
