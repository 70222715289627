.fil-outlook-in-depth {
  // Pullout has inner padding but is pushed
  // down by paragraphs above it so we need
  // remove the padding to achieve correct spacing
  section ~ .fil-pullout {
    padding-top: rem-calc(0);
    padding-bottom: rem-calc(40);

    @include breakpoint(medium down) {
      padding-bottom: rem-calc($outlook-margin-medium);
    }
  }


  .fil-video {
    padding: 0;
    margin-bottom: rem-calc($outlook-margin-medium);


    @include breakpoint(medium down) {
      margin-bottom: rem-calc($outlook-margin-small);
    }

    // We need to reset the grid styles as
    // the component will be already within a grid column
    .grid-container {
      padding: 0;
    }

    .cell {
      width: 100%;
    }
  }

  &__heading {
    font-weight: bold;
    margin-bottom: rem-calc($outlook-margin-medium);
  }

  &__textarea {
    h5 {
      font-weight: bold;
      margin-bottom: rem-calc($outlook-margin-medium);

      @include breakpoint(medium down) {
        margin-bottom: rem-calc($outlook-margin-small);
      }
    }

    table {
      margin-bottom: rem-calc($outlook-margin-medium);


      @include breakpoint(medium down) {
        margin-bottom: rem-calc($outlook-margin-small);
      }
    }
  }
}
