$contact-card-vertical-offset: 30;
$component-padding-top: $component-padding-base + $contact-card-vertical-offset;

.fil-contact-us {
  $large-card-width: rem-calc(400);
  $medium-card-width: rem-calc(240);

  position: relative;
  padding-top: rem-calc($component-padding-top);
  padding-bottom: rem-calc($component-padding-base);

  @include breakpoint(small only) {
    margin: 0 rem-calc(-20);
  }

  &__bgImage {
    width: 100%;
    height: rem-calc(350);
    background-size: cover;
    background-position: center;
    background-color: $grey-400;

    @include breakpoint(small only) {
      height: auto;
    }
  }

  &__card-wrapper {
    position: absolute;
    margin-top: rem-calc(-$contact-card-vertical-offset);

    @include breakpoint(large) {
      &.card-left {
        left: calc(50% - #{$large-card-width});
      }

      &.card-right {
        left: auto;
      }
    }

    @include breakpoint(medium only) {
      left: calc(50% - #{$medium-card-width});
    }

    @include breakpoint(small only) {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  &-card {
    margin-bottom: 0;
    padding: rem-calc(40 19.2);
    width: $large-card-width;

    @include breakpoint(medium only) {
      width: $medium-card-width;
    }

    @include breakpoint(small only) {
      width: 100%;
    }

    &--second {
      background-color: $blue-800;
      color: $white;

      .arrow-right-cta {
        color: $white;
      }
    }

    &__title {
      color: inherit;
      margin-bottom: $global-margin;
      font-weight: bold;
    }

    &__subtitle {
      margin-bottom: 0;
    }

    &__phone {
      font-weight: 500;
      margin-bottom: rem-calc(10);
    }

    &__link {
      margin-bottom: 0;
      margin-top: rem-calc(24);

      @include breakpoint(small only) {
        font-size: rem-calc(16);
      }
    }
  }

  .margin-bottom-0 {
    margin-bottom: 0;
  }

  .margin-bottom-1 {
    margin-bottom: rem-calc(16);
  }
}
