.fil-article-content {
  margin-bottom: rem-calc(64);

  & > * {
    margin-bottom: rem-calc(40);
  }

  h2,
  h3,
  .section-header {
    font-size: rem-calc(22);
    color: $grey-600;
    margin-bottom: rem-calc(32);
  }

  p {
    font-size: rem-calc(18);
    line-height: rem-calc(23);
  }

  > p:first-of-type {
    // drop-cap paragraph at top
    $first-paragraph-line-height: 31;
    $first-pagagraph-drop-cap-padding: 4;
  }

  img {
    display: block;
  }

  iframe {
    width: 100% !important;
  }

  blockquote {
    border-left: 0;
    text-align: center;
    padding: 0;

    .fil-quote-text {
      color: $blue-600;
      font-size: rem-calc(26);
      line-height: rem-calc(31);
      margin-bottom: rem-calc(12);
    }

    .fil-quote-caption {
      font-size: rem-calc(15);
      margin-bottom: 0;
    }
  }

  .embed-block + h5,
  .embed-block + h6 {
    // targets chart sources

    font-size: rem-calc(15);
    border-bottom: rem-calc(1) solid $grey-100;
    padding-bottom: rem-calc(24);
    margin-top: rem-calc(-40);

    b {
      font-weight: normal;
    }
  }

  @include breakpoint(small only) {
    & > * {
      margin-bottom: rem-calc(32);
    }

    h2,
    h3 {
      margin-bottom: rem-calc(24);
    }

    blockquote {
      .fil-quote-text {
        font-size: rem-calc(19);
        line-height: rem-calc(28);
      }

      .fil-quote-caption {
        font-size: rem-calc(13);
        margin-bottom: 0;
      }
    }

    .embed-block + h5,
    .embed-block + h6 {
      // targets chart sources
      margin-top: rem-calc(-32);
    }
  }
}
